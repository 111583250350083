import { call, put, takeLatest } from 'redux-saga/effects';
import { IAction } from 'redux/store/types';
import * as ProfileService from 'services/profile/profile.service';
import * as ProxyService from 'services/proxy/proxy.service';
import getConfig from 'config';
import {
  FETCH_PROFILE,
  FETCH_PROFILE_FAILED,
  FETCH_PROFILE_SUCCESSFUL,
  FETCH_INSURANCE,
  FETCH_INSURANCE_FAILED,
  FETCH_INSURANCE_SUCCESSFUL,
  UPDATE_INSURANCE,
  UPDATE_INSURANCE_FAILED,
  UPDATE_INSURANCE_SUCCESSFUL,
  ADD_INSURANCE,
  ADD_INSURANCE_FAILED,
  ADD_INSURANCE_SUCCESSFUL,
  FETCH_CONNECTIONS,
  FETCH_CONNECTIONS_SUCCESSFUL,
  FETCH_CONNECTIONS_FAILED,
  UPDATE_PROFILE,
  UPDATE_PROFILE_FAILED,
  UPDATE_PROFILE_SUCCESSFUL,
  UPDATE_PROFILE_IMAGE,
  UPDATE_PROFILE_IMAGE_FAILED,
  UPDATE_PROFILE_IMAGE_SUCCESSFUL,
  FETCH_MARKED_ARTICLES,
  FETCH_MARKED_ARTICLES_FAILED,
  FETCH_MARKED_ARTICLES_SUCCESSFUL,
  FETCH_ACTIVITY,
  FETCH_ACTIVITY_SUCCESSFUL,
  FETCH_ACTIVITY_FAILED,
  FETCH_PUBLIC_INSURANCE,
  UPDATE_PUBLIC_INSURANCE,
  FETCH_PUBLIC_INSURANCE_SUCCESSFUL,
  FETCH_PUBLIC_INSURANCE_FAILED,
  UPDATE_PUBLIC_INSURANCE_SUCCESSFUL,
  UPDATE_PUBLIC_INSURANCE_FAILED,
} from './actions';
import { addColorCode, getTimeFormattedConnections } from './helper';
import { showSnackbar } from '../snackbar';

function* fetchProfile(action: IAction) {
  try {
    const { data } = yield call(ProfileService.getProfile, action.payload);
    yield put({
      type: FETCH_PROFILE_SUCCESSFUL,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: FETCH_PROFILE_FAILED,
      payload: { message: e },
    });
  }
}

function* fetchActivity(action: IAction) {
  const { userId, pageNumber = 0, pageSize = 3 } = action.payload;
  try {
    const { data } = yield call(ProfileService.getActivity, { userId }, { pageNumber, pageSize });
    yield put({
      type: FETCH_ACTIVITY_SUCCESSFUL,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: FETCH_ACTIVITY_FAILED,
      payload: { message: e },
    });
  }
}

function* updateProfile(action: IAction) {
  try {
    const { data } = yield call(ProfileService.updateProfile, action.payload);
    yield put({
      type: UPDATE_PROFILE_SUCCESSFUL,
      payload: data,
    });

    yield put({
      type: FETCH_PROFILE,
    });
  } catch (e: any) {
    const message = e?.data?.errors[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: UPDATE_PROFILE_FAILED,
      payload: { message },
    });
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: message,
      })
    );
  }
}

function* updateProfileImage(action: IAction) {
  try {
    const {
      data: { fileUrl },
    } = yield call(ProfileService.updateProfileImage, action.payload);

    yield put({
      type: UPDATE_PROFILE_IMAGE_SUCCESSFUL,
      payload: fileUrl,
    });
  } catch (e: any) {
    const message = e?.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: UPDATE_PROFILE_IMAGE_FAILED,
      payload: { message },
    });
  }
}

function* fetchInsurance(action: IAction) {
  const { userId } = action.payload;

  try {
    const { data } = yield call(ProfileService.getInsuranceInfo, { userId });
    yield put({
      type: FETCH_INSURANCE_SUCCESSFUL,
      payload: data,
    });
  } catch (e: any) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: FETCH_INSURANCE_FAILED,
      payload: { message },
    });
  }
}

function* updateInsurance(action: IAction) {
  const { userId, bodyRequest } = action.payload;
  try {
    yield call(ProfileService.updateInsuranceInfo, bodyRequest, { userId });

    const { data } = yield call(ProfileService.getInsuranceInfo, { userId });

    yield put({
      type: UPDATE_INSURANCE_SUCCESSFUL,
      payload: data,
    });
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Member insurance profile updated successfully',
      })
    );
  } catch (e: any) {
    const message = e?.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: UPDATE_INSURANCE_FAILED,
      payload: { message },
    });
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: message ?? 'Unable to update insurance profile',
      })
    );
  }
}

function* addInsurance(action: IAction) {
  const { userId, bodyRequest } = action.payload;
  try {
    yield call(ProfileService.createInsuranceInfo, bodyRequest);

    const { data } = yield call(ProfileService.getInsuranceInfo, { userId });

    yield put({
      type: ADD_INSURANCE_SUCCESSFUL,
      payload: data,
    });
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Member insurance profile added successfully',
      })
    );
  } catch (e: any) {
    const message = e?.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: ADD_INSURANCE_FAILED,
      payload: { message },
    });
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: message ?? 'Unable to add insurance profile',
      })
    );
  }
}

function* fetchPublicInsurance(action: IAction) {
  const { userId } = action.payload;

  try {
    const { data } = yield call(ProxyService.bypassProxy, {
      method: 'GET',
      url: `${getConfig.api.baseUrl}/report-service-node/insurances/userprofile/insurance-info/${userId}`,
      memberId: userId,
      params: { userId },
    });
    yield put({
      type: FETCH_PUBLIC_INSURANCE_SUCCESSFUL,
      payload: data,
    });
  } catch (e: any) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: FETCH_PUBLIC_INSURANCE_FAILED,
      payload: { message },
    });
  }
}

function* updatePublicInsurance(action: IAction) {
  const { userId, bodyRequest } = action.payload;

  try {
    yield call(ProxyService.bypassProxy, {
      method: 'PUT',
      url: `${getConfig.api.baseUrl}/report-service-node/insurances/userprofile/insurance-info/${userId}`,
      memberId: userId,
      params: { userId },
      body: bodyRequest,
    });

    const { data } = yield call(ProxyService.bypassProxy, {
      method: 'GET',
      url: `${getConfig.api.baseUrl}/report-service-node/insurances/userprofile/insurance-info/${userId}`,
      memberId: userId,
      params: { userId },
    });

    yield put({
      type: UPDATE_PUBLIC_INSURANCE_SUCCESSFUL,
      payload: data,
    });
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Member insurance profile updated successfully',
      })
    );
  } catch (e: any) {
    const message = e?.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: UPDATE_PUBLIC_INSURANCE_FAILED,
      payload: { message },
    });
  }
}

function* fetchConnections(action: IAction) {
  try {
    if (!action.payload) {
      return;
    }
    const {
      data: { activeConnections, requestedConnections, pastConnections },
    } = yield call(ProfileService.getConnections, action.payload);
    const activeConnections_ = addColorCode(activeConnections);
    const pastConnections_ = addColorCode(pastConnections);

    yield put({
      type: FETCH_CONNECTIONS_SUCCESSFUL,
      payload: {
        activeConnections: getTimeFormattedConnections(activeConnections_),
        pastConnections: getTimeFormattedConnections(pastConnections_),
        requestedConnections: requestedConnections || [],
        userId: action.payload.userId,
      },
    });
  } catch (e: any) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: FETCH_CONNECTIONS_FAILED,
      payload: { message },
    });
  }
}

function* fetchMarkedEducationContent() {
  try {
    const { data: bookmarkedContentResponse } = yield call(
      ProfileService.getMarkedEducationalContent,
      'bookmarked'
    );
    const { data: markAsCompletedContentResponse } = yield call(
      ProfileService.getMarkedEducationalContent,
      'completedSlug'
    );
    yield put({
      type: FETCH_MARKED_ARTICLES_SUCCESSFUL,
      payload: { bookmarkedContentResponse, markAsCompletedContentResponse },
    });
  } catch (err: any) {
    const message = err.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({ type: FETCH_MARKED_ARTICLES_FAILED, payload: { message } });
  }
}

export default function* profileSaga(): IterableIterator<any> {
  yield takeLatest(FETCH_PROFILE, fetchProfile);
  yield takeLatest(UPDATE_PROFILE, updateProfile);
  yield takeLatest(UPDATE_PROFILE_IMAGE, updateProfileImage);
  yield takeLatest(FETCH_ACTIVITY, fetchActivity);
  yield takeLatest(FETCH_INSURANCE, fetchInsurance);
  yield takeLatest(UPDATE_INSURANCE, updateInsurance);
  yield takeLatest(ADD_INSURANCE, addInsurance);
  yield takeLatest(FETCH_PUBLIC_INSURANCE, fetchPublicInsurance);
  yield takeLatest(UPDATE_PUBLIC_INSURANCE, updatePublicInsurance);
  yield takeLatest(FETCH_CONNECTIONS, fetchConnections);
  yield takeLatest(FETCH_MARKED_ARTICLES, fetchMarkedEducationContent);
}
