import { FC } from 'react';

interface IGlyphIcon {
  className?: string;
  color?: string;
  onClick?: () => void;
}

const PayPal: FC<IGlyphIcon> = ({ className = '', onClick }) => {
  return (
    <svg
      width="58"
      height="40"
      viewBox="0 0 58 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
    >
      <rect x="0.5" y="0.5" width="57" height="39" rx="5.5" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.3569 30.7472L24.7248 28.332L23.9053 28.3123H19.9921L22.7116 10.4896C22.7201 10.4356 22.7475 10.3855 22.7875 10.3499C22.8277 10.3142 22.8789 10.2947 22.9325 10.2947H29.5308C31.7215 10.2947 33.2332 10.7657 34.0223 11.6956C34.3924 12.1319 34.628 12.5879 34.7421 13.0895C34.8617 13.6159 34.8637 14.2448 34.7471 15.012L34.7386 15.0678V15.5594L35.1086 15.7761C35.4201 15.947 35.6678 16.1425 35.8578 16.3663C36.1742 16.7394 36.3789 17.2135 36.4654 17.7754C36.5549 18.3534 36.5253 19.0414 36.3789 19.8202C36.2101 20.7158 35.9372 21.4961 35.5685 22.1343C35.2296 22.7225 34.7977 23.2105 34.2848 23.5884C33.7952 23.9477 33.2135 24.2204 32.5558 24.3949C31.9183 24.5664 31.1917 24.653 30.3946 24.653H29.8811C29.5141 24.653 29.1574 24.7896 28.8774 25.0346C28.5966 25.2847 28.411 25.6264 28.3541 26.0002L28.3153 26.2177L27.6652 30.4747L27.6359 30.6309C27.628 30.6804 27.6146 30.7051 27.5949 30.7218C27.5773 30.737 27.5521 30.7472 27.5275 30.7472H24.3569"
        fill="#28356A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.4593 15.1245C35.4398 15.2546 35.4172 15.3876 35.392 15.5243C34.5218 20.142 31.5447 21.7373 27.7426 21.7373H25.8067C25.3417 21.7373 24.9498 22.0861 24.8775 22.5603L23.6056 30.899C23.5585 31.2104 23.7906 31.4909 24.0944 31.4909H27.5281C27.9346 31.4909 28.28 31.1856 28.344 30.7711L28.3777 30.5909L29.0242 26.3505L29.0658 26.118C29.129 25.7021 29.4752 25.3966 29.8817 25.3966H30.3952C33.7218 25.3966 36.3261 24.0007 37.0873 19.961C37.4051 18.2735 37.2406 16.8644 36.3992 15.8734C36.1447 15.5746 35.8288 15.3265 35.4593 15.1245"
        fill="#298FC2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.5487 14.7493C34.4157 14.7092 34.2785 14.6729 34.1379 14.6402C33.9964 14.6082 33.8516 14.5799 33.7024 14.555C33.1804 14.4679 32.6084 14.4265 31.9958 14.4265H26.8241C26.6966 14.4265 26.5756 14.4562 26.4675 14.51C26.2288 14.6285 26.0517 14.8619 26.0088 15.1477L24.9085 22.3502L24.877 22.5602C24.9493 22.0861 25.3412 21.7372 25.8062 21.7372H27.7421C31.5442 21.7372 34.5213 20.1411 35.3914 15.5242C35.4174 15.3876 35.4393 15.2546 35.4588 15.1244C35.2387 15.0037 35.0003 14.9004 34.7436 14.8124C34.6801 14.7907 34.6147 14.7697 34.5487 14.7493"
        fill="#22284F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.0091 15.1478C26.052 14.8621 26.2292 14.6287 26.4678 14.5109C26.5767 14.457 26.6969 14.4272 26.8244 14.4272H31.9961C32.6087 14.4272 33.1807 14.4688 33.7027 14.556C33.8519 14.5806 33.9967 14.6091 34.1382 14.6411C34.2788 14.6737 34.416 14.7101 34.549 14.75C34.615 14.7704 34.6804 14.7916 34.7445 14.8126C35.0012 14.9005 35.2398 15.0046 35.4599 15.1246C35.7188 13.4181 35.4577 12.2563 34.5651 11.2042C33.5809 10.0458 31.8048 9.55005 29.5319 9.55005H22.9335C22.4692 9.55005 22.0732 9.89891 22.0014 10.3738L19.2531 28.3798C19.1989 28.736 19.4647 29.0574 19.8123 29.0574H23.8859L26.0091 15.1478"
        fill="#28356A"
      />
      <rect x="0.5" y="0.5" width="57" height="39" rx="5.5" stroke="#E0E6EB" />
    </svg>
  );
};

export default PayPal;
