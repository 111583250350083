import { call, put, takeLatest } from 'redux-saga/effects';
import { IAction } from 'redux/store/types';
import * as ConversationService from 'services/conversation/conversation.service';

import { showSnackbar } from '../snackbar';

import {
  FETCH_CONVERSATIONS,
  FETCH_CONVERSATIONS_FAILED,
  FETCH_CONVERSATIONS_SUCCESSFUL,
  FETCH_CHATBOTS,
  FETCH_CHATBOTS_FAILED,
  FETCH_CHATBOTS_SUCCESSFUL,
  ASSIGN_CHATBOT,
  ASSIGN_CHATBOT_FAILED,
  ASSIGN_CHATBOT_SUCCESSFUL,
} from './actions';

function* fetchConversations() {
  try {
    const { data } = yield call(ConversationService.getConversations);
    yield put({ type: FETCH_CONVERSATIONS_SUCCESSFUL, payload: data });
  } catch (e: any) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({ type: FETCH_CONVERSATIONS_FAILED, payload: { message } });
  }
}

function* fetchChatbots(action: IAction) {
  const { userId, includeResponses = true } = action.payload;

  try {
    const { data } = yield call(ConversationService.getChatbots, userId, includeResponses);
    yield put({ type: FETCH_CHATBOTS_SUCCESSFUL, payload: data });
  } catch (err: any) {
    const message = err.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({ type: FETCH_CHATBOTS_FAILED, payload: { message } });
  }
}

function* assignChatbot(action: IAction) {
  const { callback, ...bodyRequest } = action.payload;

  try {
    yield call(ConversationService.assignChatbot, bodyRequest);
    yield put({ type: ASSIGN_CHATBOT_SUCCESSFUL });
    yield callback();
  } catch (e: any) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({ type: ASSIGN_CHATBOT_FAILED, payload: { message } });
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: message,
      })
    );
  }
}

function* conversationSaga(): IterableIterator<any> {
  yield takeLatest(FETCH_CONVERSATIONS, fetchConversations);
  yield takeLatest(FETCH_CHATBOTS, fetchChatbots);
  yield takeLatest(ASSIGN_CHATBOT, assignChatbot);
}

export default conversationSaga;
