import { call, put, takeLatest } from 'redux-saga/effects';
import * as ScheduleService from 'services/schedule/schedule.service';
import {
  FETCH_PROVIDER_SCHEDULE,
  FETCH_PROVIDER_SCHEDULE_FAILED,
  FETCH_PROVIDER_SCHEDULE_SUCCESSFUL,
  FETCH_PROVIDER_SERVICES,
  FETCH_PROVIDER_SERVICES_FAILED,
  FETCH_PROVIDER_SERVICES_SUCCESSFUL,
  FETCH_PUBLIC_ALL_SERVICES,
  FETCH_PUBLIC_PROVIDER_SCHEDULE,
  FETCH_PUBLIC_PROVIDER_SCHEDULE_FAILED,
  FETCH_PUBLIC_PROVIDER_SCHEDULE_SUCCESSFUL,
  FETCH_PUBLIC_PROVIDER_SERVICES,
  FETCH_SYSTEM_SERVICES,
  FETCH_SYSTEM_SERVICES_FAILED,
  FETCH_SYSTEM_SERVICES_SUCCESSFUL,
} from './actions';
import { IAction } from 'redux/store/types';
import dayjs from 'dayjs';

function* fetchSystemServices() {
  try {
    const response = yield call(ScheduleService.getSystemServices);
    if (response.status === 200) {
      const list = response?.data?.data || [];
      yield put({
        type: FETCH_SYSTEM_SERVICES_SUCCESSFUL,
        payload: {
          list,
        },
      });
    } else {
      yield put({
        type: FETCH_SYSTEM_SERVICES_FAILED,
        payload: {
          message: response.errors[0].endUserMessage,
        },
      });
    }
  } catch (e) {
    yield put({
      type: FETCH_SYSTEM_SERVICES_FAILED,
      payload: { message: e },
    });
  }
}

function* fetchProviderServices(action: IAction) {
  try {
    const { data } = yield call(ScheduleService.getProviderServices, { providerId: action.payload });
    yield put({
      type: FETCH_PROVIDER_SERVICES_SUCCESSFUL,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: FETCH_PROVIDER_SERVICES_FAILED,
      payload: { message: e },
    });
  }
}

function* fetchPublicProviderServices(action: IAction) {
  try {
    const { data } = yield call(ScheduleService.getPublicProviderServices, { providerId: action.payload });
    const filteredProvider = data.results.find(item => item.actor === action.payload);
    yield put({
      type: FETCH_PROVIDER_SERVICES_SUCCESSFUL,
      payload: filteredProvider,
    });
  } catch (e) {
    yield put({
      type: FETCH_PROVIDER_SERVICES_FAILED,
      payload: { message: e },
    });
  }
}

function* fetchPublicAllServices() {
  try {
    const { data } = yield call(ScheduleService.getPublicAllServices);
    yield put({
      type: FETCH_PROVIDER_SERVICES_SUCCESSFUL,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: FETCH_PROVIDER_SERVICES_FAILED,
      payload: { message: e },
    });
  }
}

function* fetchProviderSchedule(action: IAction) {
  try {
    const { data } = yield call(ScheduleService.getProviderSchedule, action.payload);
    yield put({
      type: FETCH_PROVIDER_SCHEDULE_SUCCESSFUL,
      payload: {
        schedule: data,
      },
    });
  } catch (e) {
    yield put({
      type: FETCH_PROVIDER_SCHEDULE_FAILED,
      payload: { message: e },
    });
  }
}

function* fetchPublicProviderSchedule(action: IAction) {
  try {
    const queryParams = {
      participantIds: action.payload.participantIds,
      serviceId: action.payload.serviceId,
      timezone: action.payload.timeZone,
      month: dayjs(action.payload.startDate, 'DD-MM-YYYY').month() + 1,
      year: dayjs(action.payload.startDate, 'DD-MM-YYYY').year(),
    };
    const queryParams2 = {
      ...action.payload,
      startDate: dayjs(action.payload.startDate, 'DD-MM-YYYY').subtract(2, 'days').format('DD-MM-YYYY'),
      endDate: dayjs(action.payload.endDate, 'DD-MM-YYYY').add(2, 'days').format('DD-MM-YYYY'),
    };
    const { data } = yield call(
      ScheduleService.getPublicProviderSchedule,
      action.payload.scheduleType === 'next-available' || action.payload.scheduleType === undefined
        ? queryParams
        : queryParams2
    );
    yield put({
      type: FETCH_PUBLIC_PROVIDER_SCHEDULE_SUCCESSFUL,
      payload: {
        schedules: data,
      },
    });
  } catch (e) {
    yield put({
      type: FETCH_PUBLIC_PROVIDER_SCHEDULE_FAILED,
      payload: { message: e },
    });
  }
}

export default function* stateSaga(): IterableIterator<any> {
  yield takeLatest(FETCH_SYSTEM_SERVICES, fetchSystemServices);
  yield takeLatest(FETCH_PROVIDER_SERVICES, fetchProviderServices);
  yield takeLatest(FETCH_PUBLIC_PROVIDER_SERVICES, fetchPublicProviderServices);
  yield takeLatest(FETCH_PUBLIC_ALL_SERVICES, fetchPublicAllServices);
  yield takeLatest(FETCH_PROVIDER_SCHEDULE, fetchProviderSchedule);
  yield takeLatest(FETCH_PUBLIC_PROVIDER_SCHEDULE, fetchPublicProviderSchedule);
}
