import { call, put, takeLatest } from 'redux-saga/effects';
import { IAction } from 'redux/store/types';
import * as ProxyService from 'services/proxy/proxy.service';
import getConfig from 'config';

import {
  FETCH_CONNECTIONS_PUBLIC,
  FETCH_CONNECTIONS_FAILED_PUBLIC,
  FETCH_CONNECTIONS_SUCCESSFUL_PUBLIC,
} from './actions';

function* fetchConnections(action: IAction) {
  const { userId } = action.payload;

  try {
    const { data } = yield call(ProxyService.bypassProxy, {
      method: 'GET',
      url: `${getConfig.api.baseUrl}/profile/connections`,
      memberId: userId,
      params: { userId },
    });
    yield put({ type: FETCH_CONNECTIONS_SUCCESSFUL_PUBLIC, payload: data });
  } catch (e: any) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({ type: FETCH_CONNECTIONS_FAILED_PUBLIC, payload: { message } });
  }
}

export default function* publicConnectionSaga(): IterableIterator<any> {
  yield takeLatest(FETCH_CONNECTIONS_PUBLIC, fetchConnections);
}
