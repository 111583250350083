import { IAction } from 'redux/store/types';
import { ConversationState, IChatbot, IConversation } from './types';
import {
  FETCH_CONVERSATIONS,
  FETCH_CONVERSATIONS_FAILED,
  FETCH_CONVERSATIONS_SUCCESSFUL,
  FETCH_CHATBOTS,
  FETCH_CHATBOTS_FAILED,
  FETCH_CHATBOTS_SUCCESSFUL,
  ASSIGN_CHATBOT,
  ASSIGN_CHATBOT_FAILED,
  ASSIGN_CHATBOT_SUCCESSFUL,
} from './actions';

export const DEFAULT = {
  isLoading: false,
  isRequesting: false,
  errorMsg: '',
  conversations: [] as IConversation[],
  chatbots: [] as IChatbot[],
};

export default function conversationReducer(state = DEFAULT, action: IAction): ConversationState {
  const { type, payload } = action;
  switch (type) {
    case FETCH_CONVERSATIONS:
      return {
        ...state,
        isLoading: true,
        isRequesting: false,
        errorMsg: '',
      };
    case FETCH_CONVERSATIONS_FAILED: {
      return {
        ...state,
        isLoading: false,
        conversations: [] as IConversation[],
        errorMsg: payload?.message || '',
      };
    }
    case FETCH_CONVERSATIONS_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        errorMsg: '',
        conversations: payload,
      };
    }
    case FETCH_CHATBOTS:
      return {
        ...state,
        isLoading: true,
        isRequesting: false,
        errorMsg: '',
      };
    case FETCH_CHATBOTS_FAILED: {
      return {
        ...state,
        isLoading: false,
        chatbots: [] as IChatbot[],
        errorMsg: payload?.message || '',
      };
    }
    case FETCH_CHATBOTS_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        errorMsg: '',
        chatbots: payload,
      };
    }
    case ASSIGN_CHATBOT:
      return {
        ...state,
        isLoading: false,
        isRequesting: true,
        errorMsg: '',
      };
    case ASSIGN_CHATBOT_FAILED: {
      return {
        ...state,
        isRequesting: false,
        errorMsg: payload?.message || '',
      };
    }
    case ASSIGN_CHATBOT_SUCCESSFUL: {
      return {
        ...state,
        isRequesting: false,
        errorMsg: '',
      };
    }
    default:
      return state;
  }
}
