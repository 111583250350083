import { createAction } from 'redux-actions';

export const FETCH_SYSTEM_SERVICES = 'schedule/systemServices/fetch';
export const FETCH_SYSTEM_SERVICES_SUCCESSFUL = 'schedule/systemServices/fetchSuccessful';
export const FETCH_SYSTEM_SERVICES_FAILED = 'schedule/systemServices/fetchSuccess';

export const FETCH_PROVIDER_SERVICES = 'appointments/providerServices';
export const FETCH_PUBLIC_PROVIDER_SERVICES = 'appointments/public/providerServices';
export const FETCH_PUBLIC_ALL_SERVICES = 'appointments/public/allServices';
export const FETCH_PROVIDER_SERVICES_SUCCESSFUL = 'appointments/providerServicesSuccessful';
export const FETCH_PROVIDER_SERVICES_FAILED = 'appointments/providerServicesFailed';

export const FETCH_PROVIDER_SCHEDULE = 'schedule/fetchProviderSchedule';
export const FETCH_PROVIDER_SCHEDULE_SUCCESSFUL = 'schedule/fetchProviderScheduleSuccessful';
export const FETCH_PROVIDER_SCHEDULE_FAILED = 'schedule/fetchProviderScheduleFailed';
export const FETCH_PUBLIC_PROVIDER_SCHEDULE = 'schedule/public/fetchProviderSchedule';
export const FETCH_PUBLIC_PROVIDER_SCHEDULE_SUCCESSFUL = 'schedule/public/fetchProviderScheduleSuccessful';
export const FETCH_PUBLIC_PROVIDER_SCHEDULE_FAILED = 'schedule/public/fetchProviderScheduleFailed';

export const scheduleActionCreators = {
  fetchSystemServices: createAction(FETCH_SYSTEM_SERVICES),
  fetchProviderServices: createAction(FETCH_PROVIDER_SERVICES),
  fetchPublicProviderServices: createAction(FETCH_PUBLIC_PROVIDER_SERVICES),
  fetchPublicAllServices: createAction(FETCH_PUBLIC_ALL_SERVICES),
  fetchProviderSchedule: createAction(FETCH_PROVIDER_SCHEDULE),
  fetchPublicProviderSchedule: createAction(FETCH_PUBLIC_PROVIDER_SCHEDULE),
};
