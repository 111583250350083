import { IAction } from 'redux/store/types';
import { ConversationState, IChatbot, IConversation } from './types';
import {
  FETCH_CONVERSATIONS_PUBLIC,
  FETCH_CONVERSATIONS_FAILED_PUBLIC,
  FETCH_CONVERSATIONS_SUCCESSFUL_PUBLIC,
  FETCH_CHATBOTS_PUBLIC,
  FETCH_CHATBOTS_FAILED_PUBLIC,
  FETCH_CHATBOTS_SUCCESSFUL_PUBLIC,
  ASSIGN_CHATBOT_PUBLIC,
  ASSIGN_CHATBOT_FAILED_PUBLIC,
  ASSIGN_CHATBOT_SUCCESSFUL_PUBLIC,
} from './actions';

export const DEFAULT = {
  isLoading: false,
  isRequesting: false,
  errorMsg: '',
  conversations: [] as IConversation[],
  chatbots: [] as IChatbot[],
};

export default function publicConversationReducer(state = DEFAULT, action: IAction): ConversationState {
  const { type, payload } = action;
  switch (type) {
    case FETCH_CONVERSATIONS_PUBLIC:
      return {
        ...state,
        isLoading: true,
        isRequesting: false,
        errorMsg: '',
      };
    case FETCH_CONVERSATIONS_FAILED_PUBLIC: {
      return {
        ...state,
        isLoading: false,
        conversations: [] as IConversation[],
        errorMsg: payload?.message || '',
      };
    }
    case FETCH_CONVERSATIONS_SUCCESSFUL_PUBLIC: {
      return {
        ...state,
        isLoading: false,
        errorMsg: '',
        conversations: payload,
      };
    }
    case FETCH_CHATBOTS_PUBLIC:
      return {
        ...state,
        isLoading: true,
        isRequesting: false,
        errorMsg: '',
      };
    case FETCH_CHATBOTS_FAILED_PUBLIC: {
      return {
        ...state,
        isLoading: false,
        chatbots: [] as IChatbot[],
        errorMsg: payload?.message || '',
      };
    }
    case FETCH_CHATBOTS_SUCCESSFUL_PUBLIC: {
      return {
        ...state,
        isLoading: false,
        errorMsg: '',
        chatbots: payload,
      };
    }
    case ASSIGN_CHATBOT_PUBLIC:
      return {
        ...state,
        isLoading: false,
        isRequesting: true,
        errorMsg: '',
      };
    case ASSIGN_CHATBOT_FAILED_PUBLIC: {
      return {
        ...state,
        isRequesting: false,
        errorMsg: payload?.message || '',
      };
    }
    case ASSIGN_CHATBOT_SUCCESSFUL_PUBLIC: {
      return {
        ...state,
        isRequesting: false,
        errorMsg: '',
      };
    }
    default:
      return state;
  }
}
