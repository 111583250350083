import dayjs from 'dayjs';
import moment from 'moment-timezone';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
// dayjs.tz.setDefault('America/New_York');

export const getUserTimeZone = (): string => {
  return moment.tz.guess();
};

export const numToTimeString = (mins: number) => {
  return dayjs(`2022-01-01T${Math.floor(mins / 100)}:${mins % 100}:00`).format('hh:mm a');
};

export const numToTime = (mins: number) => {
  return mins === 0 || mins
    ? dayjs(`2022-01-01T${Math.floor(mins / 100)}:${mins % 100}:00`).format('HH:mm')
    : '';
};

export const timeToNum = (str: string) => {
  return Number(str.split(':').join(''));
};

export const addTime = (num: number, addition: number) => {
  const mins = Math.floor(num / 100) * 60 + (num % 100) + addition;

  return Math.floor(mins / 60) * 100 + (mins % 60);
};

export const getTimeDifference = (date1: Date, date2: Date) => {
  const differenceInTime = Math.abs(date2.getTime() - date1.getTime());
  const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
  const differenceInHours = Math.ceil(differenceInTime / (1000 * 3600));
  return differenceInDays > 1 ? `${differenceInDays} Days ago` : `${differenceInHours}h ago`;
};

export const getDateLabel = (date: string, format?: string) => {
  return dayjs(date).isToday()
    ? 'Today'
    : dayjs(date).add(1, 'day').isTomorrow()
    ? 'Tomorrow'
    : dayjs(date).format(format ?? 'dddd, MMMM DD');
};

export const convertSlotToDate = (date: string, time: number): string => {
  const hours = Math.floor(time / 100);
  const minutes = time % 100;
  const newDate = new Date(date);
  newDate.setHours(hours);
  newDate.setMinutes(minutes);
  return newDate.toString();
};

export const formatTimeMessage = (time: string): string => {
  const date = dayjs(time);
  const now = dayjs();
  const diffMinutes = now.diff(date, 'minute');
  const diffHours = now.diff(date, 'hour');
  const diffDays = now.diff(date, 'day');

  if (diffMinutes < 1) {
    return 'Just now';
  }
  if (diffMinutes < 60) {
    return `${diffMinutes} minutes ago`;
  }
  if (diffHours < 24) {
    return date.format('h:mm a');
  }
  if (diffDays < 7) {
    return date.format('dddd');
  }
  return date.format('MMM DD, YYYY');
};

export default dayjs;
