import * as contentful from 'contentful/dist/contentful.browser.min';

const SPACE_ID = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
const ACCESS_TOKEN = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;
const HOST = 'cdn.contentful.com';

export const ContentfulClient = contentful.createClient({
  space: SPACE_ID,
  accessToken: ACCESS_TOKEN,
  host: HOST,
});

const queryMethod = ContentfulClient.getEntries;

ContentfulClient.getEntries = (query: any) => {
  return queryMethod.apply(this, [query]).catch(e => {
    console.log(e);

    return {
      total: 0,
      items: [],
    };
  });
};
