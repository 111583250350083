import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faCoffee as farFaCoffee,
  faHeart as farFaHeart,
  faBell as farFaBell,
  faBars as farFaBars,
  faStar as farFaStar,
  faCalendar as farFaCalendar,
  faClock as farFaClock,
  faMagnifyingGlass as farFaMagnifyingGlass,
  faMinus as farFaMinus,
  faCircleExclamation as farFaCircleExclamation,
  faCircleInfo as farFaCircleInfo,
  faEllipsisVertical as farFaEllipsisVertical,
  faEllipsis as farFaEllipsis,
  faGear as farFaGear,
  faXmark as farFaXmark,
  faStarHalfStroke as farFaStarHalfStroke,
  faPlus as farFaPlus,
  faCheck as farFaCheck,
  faCircleCheck as farFaCircleCheck,
  faCircleXmark as farFaCircleXmark,
  faCircleDashed as farFaCircleDashed,
  faLoader as farFaLoader,
  faAngleDown as farFaAngleDown,
  faAngleUp as farFaAngleUp,
  faCaretDown as farFaCaretDown,
  faCaretUp as farFaCaretUp,
  faCaretLeft as farFaCaretLeft,
  faCaretRight as farFaCaretRight,
  faArrowRight as farFaArrowRight,
  faArrowLeft as farFaArrowLeft,
  faArrowUp as farFaArrowUp,
  faArrowDown as farFaArrowDown,
  faArrowLeftToLine as farFaArrowLeftToLine,
  faObjectsColumn as farFaObjectsColumn,
  faCalendarRange as farFaCalendarRange,
  faMessageBot as farFaMessageBot,
  faUserDoctor as farFaUserDoctor,
  faMessage as farFaMessage,
  faReceipt as farFaReceipt,
  faUsersMedical as farFaUsersMedical,
  faRepeat as farFaRepeat,
  faLifeRing as farFaLifeRing,
  faRotateLeft as farFaRotateLeft,
  faBoxArchive as farFaBoxArchive,
  faPaperPlaneTop as farFaPaperPlaneTop,
  faCircleQuestion as farFaCircleQuestion,
  faMessages as farFaMessages,
  faCircleArrowRight as farFaCircleArrowRight,
  faCircleArrowLeft as farFaCircleArrowLeft,
  faCircleArrowDown as farFaCircleArrowDown,
  faCircleArrowUp as farFaCircleArrowUp,
  faAngleLeft as farFaAngleLeft,
  faAngleRight as farFaAngleRight,
  faCameraWeb as farFaCameraWeb,
  faCameraWebSlash as farFaCameraWebSlash,
  faMicrophone as farFaMicrophone,
  faMicrophoneSlash as farFaMicrophoneSlash,
  faPhoneHangup as farFaPhoneHangup,
  faDisplay as farFaDisplay,
  faDisplaySlash as farFaDisplaySlash,
  faExpand as farFaExpand,
  faCompress as farFaCompress,
  faTrashCan as farFaTrashCan,
  faWallet as farFaWallet,
  faSlider as farFaSlider,
  faArrowUpFromBracket as farFaArrowUpFromBracket,
  faCalendarCheck as farFaCalendarCheck,
  faIdCard as farFaIdCard,
  faThumbTack as farThumbTack,
  faReply as farReply,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faCoffee as fasFaCoffee,
  faHeart as fasFaHeart,
  faBell as fasFaBell,
  faBars as fasFaBars,
  faStar as fasFaStar,
  faCalendar as fasFaCalendar,
  faClock as fasFaClock,
  faMagnifyingGlass as fasFaMagnifyingGlass,
  faMinus as fasFaMinus,
  faCircleExclamation as fasFaCircleExclamation,
  faCircleInfo as fasFaCircleInfo,
  faEllipsisVertical as fasFaEllipsisVertical,
  faEllipsis as fasFaEllipsis,
  faGear as fasFaGear,
  faXmark as fasFaXmark,
  faStarHalfStroke as fasFaStarHalfStroke,
  faPlus as fasFaPlus,
  faCheck as fasFaCheck,
  faCircleCheck as fasFaCircleCheck,
  faCircleXmark as fasFaCircleXmark,
  faCircleDashed as fasFaCircleDashed,
  faLoader as fasFaLoader,
  faAngleDown as fasFaAngleDown,
  faAngleUp as fasFaAngleUp,
  faCaretDown as fasFaCaretDown,
  faCaretUp as fasFaCaretUp,
  faCaretLeft as fasFaCaretLeft,
  faCaretRight as fasFaCaretRight,
  faArrowRight as fasFaArrowRight,
  faArrowLeft as fasFaArrowLeft,
  faArrowUp as fasFaArrowUp,
  faArrowDown as fasFaArrowDown,
  faArrowLeftToLine as fasFaArrowLeftToLine,
  faObjectsColumn as fasFaObjectsColumn,
  faCalendarRange as fasFaCalendarRange,
  faMessageBot as fasFaMessageBot,
  faUserDoctor as fasFaUserDoctor,
  faMessage as fasFaMessage,
  faReceipt as fasFaReceipt,
  faUsersMedical as fasFaUsersMedical,
  faRepeat as fasFaRepeat,
  faLifeRing as fasFaLifeRing,
  faRotateLeft as fasFaRotateLeft,
  faBoxArchive as fasFaBoxArchive,
  faPaperPlaneTop as fasFaPaperPlaneTop,
  faCircleQuestion as fasFaCircleQuestion,
  faMessages as fasFaMessages,
  faCircleArrowRight as fasFaCircleArrowRight,
  faCircleArrowLeft as fasFaCircleArrowLeft,
  faCircleArrowDown as fasFaCircleArrowDown,
  faCircleArrowUp as fasFaCircleArrowUp,
  faAngleLeft as fasFaAngleLeft,
  faAngleRight as fasFaAngleRight,
  faCameraWeb as fasFaCameraWeb,
  faCameraWebSlash as fasFaCameraWebSlash,
  faMicrophone as fasFaMicrophone,
  faMicrophoneSlash as fasFaMicrophoneSlash,
  faPhoneHangup as fasFaPhoneHangup,
  faDisplay as fasFaDisplay,
  faDisplaySlash as fasFaDisplaySlash,
  faExpand as fasFaExpand,
  faCompress as fasFaCompress,
  faTrashCan as fasFaTrashCan,
  faWallet as fasFaWallet,
  faSlider as fasFaSlider,
  faArrowUpFromBracket as fasFaArrowUpFromBracket,
  faCalendarCheck as fasFaCalendarCheck,
  faIdCard as fasFaIdCard,
  faThumbTack as fasThumbTack,
  faReply as fasReply,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faSquare as fatFaSquare,
  faSquareCheck as fatFaSquareCheck,
  faSquareMinus as fatFaSquareMinus,
} from '@fortawesome/pro-thin-svg-icons';
// import { faCoffee as falFaCoffee } from '@fortawesome/pro-light-svg-icons'
// import { faCoffee as fadFaCoffee } from '@fortawesome/pro-duotone-svg-icons'
// import { faCoffee as fassFaCoffee } from '@fortawesome/sharp-solid-svg-icons'

library.add(
  farFaCoffee,
  fasFaCoffee,
  farFaHeart,
  fasFaHeart,
  farFaBell,
  fasFaBell,
  farFaBars,
  fasFaBars,
  farFaStar,
  fasFaStar,
  farFaCalendar,
  fasFaCalendar,
  farFaClock,
  fasFaClock,
  farFaMagnifyingGlass,
  fasFaMagnifyingGlass,
  farFaMinus,
  fasFaMinus,
  farFaCircleExclamation,
  fasFaCircleExclamation,
  farFaCircleInfo,
  fasFaCircleInfo,
  farFaEllipsisVertical,
  fasFaEllipsisVertical,
  farFaEllipsis,
  fasFaEllipsis,
  farFaGear,
  fasFaGear,
  farFaXmark,
  fasFaXmark,
  farFaStarHalfStroke,
  fasFaStarHalfStroke,
  farFaPlus,
  fasFaPlus,
  farFaCheck,
  fasFaCheck,
  farFaCircleCheck,
  fasFaCircleCheck,
  farFaCircleXmark,
  fasFaCircleXmark,
  farFaCircleDashed,
  fasFaCircleDashed,
  farFaLoader,
  fasFaLoader,
  farFaAngleDown,
  fasFaAngleDown,
  farFaAngleUp,
  fasFaAngleUp,
  farFaCaretDown,
  fasFaCaretDown,
  farFaCaretUp,
  fasFaCaretUp,
  farFaCaretLeft,
  fasFaCaretLeft,
  fasFaCaretRight,
  fasFaArrowRight,
  farFaCaretRight,
  fasFaCaretRight,
  farFaArrowRight,
  fasFaArrowRight,
  farFaArrowLeft,
  fasFaArrowLeft,
  farFaArrowUp,
  fasFaArrowUp,
  farFaArrowDown,
  fasFaArrowDown,
  farFaArrowLeftToLine,
  fasFaArrowLeftToLine,
  farFaObjectsColumn,
  fasFaObjectsColumn,
  farFaCalendarRange,
  fasFaCalendarRange,
  farFaMessageBot,
  fasFaMessageBot,
  farFaUserDoctor,
  fasFaUserDoctor,
  farFaMessage,
  fasFaMessage,
  farFaReceipt,
  fasFaReceipt,
  farFaUsersMedical,
  fasFaUsersMedical,
  farFaRepeat,
  fasFaRepeat,
  farFaLifeRing,
  fasFaLifeRing,
  farFaRotateLeft,
  fasFaRotateLeft,
  farFaBoxArchive,
  fasFaBoxArchive,
  farFaPaperPlaneTop,
  fasFaPaperPlaneTop,
  farFaCircleQuestion,
  fasFaCircleQuestion,
  farFaMessages,
  fasFaMessages,
  farFaCircleArrowRight,
  fasFaCircleArrowRight,
  farFaCircleArrowLeft,
  fasFaCircleArrowLeft,
  farFaCircleArrowDown,
  fasFaCircleArrowDown,
  farFaCircleArrowUp,
  fasFaCircleArrowUp,
  farFaAngleLeft,
  farFaAngleRight,
  farFaCameraWeb,
  farFaCameraWebSlash,
  farFaMicrophone,
  farFaMicrophoneSlash,
  farFaPhoneHangup,
  farFaDisplay,
  farFaDisplaySlash,
  farFaExpand,
  farFaCompress,
  farFaTrashCan,
  farFaWallet,
  farFaWallet,
  farFaSlider,
  fasFaAngleLeft,
  fasFaAngleRight,
  fasFaCameraWeb,
  fasFaCameraWebSlash,
  fasFaMicrophone,
  fasFaMicrophoneSlash,
  fasFaPhoneHangup,
  fasFaDisplay,
  fasFaDisplaySlash,
  fasFaExpand,
  fasFaCompress,
  fasFaTrashCan,
  fasFaWallet,
  fasFaWallet,
  fasFaSlider,
  farFaArrowUpFromBracket,
  fasFaArrowUpFromBracket,
  farFaCalendarCheck,
  fasFaCalendarCheck,
  farFaIdCard,
  fasFaIdCard,
  farThumbTack,
  fasThumbTack,
  farReply,
  fasReply,
  // thin icons
  fatFaSquare,
  fatFaSquareCheck,
  fatFaSquareMinus
);
