import type { AlertColor } from '@mui/material';
import { connect } from 'react-redux';

import { clearSnackbar } from 'redux/modules/snackbar';
import ToastMessage from 'components/shared/molecules/toast-message';

import { AppState } from 'redux/store/types';

type Props = {
  show: boolean;
  snackType: AlertColor;
  snackMessage: string;
  clearSnackbarAction: () => void;
};

function SnackbarContainer(props: Props) {
  return <ToastMessage {...props} />;
}

const mapStateToProps = (state: AppState) => state.snackbar;
const mapDispatchToProps = dispatch => ({
  clearSnackbarAction: () => dispatch(clearSnackbar()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(SnackbarContainer);
