import { createTheme } from '@mui/material/styles';
import { parseJsonTheme } from 'utils/parseJsonTheme';

// types
import { ICustomTheme } from 'types/theme';

// fonts
import InterThinTtf from 'assets/fonts/Inter-Thin.ttf';
import InterExtraLightTtf from 'assets/fonts/Inter-ExtraLight.ttf';
import InterLightTtf from 'assets/fonts/Inter-Light.ttf';
import InterRegularTtf from 'assets/fonts/Inter-Regular.ttf';
import InterMediumTtf from 'assets/fonts/Inter-Medium.ttf';
import InterSemiBoldTtf from 'assets/fonts/Inter-SemiBold.ttf';
import InterBoldTtf from 'assets/fonts/Inter-Bold.ttf';
import InterExtraBoldTtf from 'assets/fonts/Inter-ExtraBold.ttf';
import InterBlackTtf from 'assets/fonts/Inter-Black.ttf';

// parsed design tokens
const designTokens: ICustomTheme = parseJsonTheme();

declare module '@mui/material/styles' {
  interface Theme {
    color: typeof designTokens.color;
    fontFamily: typeof designTokens.fontFamily;
    fontSize: typeof designTokens.fontSize;
    fontWeight: typeof designTokens.fontWeight;
    lineHeight: typeof designTokens.lineHeight;
    letterSpacing: typeof designTokens.letterSpacing;
    paragraphSpacing: typeof designTokens.paragraphSpacing;
    textCase: typeof designTokens.textCase;
    textDecoration: typeof designTokens.textDecoration;
    // spacing: typeof designTokens.spacing;
    sizing: typeof designTokens.sizing;
    borderRadius: typeof designTokens.borderRadius;
    borderWidth: typeof designTokens.borderWidth;
    opacity: typeof designTokens.opacity;
    // semantic values
    typography: typeof designTokens.typography;
    shadow: typeof designTokens.shadow;
    border: typeof designTokens.border;
    borderDashed: typeof designTokens.borderDashed;
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    color: typeof designTokens.color;
    fontFamily: typeof designTokens.fontFamily;
    fontSize: typeof designTokens.fontSize;
    fontWeight: typeof designTokens.fontWeight;
    lineHeight: typeof designTokens.lineHeight;
    letterSpacing: typeof designTokens.letterSpacing;
    paragraphSpacing: typeof designTokens.paragraphSpacing;
    textCase: typeof designTokens.textCase;
    textDecoration: typeof designTokens.textDecoration;
    // spacing: typeof designTokens.spacing;
    sizing: typeof designTokens.sizing;
    borderRadius: typeof designTokens.borderRadius;
    borderWidth: typeof designTokens.borderWidth;
    opacity: typeof designTokens.opacity;
    // semantic values
    shadow: typeof designTokens.shadow;
    border: typeof designTokens.border;
    borderDashed: typeof designTokens.borderDashed;
  }
}

export const theme = createTheme({
  ...designTokens,
  spacing: 4,
  typography: {
    ...designTokens.typography,
    fontFamily: 'Inter',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 100;
          src: url(${InterThinTtf}) format('truetype'); 
        }
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 200;
          src: url(${InterExtraLightTtf}) format('truetype'); 
        }
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 300;
          src: url(${InterLightTtf}) format('truetype');
        }
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          src: url(${InterRegularTtf}) format('truetype');
        }
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          src: url(${InterMediumTtf}) format('truetype');
        }
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          src: url(${InterSemiBoldTtf}) format('truetype');
        }
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: bold;
          src: url(${InterBoldTtf}) format('truetype');
        }
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 800;
          src: url(${InterExtraBoldTtf}) format('truetype');
        }
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 900;
          src: url(${InterBlackTtf}) format('truetype');
        }
      `,
    },
  },
});
