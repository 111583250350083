import { call, put, select, takeLatest } from 'redux-saga/effects';

import { IAction, IPayloadAction } from 'redux/store/types';
import * as AuthService from 'services/auth/auth.service';

import {
  MAGIC_LOGIN_EMAIL_SENT,
  PUBLIC_BOOK_APPOINTMENT,
  PUBLIC_BOOK_APPOINTMENT_FAILED,
  PUBLIC_BOOK_APPOINTMENT_SUCCESS,
  USER_LOGIN_FAILED,
  USER_LOGIN_SUCCESSFUL,
  USER_LOGOUT,
  USER_MAGIC_LOGIN,
} from './actions';
import { AuthState, PublickBookAppointmentPayload } from './types';
import { FETCH_PROFILE } from '../profile/actions';
import { SHOW_SNACKBAR } from '../snackbar/actions';

function* loginWithMagicHandler(action: IAction) {
  const { provisionalPatientDetails, callback } = action.payload;
  try {
    const response = yield call(AuthService.loginViaMagicLink, action.payload);

    if (response.errors) {
      let errorMessage = response.errors[0].endUserMessage;
      const invalidPayload =
        !provisionalPatientDetails.nickName && errorMessage === 'Name is required for onboarding';
      if (invalidPayload) {
        errorMessage = 'Please enter registered email address';
      }
      yield put({
        type: USER_LOGIN_FAILED,
        payload: {
          message: errorMessage,
        },
      });
    } else {
      yield put({ type: MAGIC_LOGIN_EMAIL_SENT });
      if (callback) {
        callback();
      }
    }
  } catch (e: any) {
    yield put({
      type: USER_LOGIN_FAILED,
      payload: {
        message: `We don't recognize this email.  Please make sure it's correct and that you've already signed up for Confidant Health`,
      },
    });
  }
}

function* publicBookAppointmentHandler(
  action: IPayloadAction<{ data: PublickBookAppointmentPayload; callback: () => void }>
) {
  const { data, callback } = action.payload;
  const { provisionalPatientDetails, ...rest } = data;
  try {
    const response = yield call(AuthService.publicBookAppointment, { ...rest });

    if (response.errors) {
      let errorMessage = response.errors[0].endUserMessage;
      const invalidPayload =
        !provisionalPatientDetails.nickName && errorMessage === 'Name is required for onboarding';
      if (invalidPayload) {
        errorMessage = 'Please enter registered email address';
      }
      yield put({
        type: SHOW_SNACKBAR,
        payload: {
          snackType: 'error',
          snackMessage: errorMessage,
        },
      });
      yield put({
        type: PUBLIC_BOOK_APPOINTMENT_FAILED,
        payload: {
          message: errorMessage,
        },
      });
    } else {
      yield put({
        type: PUBLIC_BOOK_APPOINTMENT_SUCCESS,
      });
      if (callback) {
        void callback();
      }
    }
  } catch (e: any) {
    const message =
      e.data.errors?.[0]?.endUserMessage === 'Invalid email address'
        ? `We don't recognize this email.  Please make sure it's correct and that you've already signed up for Confidant Health`
        : e.data.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: SHOW_SNACKBAR,
      payload: {
        snackType: 'error',
        snackMessage: message,
      },
    });
    yield put({
      type: PUBLIC_BOOK_APPOINTMENT_FAILED,
      payload: {
        message,
      },
    });
  }
}

function* logoutHandler() {
  yield AuthService.logout();
}

function* authSaga(): IterableIterator<any> {
  yield takeLatest(USER_MAGIC_LOGIN, loginWithMagicHandler);
  yield takeLatest(PUBLIC_BOOK_APPOINTMENT, publicBookAppointmentHandler);
  yield takeLatest(USER_LOGOUT, logoutHandler);
  const auth: AuthState | undefined = yield select(state => state.auth);
  if (auth) {
    const { isAuthenticated, userInfo } = auth;
    if (isAuthenticated) {
      yield put({ type: FETCH_PROFILE });
      yield put({
        type: USER_LOGIN_SUCCESSFUL,
        payload: userInfo,
      });
    }
  }
}

export default authSaga;
