import { DirectLine } from 'botframework-directlinejs';

let instance: DirectLine;

export class DirectLineClient {
  static createInstance(token: string) {
    if (!token) {
      throw new Error('Directline Token is required');
    }

    instance = new DirectLine({
      token,
      webSocket: true,
      pollingInterval: 1000,
    });

    return instance;
  }

  static getInstance() {
    return instance;
  }
}
