// TODO: This file is a mess. Needs to be refactored. It's a copy of the mobile code.
import { IAction } from 'redux/store/types';
import {
  CHAT_ADD_MESSAGE_PUBLIC,
  CHAT_AUTO_REPLY_REGISTERED_PUBLIC,
  CHAT_EXIT_PUBLIC,
  CHAT_HIDE_SINGLE_CHOICES_PUBLIC,
  CHAT_INIT_SUCCESSFUL_PUBLIC,
  CHAT_LOAD_EARLIER_PUBLIC,
  CHAT_LOADED_EARLIER_PUBLIC,
  CHAT_PAUSE_PUBLIC,
  CHAT_REQUEST_INITIALIZE_PUBLIC,
  CHAT_RESUME_PUBLIC,
  CHAT_SEND_MESSAGE_PUBLIC,
  CHAT_SEND_MESSAGE_FAILED_PUBLIC,
  CHAT_SEND_MESSAGE_IN_PROGRESS_PUBLIC,
  CHAT_SEND_MESSAGE_SUCCESSFUL_PUBLIC,
  CHAT_TYPING_DETECTED_PUBLIC,
  CHAT_UPDATE_CONNECTION_STATUS_PUBLIC,
} from './actions';
import { USER_LOGOUT } from '../auth/actions';

import { IConnection } from '../connection/types';
import { ChatbotState, IChatbotMessage, IConversationContext } from './types';

export const DEFAULT_CHAT_LOCAL_CONTEXT = {
  contact: {} as IConnection,
  connectionStatus: 0,
  chatMessages: [] as IChatbotMessage[],
  conversationContext: null as IConversationContext | null,
  typingText: '',
  initTyping: '',
  sendingMessage: false,
  isConnectedBefore: false,
  pendingAutoReply: null,
  watermark: 0,
  loadEarlier: true,
  isLoadingEarlier: false,
  chatPaused: false,
  sendbirdStatus: 0,
};

export default function publicChatbotReducer(
  state = DEFAULT_CHAT_LOCAL_CONTEXT,
  action: IAction
): ChatbotState {
  const { type, payload } = action;

  switch (type) {
    case CHAT_INIT_SUCCESSFUL_PUBLIC: {
      return {
        ...state,
        connectionStatus: payload.connectionStatus,
        conversationContext: payload.conversationContext,
        contact: payload.contact,
        isConnectedBefore: payload.isConnectedBefore,
        watermark: payload.watermark,
        loadEarlier: payload.conversationHistory.length >= 10,
        chatMessages: payload.conversationHistory,
        initTyping: payload.conversationHistory.length === 0 ? 'Typing...' : '',
      };
    }
    case CHAT_PAUSE_PUBLIC: {
      return { ...state, chatPaused: true };
    }
    case CHAT_RESUME_PUBLIC: {
      return { ...state, chatPaused: false };
    }
    case CHAT_UPDATE_CONNECTION_STATUS_PUBLIC: {
      return { ...state, connectionStatus: payload.status };
    }
    case CHAT_TYPING_DETECTED_PUBLIC: {
      return {
        ...state,
        chatMessages: [payload, ...state.chatMessages],
        typingText: 'Typing...',
        initTyping: '',
      };
    }
    case CHAT_ADD_MESSAGE_PUBLIC: {
      // const watermark = payload.watermark ? payload.watermark : state.watermark;
      return {
        ...state,
        typingText: '',
        initTyping: '',
        chatMessages: [payload, ...state.chatMessages],
        watermark: state.watermark + 1,
      };
    }
    case CHAT_HIDE_SINGLE_CHOICES_PUBLIC: {
      if (state.chatMessages.length > 0) {
        state.chatMessages.forEach((msg: any) => {
          if (
            msg.attachments &&
            msg.attachments.length &&
            msg.attachments[0].contentType &&
            msg.attachments[0].contentType === 'single-select'
          ) {
            msg.attachments[0].content.choices = [];
          }
        });
      }

      return { ...state, chatMessages: [...state.chatMessages] };
    }
    case CHAT_SEND_MESSAGE_PUBLIC: {
      return {
        ...state,
        typingText: '',
        initTyping: '',
        chatMessages: [payload, ...state.chatMessages],
      };
    }
    case CHAT_SEND_MESSAGE_IN_PROGRESS_PUBLIC: {
      // Update sendingMessage status and also clear auto pending auto reply if needed
      let pendingAutoReply = state.pendingAutoReply as any;
      if (pendingAutoReply !== null && payload.text === pendingAutoReply._id) {
        pendingAutoReply = null;
      }
      return { ...state, sendingMessage: true, pendingAutoReply: pendingAutoReply };
    }
    case CHAT_SEND_MESSAGE_SUCCESSFUL_PUBLIC: {
      return { ...state, sendingMessage: false };
    }
    case CHAT_AUTO_REPLY_REGISTERED_PUBLIC: {
      return { ...state, pendingAutoReply: payload };
    }
    case CHAT_SEND_MESSAGE_FAILED_PUBLIC: {
      // TODO:: push this message to pendingAutoReply for replaying
      return { ...state, sendingMessage: false };
    }
    case CHAT_LOAD_EARLIER_PUBLIC: {
      return { ...state, isLoadingEarlier: true };
    }
    case CHAT_LOADED_EARLIER_PUBLIC: {
      return {
        ...state,
        loadEarlier: payload.conversationHistory.length === 10,
        watermark: state.watermark + payload.conversationHistory.length,
        isLoadingEarlier: false,
        chatMessages: [...state.chatMessages, ...payload.conversationHistory],
      };
    }
    case CHAT_EXIT_PUBLIC:
    case CHAT_REQUEST_INITIALIZE_PUBLIC: {
      return {
        ...state,
        ...DEFAULT_CHAT_LOCAL_CONTEXT,
        sendbirdStatus: state.sendbirdStatus,
        conversationContext: null,
      };
    }
    case USER_LOGOUT: {
      return DEFAULT_CHAT_LOCAL_CONTEXT;
    }
    default:
      return state;
  }
}
