import { createAction } from 'redux-actions';

export const FETCH_CONVERSATIONS = 'conversations/fetch';
export const FETCH_CONVERSATIONS_SUCCESSFUL = 'conversations/fetchSuccessful';
export const FETCH_CONVERSATIONS_FAILED = 'conversations/fetchSuccess';

export const ASSIGN_CHATBOT = 'chatbot/assign';
export const ASSIGN_CHATBOT_SUCCESSFUL = 'chatbot/assignSuccessful';
export const ASSIGN_CHATBOT_FAILED = 'chatbot/assignSuccess';

export const FETCH_CHATBOTS = 'chatbots/fetch';
export const FETCH_CHATBOTS_SUCCESSFUL = 'chatbots/fetchSuccessful';
export const FETCH_CHATBOTS_FAILED = 'chatbots/fetchSuccess';

export const CREATE_CHATBOT = 'chatbots/create';
export const CREATE_CHATBOT_SUCCESSFUL = 'chatbots/createSuccessful';
export const CREATE_CHATBOT_FAILED = 'chatbots/createFailed';

export const UPDATE_CHATBOT = 'chatbots/update';
export const UPDATE_CHATBOT_SUCCESSFUL = 'chatbots/updateSuccessful';
export const UPDATE_CHATBOT_FAILED = 'chatbots/updateFailed';

export const conversationActionCreators = {
  fetchConversations: createAction(FETCH_CONVERSATIONS),
  assignChatbot: createAction(ASSIGN_CHATBOT),
  fetchChatbots: createAction(FETCH_CHATBOTS),
  createChatbot: createAction(CREATE_CHATBOT),
};
