import { FC } from 'react';

interface IGlyphIcon {
  className?: string;
  color?: string;
  onClick?: () => void;
}

const NewHampshire: FC<IGlyphIcon> = ({ className = '', onClick, color }) => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
    >
      <path
        d="M39.556 7.524L41.604 40.74L41.22 44.068L43.46 47.012V48.676L44.676 50.212L44.484 50.66L45.188 50.532V50.852L43.78 54.18L42.116 54.052L40.004 55.46H38.98L38.148 56.676L36.676 57.7L19.844 57.124L18.564 55.076L18.5 54.18L19.076 52.388L19.716 51.684L20.228 46.756L20.74 45.988L20.932 40.868L21.892 39.844L22.276 37.668L23.62 36.388L24.004 34.468L25.22 32.676L24.964 32.1L25.86 30.18L26.052 26.66L26.884 25.508L28.74 25.38L31.172 23.332L32.196 22.052L32.388 20.964L33.028 20.644L31.812 17.636L33.54 14.564L33.22 12.516L34.948 8.42L34.436 7.972L36.228 6.692L38.34 7.652L39.364 6.5L39.556 7.524Z"
        fill={color}
      />
    </svg>
  );
};

export default NewHampshire;
