import { call, put, takeLatest } from 'redux-saga/effects';
import { IAction } from 'redux/store/types';
import * as InvoiceService from 'services/invoice/invoice.service';

import { FETCH_INVOICES, FETCH_INVOICES_FAILED, FETCH_INVOICES_SUCCESSFUL } from './actions';

function* fetchInvoices(action: IAction) {
  const {
    pageSize = 100,
    pageNumber = 1,
    sortBy = 'createdAt',
    sortOrder = 'desc',
    bodyRequest = {},
  } = action.payload;
  try {
    const { data } = yield call(InvoiceService.getInvoices, bodyRequest, {
      pageSize,
      pageNumber,
      sortBy,
      sortOrder,
    });
    yield put({ type: FETCH_INVOICES_SUCCESSFUL, payload: data });
    // TODO: switch correct error type
  } catch (e: any) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({ type: FETCH_INVOICES_FAILED, payload: { message } });
  }
}

function* invoiceSaga(): IterableIterator<any> {
  yield takeLatest(FETCH_INVOICES, fetchInvoices);
}

export default invoiceSaga;
