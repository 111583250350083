import makeStyles from '@mui/styles/makeStyles';
import type { Theme } from '@mui/material';

interface StyleProps {
  isRounded: boolean;
}

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.color.text.onColor,
  },
  xxsmall: {
    width: theme.sizing[8],
    height: theme.sizing[8],
    borderRadius: ({ isRounded }: StyleProps) => (isRounded ? theme.borderRadius.xsmall : ''),
    ...theme.typography.label.smallBold,
  },
  xsmall: {
    width: theme.sizing[10],
    height: theme.sizing[10],
    borderRadius: ({ isRounded }: StyleProps) => (isRounded ? theme.borderRadius.xsmall : ''),
    ...theme.typography.headline.headline5,
  },
  small: {
    width: theme.sizing[12],
    height: theme.sizing[12],
    borderRadius: ({ isRounded }: StyleProps) => (isRounded ? theme.borderRadius.small : ''),
    ...theme.typography.headline.headline4,
  },
  medium: {
    width: theme.sizing[14],
    height: theme.sizing[14],
    borderRadius: ({ isRounded }: StyleProps) => (isRounded ? theme.borderRadius.small : ''),
    ...theme.typography.headline.headline3,
  },
  large: {
    width: theme.sizing[18],
    height: theme.sizing[18],
    borderRadius: ({ isRounded }: StyleProps) => (isRounded ? theme.borderRadius.small : ''),
    ...theme.typography.headline.headline2,
  },
  xlarge: {
    width: theme.sizing[20],
    height: theme.sizing[20],
    borderRadius: ({ isRounded }: StyleProps) => (isRounded ? theme.borderRadius.medium : ''),
    ...theme.typography.headline.headline2,
  },
  xxlarge: {
    width: theme.sizing[24],
    height: theme.sizing[24],
    borderRadius: ({ isRounded }: StyleProps) => (isRounded ? theme.borderRadius.medium : ''),
    ...theme.typography.headline.headline1,
  },
  xxxlarge: {
    width: theme.sizing[40],
    height: theme.sizing[40],
    borderRadius: ({ isRounded }: StyleProps) => (isRounded ? theme.borderRadius.large : ''),
    ...theme.typography.display.display2,
  },
}));
