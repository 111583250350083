import { createAction } from 'redux-actions';

export const USER_MAGIC_LOGIN = 'auth/MAGIC_LOGIN';
export const MAGIC_LOGIN_EMAIL_SENT = 'auth/MAGIC_LOGIN_EMAIL_SENT';
export const RESET_EMAIL_SENT = 'auth/RESET_EMAIL_SENT';

export const USER_LOGIN = 'auth/USER_LOGIN';
export const USER_LOGOUT = 'auth/USER_LOGOUT';
export const USER_LOGIN_SUCCESSFUL = 'auth/LOGIN_SUCCESSFUL';
export const USER_INITIAL_SUCCESSFUL = 'auth/INITIAL_SUCCESSFUL';
export const USER_LOGIN_FAILED = 'auth/LOGIN_FAILED';
export const NETWORK_STATUS_CHANGED = 'auth/NETWORK_STATUS_CHANGED';
export const PUBLIC_BOOK_APPOINTMENT = 'auth/public/BOOK_APPOINTMENT';
export const PUBLIC_BOOK_APPOINTMENT_SUCCESS = 'auth/public/BOOK_APPOINTMENT/Success';
export const PUBLIC_BOOK_APPOINTMENT_FAILED = 'auth/public/BOOK_APPOINTMENT/Failed';

export const authActionCreators = {
  loginWithMagic: createAction(USER_MAGIC_LOGIN),
  publicBookAppointment: createAction(PUBLIC_BOOK_APPOINTMENT),
  login: createAction(USER_LOGIN_SUCCESSFUL),
  initialSuccess: createAction(USER_INITIAL_SUCCESSFUL),
  resetEmailSent: createAction(RESET_EMAIL_SENT),
  logout: createAction(USER_LOGOUT),
};
