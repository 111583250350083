import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import type { AlertColor, SnackbarOrigin } from '@mui/material';

type Props = {
  show: boolean;
  snackType: AlertColor;
  snackMessage: string;
  clearSnackbarAction: () => void;
  vertical?: SnackbarOrigin['vertical'];
  horizontal?: SnackbarOrigin['horizontal'];
};

function ToastMessage({
  show,
  snackType,
  snackMessage,
  clearSnackbarAction,
  vertical = 'bottom',
  horizontal = 'right',
}: Props) {
  return (
    <Snackbar
      open={show}
      autoHideDuration={5000}
      onClose={clearSnackbarAction}
      anchorOrigin={{ vertical, horizontal }}
    >
      <div>
        <Alert onClose={clearSnackbarAction} severity={snackType}>
          {snackMessage}
        </Alert>
      </div>
    </Snackbar>
  );
}

export { ToastMessage };
