const env = process.env.REACT_APP_ENV || process.env.NODE_ENV || 'staging';
export const CH_HELP_CENTER_CONTACT = 'help@confidanthealth.com';
export const S3_BUCKET_URL =
  env === 'prod' || env === 'production'
    ? 'http://confidant-health-prod.s3.amazonaws.com/'
    : 'http://confidant-health.s3.amazonaws.com/';
export const CHATBOT_DEFAULT_AVATAR_URL = 'https://i.imgur.com/Tgbdv8K.png';
export const DEFAULT_IMAGE = 'profileImages/testUser_defaultAvatar.png';
export const DEFAULT_GROUP_IMAGE = 'profileImages/testUser_defaultGroupAvatar.png';

export enum LoginType {
  ADMIN = 'ADMIN',
  PROVIDER = 'PRACTITIONER',
  ADMIN_LABEL = 'Admin',
  PROVIDER_LABEL = 'Provider',
}

export const navItems = [
  {
    title: 'Overview',
    icon: 'fa-regular fa-objects-column',
    link: '/overview',
  },
  {
    title: 'Appointments',
    icon: 'fa-regular fa-calendar-range',
    link: '/appointments',
  },
  {
    title: 'Chatbots',
    icon: 'fa-regular fa-message-bot',
    link: '/chatbots',
  },
  {
    title: 'Chats',
    icon: 'fa-regular fa-messages',
    link: '/chats',
  },
  {
    title: 'Invoices',
    icon: 'fa-regular fa-receipt',
    link: '/invoices',
  },
];

export const GENDER_OPTIONS = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
  { label: 'Intersex', value: 'Intersex' },
];
