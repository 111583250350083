import dayjs from 'dayjs';
import { IConnection } from './types';
import { AvatarColor } from 'components/shared/atoms/avatar';

const avatarColors = Object.values(AvatarColor);

export const sortConnections = (roaster = [] as IConnection[]) => {
  return roaster.sort((a, b) => {
    if (!a.lastMessage) {
      return 1;
    }
    return dayjs(b.lastModified).unix() - dayjs(a.lastModified).unix();
  });
};

export const addColorCode = (connections: any[]) => {
  let updatedConnections = connections;
  if (updatedConnections && updatedConnections.length > 0) {
    updatedConnections = updatedConnections.map((item, index) => {
      if (!item.profilePicture) {
        item.colorCode = avatarColors[index % avatarColors.length];
      }
      return item;
    });
  }
  return updatedConnections;
};

export const formatTimeForConnection = (connection: IConnection) => {
  let timestamp: number | null = null;
  if (connection.lastMessageTimestamp) {
    let timeString = connection.lastMessageTimestamp;
    if (timeString.indexOf('-') === -1) {
      timeString = parseInt(connection.lastMessageTimestamp, 10);
    }
    timestamp = timeString ? new Date(timeString).getTime() : null;
  }
  connection.lastMessageTimestamp = timestamp?.toString() ?? '';
  if (connection.type === 'PRACTITIONER' || connection.type === 'MATCH_MAKER') {
    connection['nickname'] = connection.name;
    connection['userId'] = connection.connectionId;
  }
  return connection;
};

export const getTimeFormattedConnections = (connections: IConnection[]) => {
  return connections?.map(connection => {
    return formatTimeForConnection(connection);
  });
};
