import { createStore, applyMiddleware, compose, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import compact from 'lodash/compact';
import { persistStore, Persistor, getStoredState, REHYDRATE } from 'redux-persist';

import rootReducer from './reducers';
import sagas from './sagas';

// define type of window
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: typeof compose;
  }
}

interface IStore {
  persistor: Persistor;
  store: Store;
}

export function rehydrateCrossBrowser(dispatch, persistConfig) {
  return async function () {
    const state = await getStoredState(persistConfig);

    dispatch({
      type: REHYDRATE,
      key: persistConfig.key,
      payload: state,
    });
  };
}
export default function initializeStore(): IStore {
  let middlewares;
  const sagaMiddleware = createSagaMiddleware();

  if (process.env.NODE_ENV !== 'production') {
    const logger = createLogger({ collapsed: true, duration: true });
    middlewares = compact([sagaMiddleware, logger]);
  } else {
    middlewares = [sagaMiddleware];
  }

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(...middlewares)));

  sagaMiddleware.run(sagas, store);

  const persistor = persistStore(store, null, () => {
    store.getState();
  });

  return { store, persistor };
}
