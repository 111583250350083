import React, { FC } from 'react';

interface IGlyphIcon {
  className?: string;
  color?: string;
  onClick?: () => void;
}

const Upload: FC<IGlyphIcon> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none">
      <path
        d="M12.2969 8.75781C12.6484 8.40625 13.2344 8.40625 13.5859 8.75781L16.7109 11.8828C17.1016 12.2734 17.1016 12.8594 16.7109 13.2109C16.3594 13.6016 15.7734 13.6016 15.4219 13.2109L13.8984 11.6875V16.9219C13.8984 17.4688 13.4688 17.8594 12.9609 17.8594C12.4141 17.8594 12.0234 17.4688 12.0234 16.9219V11.6875L10.4609 13.2109C10.1094 13.6016 9.52344 13.6016 9.17188 13.2109C8.78125 12.8594 8.78125 12.2734 9.17188 11.8828L12.2969 8.75781ZM6.08594 20.6719C2.96094 20.6719 0.460938 18.1719 0.460938 15.0469C0.460938 12.625 2.02344 10.5156 4.21094 9.77344V9.73438C4.21094 6.14062 7.14062 3.17188 10.7734 3.17188C12.7266 3.17188 14.5234 4.07031 15.7344 5.47656C16.3203 5.20312 16.9453 5.04688 17.6484 5.04688C20.2266 5.04688 22.3359 7.15625 22.3359 9.73438C22.3359 10.0078 22.2969 10.2422 22.2578 10.5156C24.1328 11.3359 25.4609 13.2109 25.4609 15.3594C25.4609 18.2109 23.2344 20.5156 20.4609 20.6719H6.08594ZM10.7734 5.04688C8.15625 5.04688 6.08594 7.15625 6.08594 9.73438V11.1016L4.79688 11.5312C3.35156 12.0391 2.33594 13.4453 2.33594 15.0469C2.33594 17.1172 3.97656 18.7969 6.08594 18.7969H20.2266H20.3438C22.1406 18.7188 23.5859 17.1953 23.5859 15.3594C23.5859 13.9531 22.7266 12.7422 21.5156 12.2344L20.1875 11.6484L20.4219 10.2031C20.4219 10.0469 20.4609 9.89062 20.4609 9.73438C20.4609 8.21094 19.1719 6.92188 17.6484 6.92188C17.2188 6.92188 16.8281 7.03906 16.4766 7.19531L15.2266 7.74219L14.2891 6.6875C13.4297 5.71094 12.1797 5.04688 10.7344 5.04688H10.7734Z"
        fill="#0374DD"
      />
    </svg>
  );
};

export default Upload;
