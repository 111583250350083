import { createAction } from 'redux-actions';
import { FETCH_BOT_PROGRESS } from '../connection/actions';

export const CHAT_REQUEST_INITIALIZE = 'chat/REQUEST_INITIALIZE';
export const CHAT_RESPONSE_RECEIVED = 'chat/RESPONSE_RECEIVED';
export const CHAT_INITIALIZING = 'chat/INITIALIZING';
export const CHAT_UPDATE_CONNECTION_STATUS = 'chat/UPDATE_CONNECTION_STATUS';
export const CHAT_INIT_FAILED = 'chat/INIT_FAILED';
export const CHAT_TOKEN_EXPIRED = 'chat/TOKEN_EXPIRED';
export const CHAT_INIT_SUCCESSFUL = 'chat/INIT_SUCCESSFUL';
export const CHAT_FETCH_NEXT_MSG = 'chat/FETCH_NEXT_MSG';
export const CHAT_SEND_MESSAGE = 'chat/SEND_MESSAGE';
export const CHAT_HIDE_SINGLE_CHOICES = 'chat/HIDE_SINGLE_CHOICES';
export const CHAT_SEND_MESSAGE_IN_PROGRESS = 'chat/SEND_MESSAGE_IN_PROGRESS';
export const CHAT_SEND_MESSAGE_FAILED = 'chat/SEND_MESSAGE_FAILED';
export const CHAT_SEND_MESSAGE_SUCCESSFUL = 'chat/SEND_MESSAGE_SUCCESSFUL';
export const CHAT_TYPING_DETECTED = 'chat/TYPING_DETECTED';
export const CHAT_ADD_MESSAGE = 'chat/ADD_MESSAGE';
export const CHAT_AUTO_REPLY_REGISTERED = 'chat/AUTO_REPLY_REGISTERED';
export const CHAT_LOAD_EARLIER = 'chat/LOAD_EARLIER';
export const CHAT_LOADING_EARLIER = 'chat/LOADING_EARLIER';
export const CHAT_LOADED_EARLIER = 'chat/LOADED_EARLIER';
export const CHAT_PAUSE = 'chat/PAUSE';
export const CHAT_RESUME = 'chat/RESUME';
export const CHAT_EXIT = 'chat/EXIT';

const createActionWrapper = action => {
  return createAction(
    action,
    data => data?.payload,
    data => data?.meta
  );
};

export const chatbotActionCreators = {
  chatRequestInitialize: createActionWrapper(CHAT_REQUEST_INITIALIZE),
  chatUpdateConnectionStatus: createActionWrapper(CHAT_UPDATE_CONNECTION_STATUS),
  chatPaused: createAction(CHAT_PAUSE),
  chatResumed: createAction(CHAT_RESUME),
  chatInitFailed: createActionWrapper(CHAT_INIT_FAILED),
  chatInitSuccessful: createActionWrapper(CHAT_INIT_SUCCESSFUL),
  chatSendMessage: createActionWrapper(CHAT_SEND_MESSAGE),
  chatSendMessageInProgress: createActionWrapper(CHAT_SEND_MESSAGE_IN_PROGRESS),
  chatSendMessageFailed: createActionWrapper(CHAT_SEND_MESSAGE_FAILED),
  chatSendMessageSuccessful: createActionWrapper(CHAT_SEND_MESSAGE_SUCCESSFUL),
  chatTypingDetected: createActionWrapper(CHAT_TYPING_DETECTED),
  chatAddMessage: createActionWrapper(CHAT_ADD_MESSAGE),
  chatLoadEarlier: createActionWrapper(CHAT_LOAD_EARLIER),
  chatLoadingEarlier: createActionWrapper(CHAT_LOADING_EARLIER),
  chatLoadedEarlier: createActionWrapper(CHAT_LOADED_EARLIER),
  chatExit: createActionWrapper(CHAT_EXIT),
  chatFetchNext: createActionWrapper(CHAT_FETCH_NEXT_MSG),
  updateBotProgress: createAction(FETCH_BOT_PROGRESS),
};
