import { FC } from 'react';

interface IGlyphIcon {
  className?: string;
  color?: string;
  onClick?: () => void;
}

const ExclamationCircleFilled: FC<IGlyphIcon> = ({ className = '', onClick, color }) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
    >
      <path
        d="M10.9609 20.9219C7.36719 20.9219 4.08594 19.0469 2.28906 15.9219C0.492188 12.8359 0.492188 9.04688 2.28906 5.92188C4.08594 2.83594 7.36719 0.921875 10.9609 0.921875C14.5156 0.921875 17.7969 2.83594 19.5938 5.92188C21.3906 9.04688 21.3906 12.8359 19.5938 15.9219C17.7969 19.0469 14.5156 20.9219 10.9609 20.9219ZM10.9609 5.92188C10.4141 5.92188 10.0234 6.35156 10.0234 6.85938V11.2344C10.0234 11.7812 10.4141 12.1719 10.9609 12.1719C11.4688 12.1719 11.8984 11.7812 11.8984 11.2344V6.85938C11.8984 6.35156 11.4688 5.92188 10.9609 5.92188ZM9.71094 14.6719C9.71094 15.375 10.2578 15.9219 10.9609 15.9219C11.625 15.9219 12.2109 15.375 12.2109 14.6719C12.2109 14.0078 11.625 13.4219 10.9609 13.4219C10.2578 13.4219 9.71094 14.0078 9.71094 14.6719Z"
        fill={color}
      />
    </svg>
  );
};

export default ExclamationCircleFilled;
