import React, { Fragment, Suspense, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, PathRouteProps, Route, Routes } from 'react-router-dom';
import { Provider, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import OneSignalReact from 'react-onesignal';
// mui
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { LicenseInfo } from '@mui/x-license-pro';

// redux
import createStore from 'redux/store';
import { getAuth } from 'redux/modules/auth/selectors';

import { theme } from 'themes';
import { InAppNotification } from 'components/app-notifications';
import SnackbarContainer from 'components/snackbar';
import FallbackSpin from 'components/shared/atoms/fallback';
import { routes } from 'routes';
import reportWebVitals from './reportWebVitals';

import 'lib/fontawesome';
import SocketListener from 'lib/socket/socket-listener';
import { getUserInfo } from 'utils';
import getConfig from 'config';
// import AnimatedRoutes from 'AnimatedRoutes';

export const { store, persistor } = createStore();
const root = createRoot(document.getElementById('root') as Element);
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY as string);

const initOneSignal = async () => {
  try {
    await OneSignalReact.init({
      appId: getConfig.api.oneSignalAppId as string,
      allowLocalhostAsSecureOrigin: true,
    });
    console.log('One Signal Enabled', await OneSignalReact.isPushNotificationsEnabled());
    console.log('OneSignal initialized!');
  } catch (error) {
    console.log('One Signal Init Error', error);
  }
};

export type RouteConfig = PathRouteProps & {
  guard?: any;
  component?: any;
};

const App = () => {
  const { userId, authorities } = getUserInfo();
  const { isAuthenticated } = useSelector(getAuth);

  useEffect(() => {
    if (isAuthenticated && userId && authorities) {
      SocketListener.initSocket({ userId, authority: authorities }, store);
      initOneSignal();
    }
  }, [isAuthenticated]);

  return (
    <BrowserRouter>
      <Suspense fallback={<FallbackSpin />}>
        <Routes>
          {routes.map((route: RouteConfig, i: number) => {
            const Guard = route.guard || Fragment;
            const Component = route.component;

            return (
              <Route
                key={i}
                path={route.path}
                element={
                  <Guard>
                    <Component />
                  </Guard>
                }
              />
            );
          })}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <InAppNotification />
            <App />
            <SnackbarContainer />
          </ThemeProvider>
        </StyledEngineProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
