import { call, put, takeLatest } from 'redux-saga/effects';
import * as StateService from 'services/state/state.service';
import {
  FETCH_STATES,
  FETCH_STATES_FAILED,
  FETCH_STATES_SUCCESSFUL,
  FETCH_PAYERS_BY_STATE_ID,
  FETCH_PAYERS_BY_STATE_ID_FAILED,
  FETCH_PAYERS_BY_STATE_ID_SUCCESSFUL,
  FETCH_PAYERS_BY_STATE_NAME,
  FETCH_PAYERS_BY_STATE_NAME_FAILED,
  FETCH_PAYERS_BY_STATE_NAME_SUCCESSFUL,
  FETCH_ACTIVE_STATES,
  FETCH_ACTIVE_STATES_FAILED,
  FETCH_ACTIVE_STATES_SUCCESSFUL,
} from './actions';
import { IAction } from 'redux/store/types';

function* fetchStates() {
  try {
    const response = yield call(StateService.getStates);
    if (response.status === 200) {
      const states = response?.data?.data || [];
      yield put({
        type: FETCH_STATES_SUCCESSFUL,
        payload: {
          states,
        },
      });
    } else {
      yield put({
        type: FETCH_STATES_FAILED,
        payload: {
          message: response.errors[0].endUserMessage,
        },
      });
    }
  } catch (e) {
    yield put({
      type: FETCH_STATES_FAILED,
      payload: { message: e },
    });
  }
}

function* fetchActiveStates() {
  try {
    const response = yield call(StateService.getActiveStates);
    if (response.status === 200) {
      const states = response?.data?.data || [];
      yield put({
        type: FETCH_ACTIVE_STATES_SUCCESSFUL,
        payload: {
          states,
        },
      });
    } else {
      yield put({
        type: FETCH_ACTIVE_STATES_FAILED,
        payload: {
          message: response.errors[0].endUserMessage,
        },
      });
    }
  } catch (e) {
    yield put({
      type: FETCH_ACTIVE_STATES_FAILED,
      payload: { message: e },
    });
  }
}

function* fetchPayersByStateId(action: IAction) {
  try {
    const response = yield call(StateService.getPayersByStateId, action.payload);
    if (response.status === 200) {
      const payers = response?.data?.data || [];
      yield put({
        type: FETCH_PAYERS_BY_STATE_ID_SUCCESSFUL,
        payload: {
          payers,
        },
      });
    } else {
      yield put({
        type: FETCH_PAYERS_BY_STATE_ID_FAILED,
        payload: {
          message: response.errors[0].endUserMessage,
        },
      });
    }
  } catch (e) {
    yield put({
      type: FETCH_PAYERS_BY_STATE_ID_FAILED,
      payload: { message: e },
    });
  }
}

function* fetchPayersByStateName(action: IAction) {
  try {
    const response = yield call(StateService.getPayersByStateName, action.payload);
    if (response.status === 200) {
      const payers = response?.data?.data || [];
      yield put({
        type: FETCH_PAYERS_BY_STATE_NAME_SUCCESSFUL,
        payload: {
          payers,
        },
      });
    } else {
      yield put({
        type: FETCH_PAYERS_BY_STATE_NAME_FAILED,
        payload: {
          message: response.errors[0].endUserMessage,
        },
      });
    }
  } catch (e) {
    yield put({
      type: FETCH_PAYERS_BY_STATE_NAME_FAILED,
      payload: { message: e },
    });
  }
}

export default function* stateSaga(): IterableIterator<any> {
  yield takeLatest(FETCH_STATES, fetchStates);
  yield takeLatest(FETCH_ACTIVE_STATES, fetchActiveStates);
  yield takeLatest(FETCH_PAYERS_BY_STATE_ID, fetchPayersByStateId);
  yield takeLatest(FETCH_PAYERS_BY_STATE_NAME, fetchPayersByStateName);
}
