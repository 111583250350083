import { FC } from 'react';

interface IGlyphIcon {
  className?: string;
  color?: string;
  onClick?: () => void;
}

const Connecticut: FC<IGlyphIcon> = ({ className = '', onClick, color }) => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
    >
      <path
        d="M25.3453 11.6545L30.8364 11.9818L30.9995 12.9636L32.25 12.8545L32.3044 11.9818L61.0651 12.0909L61.5 32.3818L61.2282 36.7455L60.5214 37.6727L60.0864 40.4L59.1622 40.7273L59.5971 40.0727L58.836 39.6909H56.4438L54.2691 40.7273L52.8555 39.3091L52.0943 41L49.5934 40.4L49.3216 41.4909L48.506 41.0545L46.6032 42.2L44.8634 41.1091V39.6364L43.3954 38.9273L44.6459 40.6182L44.2653 41.4909L44.809 41.8727L44.1566 42.3636L41.6013 41.9818L39.9703 43.1818L39.5897 42.7455L38.9916 42.9636L37.0888 42.2L35.349 42.6364L34.4791 43.1818L32.9568 42.7455L30.9995 43.1273H28.6617L28.0637 42.3636L27.9006 41.1636L24.9647 44.6545L23.7142 44.9273L21.5395 46.6182L20.6696 47L20.0172 46.2364L18.658 47.1636L18.2774 46.9455L17.2988 48.1455L16.2658 47.8182L14.4717 48.7455L10.6115 51.2L9.68727 51.1455L9.14359 52.2364L8.92612 51.9091L7.18634 52.8364L6.64266 52.2909L5.28346 53L3 49.1818L10.3941 44.8182L8.54554 41.6545L10.6659 11L25.3453 11.6545Z"
        fill={color}
      />
    </svg>
  );
};

export default Connecticut;
