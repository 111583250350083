import { IAction } from 'redux/store/types';
import {
  MAGIC_LOGIN_EMAIL_SENT,
  PUBLIC_BOOK_APPOINTMENT,
  PUBLIC_BOOK_APPOINTMENT_FAILED,
  PUBLIC_BOOK_APPOINTMENT_SUCCESS,
  USER_LOGIN,
  USER_LOGIN_FAILED,
  USER_LOGIN_SUCCESSFUL,
  USER_LOGOUT,
  USER_MAGIC_LOGIN,
} from './actions';
import { AuthState } from './types';

const token = localStorage.getItem('authorizationToken');

const DEFAULT: AuthState = {
  isAuthenticated: !!token,
  isLoading: false,
  emailSent: false,
  errorMsg: '',
  userInfo: {
    userId: '',
    nickName: '',
    authorities: '',
  },
};

export default function authReducer(state = DEFAULT, action: IAction): AuthState {
  const { type, payload } = action;

  switch (type) {
    case USER_LOGIN: {
      return {
        ...state,
        emailSent: false,
        isLoading: false,
        isAuthenticated: true,
        errorMsg: '',
      };
    }

    case USER_MAGIC_LOGIN: {
      return {
        ...state,
        emailSent: false,
        isLoading: true,
      };
    }

    case PUBLIC_BOOK_APPOINTMENT: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case PUBLIC_BOOK_APPOINTMENT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case PUBLIC_BOOK_APPOINTMENT_FAILED: {
      return {
        ...state,
        isLoading: false,
        errorMsg: payload?.message,
      };
    }

    case MAGIC_LOGIN_EMAIL_SENT: {
      return {
        ...state,
        emailSent: true,
        isLoading: false,
      };
    }

    case USER_LOGIN_FAILED: {
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
        emailSent: false,
        errorMsg: payload?.message,
      };
    }

    case USER_LOGIN_SUCCESSFUL: {
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        errorMsg: null,
        userInfo: {
          userId: payload?.userId,
          authorities: payload?.authorities,
          nickName: payload?.nickName,
        },
      };
    }

    case USER_LOGOUT: {
      return {
        ...DEFAULT,
        isAuthenticated: false,
      };
    }

    default: {
      return state;
    }
  }
}
