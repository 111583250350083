import { FC } from 'react';
import Box from '@mui/material/Box';
import cx from 'clsx';

// styles
import { useStyles } from './Badge.styles';
import {
  ActiveInactiveBadge,
  AppointmentBadge,
  AvailabilityBadge,
  BadgeAppointmentStatus,
  BadgeAvailableStatus,
  BadgeColorVariant,
  BadgeInvoiceStatus,
  BadgeProgressStatus,
  BadgeProps,
  BadgeStyleVariant,
  BadgeType,
  DefaultBadge,
  InvoiceBadge,
  ProgressBadge,
} from './Badge.types';
import { Icons } from '../icons';
import { theme } from 'themes';

const getCapitalizedString = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const Badge: FC<BadgeProps> = ({ className = '', type = BadgeType.DEFAULT, ...rest }) => {
  const classes = useStyles();
  const { color, style, text } = (rest as DefaultBadge) || {};
  const { selected = false, status = BadgeAppointmentStatus.REQUESTED } = (rest as AppointmentBadge) || {};
  const { selected: progressSelected = false, status: progressStatus = BadgeProgressStatus.INPROGRESS } =
    (rest as ProgressBadge) || {};
  const { selected: invoiceSelected = false, status: invoiceStatus = BadgeInvoiceStatus.PENDING } =
    (rest as InvoiceBadge) || {};
  const { status: availabilityStatus, text: availabilityText } = (rest as AvailabilityBadge) || {};
  const { isActive = true } = rest as ActiveInactiveBadge;

  const render = () => {
    switch (type) {
      case BadgeType.APPOINTMENT:
        return getCapitalizedString(status);
      case BadgeType.PROGRESS:
        return getCapitalizedString(progressStatus);
      case BadgeType.INVOICE:
        return getCapitalizedString(invoiceStatus);
      case BadgeType.AVAILABILITY:
        return availabilityStatus === BadgeAvailableStatus.AVAILABLE ? (
          <>
            <Icons icon="fa-solid fa-circle-check" color={theme.color.text.success} />
            <Box className="text" color={theme.color.text.success}>
              {availabilityText ?? 'Available tomorrow'}
            </Box>
          </>
        ) : availabilityStatus === BadgeAvailableStatus.NOTAVAILABLE ? (
          <>
            <Icons icon="fa-solid fa-circle-xmark" color={theme.color.text.tertiary} />
            <Box className="text" color={theme.color.text.tertiary}>
              {availabilityText ?? 'Not available'}
            </Box>
          </>
        ) : (
          <>
            <Icons icon="fa-solid fa-circle-arrow-right" color={theme.color.text.secondary} />
            <Box className="text" color={theme.color.text.secondary}>
              Next Available - {availabilityText}
            </Box>
          </>
        );
      case BadgeType.ACTIVE_INACTIVE:
        return getCapitalizedString(isActive ? 'Active' : 'Inactive');

      default:
        return text;
    }
  };

  return (
    <Box
      className={cx({
        [classes.root]: true,
        [classes.neutralDefault]:
          type === BadgeType.DEFAULT &&
          color === BadgeColorVariant.NEUTRAL &&
          style === BadgeStyleVariant.DEFAULT,
        [classes.neutralFillSubtle]:
          type === BadgeType.DEFAULT &&
          color === BadgeColorVariant.NEUTRAL &&
          style === BadgeStyleVariant.FILLSUBTLE,
        [classes.neutralFill]:
          type === BadgeType.DEFAULT &&
          color === BadgeColorVariant.NEUTRAL &&
          style === BadgeStyleVariant.FILL,
        [classes.primaryDefault]:
          type === BadgeType.DEFAULT &&
          color === BadgeColorVariant.PRIMARY &&
          style === BadgeStyleVariant.DEFAULT,
        [classes.primaryFillSubtle]:
          type === BadgeType.DEFAULT &&
          color === BadgeColorVariant.PRIMARY &&
          style === BadgeStyleVariant.FILLSUBTLE,
        [classes.primaryFill]:
          type === BadgeType.DEFAULT &&
          color === BadgeColorVariant.PRIMARY &&
          style === BadgeStyleVariant.FILL,
        [classes.secondaryDefault]:
          type === BadgeType.DEFAULT &&
          color === BadgeColorVariant.SECONDARY &&
          style === BadgeStyleVariant.DEFAULT,
        [classes.secondaryFillSubtle]:
          type === BadgeType.DEFAULT &&
          color === BadgeColorVariant.SECONDARY &&
          style === BadgeStyleVariant.FILLSUBTLE,
        [classes.secondaryFill]:
          type === BadgeType.DEFAULT &&
          color === BadgeColorVariant.SECONDARY &&
          style === BadgeStyleVariant.FILL,
        [classes.successDefault]:
          type === BadgeType.DEFAULT &&
          color === BadgeColorVariant.SUCCESS &&
          style === BadgeStyleVariant.DEFAULT,
        [classes.successFillSubtle]:
          type === BadgeType.DEFAULT &&
          color === BadgeColorVariant.SUCCESS &&
          style === BadgeStyleVariant.FILLSUBTLE,
        [classes.successFill]:
          type === BadgeType.DEFAULT &&
          color === BadgeColorVariant.SUCCESS &&
          style === BadgeStyleVariant.FILL,
        [classes.infoDefault]:
          type === BadgeType.DEFAULT &&
          color === BadgeColorVariant.INFO &&
          style === BadgeStyleVariant.DEFAULT,
        [classes.infoFillSubtle]:
          type === BadgeType.DEFAULT &&
          color === BadgeColorVariant.INFO &&
          style === BadgeStyleVariant.FILLSUBTLE,
        [classes.infoFill]:
          type === BadgeType.DEFAULT && color === BadgeColorVariant.INFO && style === BadgeStyleVariant.FILL,
        [classes.warningDefault]:
          type === BadgeType.DEFAULT &&
          color === BadgeColorVariant.WARNING &&
          style === BadgeStyleVariant.DEFAULT,
        [classes.warningFillSubtle]:
          type === BadgeType.DEFAULT &&
          color === BadgeColorVariant.WARNING &&
          style === BadgeStyleVariant.FILLSUBTLE,
        [classes.warningFill]:
          type === BadgeType.DEFAULT &&
          color === BadgeColorVariant.WARNING &&
          style === BadgeStyleVariant.FILL,
        [classes.dangerDefault]:
          type === BadgeType.DEFAULT &&
          color === BadgeColorVariant.DANGER &&
          style === BadgeStyleVariant.DEFAULT,
        [classes.dangerFillSubtle]:
          type === BadgeType.DEFAULT &&
          color === BadgeColorVariant.DANGER &&
          style === BadgeStyleVariant.FILLSUBTLE,
        [classes.dangerFill]:
          type === BadgeType.DEFAULT &&
          color === BadgeColorVariant.DANGER &&
          style === BadgeStyleVariant.FILL,
        [classes.appointmentScheduled]:
          (type === BadgeType.APPOINTMENT && !selected && status === BadgeAppointmentStatus.SCHEDULED) ||
          (type === BadgeType.PROGRESS &&
            !progressSelected &&
            progressStatus === BadgeProgressStatus.INPROGRESS),
        [classes.appointmentScheduledSelected]:
          (type === BadgeType.APPOINTMENT && selected && status === BadgeAppointmentStatus.SCHEDULED) ||
          (type === BadgeType.PROGRESS &&
            progressSelected &&
            progressStatus === BadgeProgressStatus.INPROGRESS),
        [classes.appointmentToday]:
          (type === BadgeType.APPOINTMENT && !selected && status === BadgeAppointmentStatus.TODAY) ||
          (type === BadgeType.PROGRESS &&
            !progressSelected &&
            progressStatus === BadgeProgressStatus.NOTSTARTED),
        [classes.appointmentTodaySelected]:
          (type === BadgeType.APPOINTMENT && selected && status === BadgeAppointmentStatus.TODAY) ||
          (type === BadgeType.PROGRESS &&
            progressSelected &&
            progressStatus === BadgeProgressStatus.NOTSTARTED),
        [classes.appointmentRequested]:
          (type === BadgeType.APPOINTMENT && !selected && status === BadgeAppointmentStatus.REQUESTED) ||
          (type === BadgeType.INVOICE && !invoiceSelected && invoiceStatus === BadgeInvoiceStatus.PENDING),
        [classes.appointmentRequestedSelected]:
          (type === BadgeType.APPOINTMENT && selected && status === BadgeAppointmentStatus.REQUESTED) ||
          (type === BadgeType.INVOICE && invoiceSelected && invoiceStatus === BadgeInvoiceStatus.PENDING),
        [classes.appointmentCompleted]:
          (type === BadgeType.APPOINTMENT && !selected && status === BadgeAppointmentStatus.COMPLETED) ||
          (type === BadgeType.PROGRESS &&
            !progressSelected &&
            progressStatus === BadgeProgressStatus.COMPLETED) ||
          (type === BadgeType.INVOICE && !invoiceSelected && invoiceStatus === BadgeInvoiceStatus.PAID),
        [classes.appointmentCompletedSelected]:
          (type === BadgeType.APPOINTMENT && selected && status === BadgeAppointmentStatus.COMPLETED) ||
          (type === BadgeType.PROGRESS &&
            progressSelected &&
            progressStatus === BadgeProgressStatus.COMPLETED) ||
          (type === BadgeType.INVOICE && invoiceSelected && invoiceStatus === BadgeInvoiceStatus.PAID),
        [classes.appointmentCanceled]:
          (type === BadgeType.APPOINTMENT && !selected && status === BadgeAppointmentStatus.CANCELED) ||
          (type === BadgeType.PROGRESS &&
            !progressSelected &&
            progressStatus === BadgeProgressStatus.ARCHIVED),
        [classes.appointmentCanceledSelected]:
          (type === BadgeType.APPOINTMENT && selected && status === BadgeAppointmentStatus.CANCELED) ||
          (type === BadgeType.PROGRESS &&
            progressSelected &&
            progressStatus === BadgeProgressStatus.ARCHIVED),
        [classes.appointmentNoshow]:
          (type === BadgeType.APPOINTMENT && !selected && status === BadgeAppointmentStatus.NOSHOW) ||
          (type === BadgeType.INVOICE && !invoiceSelected && invoiceStatus === BadgeInvoiceStatus.ONHOLD) ||
          (type === BadgeType.INVOICE &&
            !invoiceSelected &&
            invoiceStatus === BadgeInvoiceStatus.PAYMENT_FAILED),
        [classes.appointmentNoshowSelected]:
          (type === BadgeType.APPOINTMENT && selected && status === BadgeAppointmentStatus.NOSHOW) ||
          (type === BadgeType.INVOICE && invoiceSelected && invoiceStatus === BadgeInvoiceStatus.ONHOLD),
        [classes.availableStatus]: type === BadgeType.AVAILABILITY,
        [classes.active]: type === BadgeType.ACTIVE_INACTIVE && isActive && !selected,
        [classes.activeSelected]: type === BadgeType.ACTIVE_INACTIVE && isActive && selected,
        [classes.inactive]: type === BadgeType.ACTIVE_INACTIVE && isActive === false && !selected,
        [classes.inactiveSelected]: type === BadgeType.ACTIVE_INACTIVE && isActive === false && selected,
        [className]: className,
      })}
    >
      {render()}
    </Box>
  );
};

export { Badge };
