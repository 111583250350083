import { call, put, takeLatest } from 'redux-saga/effects';
import { IAction } from 'redux/store/types';
import * as ProviderService from 'services/provider/provider.service';
import {
  FETCH_PROVIDER,
  FETCH_PROVIDERS,
  FETCH_PROVIDERS_FAILED,
  FETCH_PROVIDERS_SUCCESSFUL,
  FETCH_PROVIDER_FAILED,
  FETCH_PROVIDER_SUCCESSFUL,
  FETCH_PUBLIC_PROVIDERS,
} from './actions';

function* fetchProviders(action: IAction) {
  const { queryParams } = action.payload || {};
  try {
    const response = yield call(ProviderService.getAllProviders, queryParams);
    if (response.status === 200) {
      const providers = response?.data?.results;
      yield put({
        type: FETCH_PROVIDERS_SUCCESSFUL,
        payload: {
          providers,
          currentPage: action.payload?.params?.pageNumber,
          totalPages: Math.ceil(response?.data?.total / response?.data?.size),
          totalRecords: response?.data?.total,
        },
      });
    } else {
      yield put({
        type: FETCH_PROVIDERS_FAILED,
        payload: {
          message: response.errors[0].endUserMessage,
        },
      });
    }
  } catch (e) {
    yield put({
      type: FETCH_PROVIDERS_FAILED,
      payload: { message: e },
    });
  }
}

function* fetchPublicProviders(action: IAction) {
  const { queryParams } = action.payload || {};
  try {
    const response = yield call(ProviderService.getPublicProviders, queryParams);
    if (response.status === 200) {
      const providers = response?.data?.results;
      yield put({
        type: FETCH_PROVIDERS_SUCCESSFUL,
        payload: {
          providers,
          currentPage: action.payload?.params?.pageNumber,
          totalPages: Math.ceil(response?.data?.total / response?.data?.size),
          totalRecords: response?.data?.total,
        },
      });
    } else {
      yield put({
        type: FETCH_PROVIDERS_FAILED,
        payload: {
          message: response.errors[0].endUserMessage,
        },
      });
    }
  } catch (e) {
    yield put({
      type: FETCH_PROVIDERS_FAILED,
      payload: { message: e },
    });
  }
}

function* fetchProvider(action: IAction) {
  try {
    const providerId = action.payload;
    const response = yield call(ProviderService.getProviderById, { providerId });
    if (response.status === 200) {
      const provider = response?.data;
      yield put({
        type: FETCH_PROVIDER_SUCCESSFUL,
        payload: {
          provider,
        },
      });
    } else {
      yield put({
        type: FETCH_PROVIDER_FAILED,
        payload: {
          message: response.errors[0].endUserMessage,
        },
      });
    }
  } catch (e) {
    yield put({
      type: FETCH_PROVIDER_FAILED,
      payload: { message: e },
    });
  }
}

export default function* providerSaga(): IterableIterator<any> {
  yield takeLatest(FETCH_PROVIDERS, fetchProviders);
  yield takeLatest(FETCH_PUBLIC_PROVIDERS, fetchPublicProviders);
  yield takeLatest(FETCH_PROVIDER, fetchProvider);
}
