import { call, put, takeLatest } from 'redux-saga/effects';
import { IAction } from 'redux/store/types';
import * as ConnectionService from 'services/connection/connection.service';

import {
  FETCH_CONNECTIONS,
  FETCH_CONNECTIONS_FAILED,
  FETCH_CONNECTIONS_SUCCESSFUL,
  FETCH_CARE_TEAM_MEMBERS,
  FETCH_CARE_TEAM_MEMBERS_FAILED,
  FETCH_CARE_TEAM_MEMBERS_SUCCESSFUL,
} from './actions';

function* fetchConnections(action: IAction) {
  const { userId } = action.payload;

  try {
    const { data } = yield call(ConnectionService.getConnections, userId);
    yield put({ type: FETCH_CONNECTIONS_SUCCESSFUL, payload: data });
  } catch (e: any) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({ type: FETCH_CONNECTIONS_FAILED, payload: { message } });
  }
}

function* fetchCareTeamMembers(action: IAction) {
  try {
    const { userId, scheduleAppointment } = action.payload;
    const { data } = yield call(ConnectionService.getCareTeamMember, { userId }, { scheduleAppointment });
    yield put({ type: FETCH_CARE_TEAM_MEMBERS_SUCCESSFUL, payload: data });
  } catch (e: any) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({ type: FETCH_CARE_TEAM_MEMBERS_FAILED, payload: { message } });
  }
}

function* connectionSaga(): IterableIterator<any> {
  yield takeLatest(FETCH_CONNECTIONS, fetchConnections);
  yield takeLatest(FETCH_CARE_TEAM_MEMBERS, fetchCareTeamMembers);
}

export default connectionSaga;
