import { lazyWithPreload } from 'react-lazy-with-preload';

const LazyGuestGuard = lazyWithPreload(() => import('components/auth-guard/GuestGuard'));
const LazyAuthGuard = lazyWithPreload(() => import('components/auth-guard'));
const LazyLandingPage = lazyWithPreload(() => import('pages/landing-page'));
const LazyLogin = lazyWithPreload(() => import('pages/login'));
const LazyOverView = lazyWithPreload(() => import('pages/overview'));
const LazyAppointments = lazyWithPreload(() => import('pages/appointments'));
const LazyCareTeam = lazyWithPreload(() => import('pages/care-team'));
const LazyChatbots = lazyWithPreload(() => import('pages/chatbots'));
const LazyChatbotInstance = lazyWithPreload(() => import('pages/chatbots/chatbot-instance'));
const LazyChats = lazyWithPreload(() => import('pages/chats'));
const LazyVideoChats = lazyWithPreload(() => import('pages/chats/video-chats'));
const LazyProviders = lazyWithPreload(() => import('pages/providers'));
const LazyProvider = lazyWithPreload(() => import('pages/provider'));
const LazyBookAppointment = lazyWithPreload(() => import('pages/book-appointment'));
const LazySession = lazyWithPreload(() => import('pages/session'));
const LazyFeedBack = lazyWithPreload(() => import('pages/session/components/feedback'));
const LazyInvoices = lazyWithPreload(() => import('pages/invoices'));
const LazyProfile = lazyWithPreload(() => import('pages/profile'));
const LazyIdentity = lazyWithPreload(() => import('pages/id-card'));
const LazyInsuranceForm = lazyWithPreload(() => import('pages/complete-insurance-form'));
const LazyPublicProviders = lazyWithPreload(() => import('pages/public/providers'));
const LazyPublicProvider = lazyWithPreload(() => import('pages/public/provider'));
const LazyPublicBookAppointment = lazyWithPreload(() => import('pages/public/book-appointment'));
const ScheduleAppointment = lazyWithPreload(
  () => import('pages/appointments/new-provider-appointment/ScheduleAppointment')
);
const LazyPublicBookAppointmentSuccess = lazyWithPreload(
  () => import('pages/public/book-appointment/BookAppointmentSuccess')
);

// Preload the components when necessary
export function preloadPublicRoutes() {
  LazyLandingPage.preload();
  LazyLogin.preload();
  LazyPublicProviders.preload();
  LazyPublicProvider.preload();
  LazyPublicBookAppointment.preload();
  LazyPublicBookAppointmentSuccess.preload();
  LazyProfile.preload();
  LazyChatbotInstance.preload();
}

export function preloadDashboardRoutes() {
  LazyOverView.preload();
  LazyAppointments.preload();
  LazyCareTeam.preload();
  LazyChatbots.preload();
  LazyChatbotInstance.preload();
  LazyChats.preload();
  LazyProviders.preload();
  LazyProvider.preload();
  LazySession.preload();
  LazyFeedBack.preload();
  LazyInvoices.preload();
  LazyProfile.preload();
  LazyBookAppointment.preload();
  LazyVideoChats.preload();
}

export const routes = [
  {
    path: '/',
    name: 'Landing Page',
    guard: LazyGuestGuard,
    component: LazyLandingPage,
  },
  {
    path: '/callback/accessToken',
    name: 'Magic Link',
    guard: LazyAuthGuard,
    component: () => null,
  },
  {
    path: '/login',
    name: 'Login',
    guard: LazyGuestGuard,
    component: LazyLogin,
  },
  {
    path: '/overview',
    name: 'Overview',
    guard: LazyAuthGuard,
    component: LazyOverView,
  },
  {
    path: '/appointments',
    name: 'Appointments',
    guard: LazyAuthGuard,
    component: LazyAppointments,
  },
  {
    path: '/care-team',
    name: 'Care team',
    guard: LazyAuthGuard,
    component: LazyCareTeam,
  },
  {
    path: '/chatbots',
    name: 'Chatbots',
    guard: LazyAuthGuard,
    component: LazyChatbots,
  },
  {
    path: '/chatbots/:conversationId/:memberId',
    name: 'Chatbots Instance',
    component: LazyChatbotInstance,
  },
  {
    path: '/chats',
    name: 'Chats',
    guard: LazyAuthGuard,
    component: LazyChats,
  },
  {
    path: '/chats/video/:channelUrl',
    name: 'Video Chat',
    guard: LazyAuthGuard,
    component: LazyVideoChats,
  },
  {
    path: '/providers',
    name: 'Providers',
    guard: LazyAuthGuard,
    component: LazyProviders,
  },
  {
    path: '/providers/:providerId',
    name: 'Provider',
    guard: LazyAuthGuard,
    component: LazyProvider,
  },
  {
    path: '/providers/:providerId/book-appointment',
    name: 'Book a session',
    guard: LazyAuthGuard,
    component: LazyBookAppointment,
  },
  {
    path: '/session/:memberId/:appointmentId',
    name: 'Session',
    component: LazySession,
  },
  {
    path: '/session/:memberId/:appointmentId/feedback',
    name: 'Session Feedback',
    guard: LazyAuthGuard,
    component: LazyFeedBack,
  },
  {
    path: '/invoices',
    name: 'Invoices',
    guard: LazyAuthGuard,
    component: LazyInvoices,
  },
  {
    path: '/profile/:memberId',
    name: 'Profile',
    component: LazyProfile,
  },
  {
    path: '/profile/insurance/:memberId',
    name: 'Profile',
    component: LazyInsuranceForm,
  },
  {
    path: '/profile/identity/:memberId',
    name: 'Id Card',
    component: LazyIdentity,
  },
  // public view
  {
    path: '/public/providers',
    name: 'Providers matching your search',
    guard: LazyGuestGuard,
    component: LazyPublicProviders,
  },
  {
    path: '/public/providers/:providerId',
    name: 'Provider',
    guard: LazyGuestGuard,
    component: LazyPublicProvider,
  },
  {
    path: '/public/archive/book-appointment/',
    name: 'Book a session',
    guard: LazyGuestGuard,
    component: LazyPublicBookAppointment,
  },
  {
    path: '/public/book-appointment/success',
    name: 'Appointment booked success',
    component: LazyPublicBookAppointmentSuccess,
  },
  {
    path: '/public/book-appointment',
    name: 'schedule new provider appointment',
    component: ScheduleAppointment,
  },
];

export const excludeRoutesToAuth = routes.filter(x => x.guard === LazyGuestGuard).map(x => x.path);
