import { AxiosResponse } from 'axios';
import { apiEndpoints } from 'constants/apiEndpoints';
import { baseRequest } from 'utils/httpClient';

export const getStates = (): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.GET_STATES);
};

export const getActiveStates = (): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.GET_STATES, undefined, undefined, { onlyActive: true });
};

export const getPayersByStateId = (stateId: string): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.GET_PAYERS_BY_STATE, undefined, { stateId });
};

export const getPayersByStateName = (stateName: string): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.GET_PAYERS_BY_STATENAME, undefined, { stateName });
};
