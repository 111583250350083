import { createAction } from 'redux-actions';

export const FETCH_PROVIDERS = 'providers/fetch';
export const FETCH_PROVIDERS_SUCCESSFUL = 'providers/fetchSuccessful';
export const FETCH_PROVIDERS_FAILED = 'providers/failed';

export const FETCH_PUBLIC_PROVIDERS = 'providers/public/fetch';

export const FETCH_PROVIDER = 'provider/fetch';
export const FETCH_PROVIDER_SUCCESSFUL = 'provider/fetchSuccessful';
export const FETCH_PROVIDER_FAILED = 'provider/fetchFailed';

export const providerActionCreators = {
  fetchProviders: createAction(FETCH_PROVIDERS),
  fetchPublicProviders: createAction(FETCH_PUBLIC_PROVIDERS),
  fetchProvider: createAction(FETCH_PROVIDER),
};
