import { FC } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// types
import { IconPrefix } from '@fortawesome/fontawesome-common-types';
import { IIconsProps } from './Icons.types';
import { IGlyphs, SvgIcons } from '../../../../assets/svg';

const iconStyles = {
  'fa-solid': 'fas',
  'fa-regular': 'far',
  'fa-thin': 'fat',
  'fa-duotone': 'fad',
  'fa-brands': 'fab',
};

const Icons: FC<IIconsProps> = ({
  icon,
  color = '',
  size = '1x',
  className = '',
  onClick,
  type = 'fa',
  ...rest
}) => {
  if (!icon) {
    return null;
  }
  const iconPath = icon.split(' ');

  if (type === 'svg' || iconPath.length < 2) {
    return (
      <SvgIcons
        glyph={icon as IGlyphs['glyph']}
        color={color}
        className={className}
        onClick={onClick}
        {...rest}
      />
    );
  }

  const iconPrefix = iconStyles[iconPath[0]] as IconPrefix;
  const iconName = iconPath[1].substring(3) as any;

  return (
    <FontAwesomeIcon
      icon={[iconPrefix, iconName]}
      color={color}
      size={size}
      className={className}
      onClick={onClick}
      {...rest}
    />
  );
};

export { Icons };
