import { FC } from 'react';

interface IGlyphIcon {
  className?: string;
  color?: string;
  onClick?: () => void;
}

const Hippa: FC<IGlyphIcon> = ({ className = '', color, onClick }) => {
  return (
    <svg
      width="97"
      height="56"
      viewBox="0 0 97 56"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
    >
      <path
        d="M77.0906 0.275272C71.9977 1.20297 67.0184 5.08368 65.0306 9.70339L64.671 10.5748L34.5125 10.6123L4.35395 10.6686L3.38871 11.1794C1.95024 11.9372 1.36339 12.5043 0.643163 13.8301L0 15.0226V33.292V51.5613L0.681693 52.7923C1.11738 53.6064 1.6657 54.2693 2.27231 54.7238C4.1277 56.1247 0.492992 56.0299 45.7209 55.9736L86.3301 55.9173L87.2953 55.4065C88.7338 54.6487 89.3206 54.0816 90.0408 52.7558L90.685 51.5633L90.7413 40.5831L90.7976 29.5841L92.0849 28.2405C98.4652 21.6143 98.3891 11.3533 91.914 4.89794C88.0145 0.975735 82.562 -0.709728 77.0906 0.275272ZM84.2464 3.64521C86.3854 4.3269 87.9948 5.32968 89.7365 7.07146C91.4596 8.79446 92.4633 10.3851 93.1826 12.5616C93.6183 13.9062 93.6746 14.3794 93.6746 16.632C93.6746 18.8845 93.6173 19.3577 93.1826 20.7024C92.4633 22.8611 91.4783 24.4507 89.7365 26.1925C88.0135 27.9155 86.4239 28.9182 84.2464 29.6385C82.9018 30.0742 82.4296 30.1305 80.176 30.1305C77.9225 30.1305 77.4493 30.0742 76.1056 29.6385C73.9292 28.9192 72.3385 27.9155 70.6155 26.1925C68.1358 23.7127 66.8484 20.9484 66.6015 17.5972C66.1282 10.7437 71.0315 4.42076 77.7901 3.18976C79.4756 2.88744 82.5432 3.0959 84.2464 3.64521ZM63.5921 15.6292C63.3076 20.5137 65.0118 24.9437 68.4381 28.3699C72.3188 32.2694 77.6577 33.9549 83.1103 33.0084C84.038 32.8385 85.4202 32.4591 86.1769 32.1568C86.9535 31.8347 87.6342 31.5887 87.6727 31.5887C87.7112 31.5887 87.73 35.9614 87.7103 41.3191C87.6539 50.8233 87.6342 51.0693 87.2558 51.5801C87.0473 51.8637 86.6314 52.2806 86.3469 52.489L85.8361 52.8872H45.3415H4.84694L4.33616 52.489C4.05262 52.2806 3.6357 51.8646 3.42724 51.5801C3.03008 51.0693 3.03008 50.8984 2.97278 33.8037C2.95401 24.2995 2.97278 16.2723 3.02909 15.9315C3.16148 15.1737 3.95679 14.2085 4.73332 13.8676C5.18779 13.6592 10.6591 13.6216 34.4947 13.6029H63.7067L63.5921 15.6292Z"
        fill="#0056B7"
      />
      <path
        d="M82.6369 14.2654L78.3966 18.5057L76.0867 16.2532C74.7994 14.9846 73.6257 13.9809 73.4173 13.9809C73.2276 13.9809 72.6032 14.4353 72.0539 15.0034C71.2398 15.8362 71.0699 16.1208 71.1449 16.4804C71.2961 17.086 77.544 23.2578 78.0172 23.2578C78.2444 23.2578 80.4407 21.194 83.8482 17.7865C89.9626 11.671 89.7552 11.9931 88.3157 10.687C87.8988 10.3086 87.4068 10.0053 87.2181 10.0053C87.0482 10.0053 84.9853 11.918 82.6369 14.2654Z"
        fill="#0056B7"
      />
      <path
        d="M10.0714 19.3967C9.99528 19.4728 9.93896 22.3685 9.93896 25.8521V32.1562H11.17H12.401V29.3168V26.4765H14.9568H17.5127V29.3168V32.1562H18.6488H19.785V25.7197V19.2831H18.6488H17.5127V21.9338V24.5835H14.9568H12.4197L12.3624 21.9714L12.3061 19.378L11.2648 19.3216C10.678 19.2831 10.1474 19.3207 10.0714 19.3967Z"
        fill="#0056B7"
      />
      <path
        d="M23.1353 19.4155C23.0592 19.4718 23.0029 22.3685 23.0029 25.8521V32.1562H24.1391H25.2752V25.7197V19.2831H24.2715C23.7034 19.2831 23.1916 19.3394 23.1353 19.4155Z"
        fill="#0056B7"
      />
      <path
        d="M28.6816 25.7197V32.1562H29.8178H30.954V29.8089V27.4615L32.999 27.4042C34.892 27.3281 35.138 27.2718 35.9906 26.761C37.2779 25.9469 37.7699 25.038 37.7699 23.3911C37.7699 20.2671 36.199 19.3019 31.1061 19.2831H28.6826V25.7197H28.6816ZM34.7971 21.6117C35.4976 22.0662 35.7811 23.1451 35.46 24.0915C35.0817 25.2277 34.4948 25.5488 32.5821 25.6634L30.954 25.777V23.4484V21.1385L32.6384 21.2146C33.7746 21.2709 34.475 21.3845 34.7971 21.6117Z"
        fill="#0056B7"
      />
      <path
        d="M40.3435 25.4164C39.2262 28.8051 38.2797 31.7018 38.2234 31.8727C38.1098 32.1375 38.2609 32.176 39.3022 32.1187L40.5135 32.0614L40.9679 30.3957L41.4412 28.73L43.6186 28.7873L45.8149 28.8436L46.2881 30.4905L46.7613 32.1562H47.9538C48.598 32.1562 49.1275 32.1375 49.1275 32.0999C49.1275 32.0624 48.1998 29.1845 47.0637 25.7009L45.0186 19.378L43.675 19.3216L42.3501 19.2643L40.3435 25.4164ZM44.4327 23.9779C44.8299 25.3976 45.152 26.6286 45.152 26.7037C45.152 26.7985 44.4515 26.8548 43.6186 26.8548C42.1229 26.8548 42.0853 26.8361 42.1802 26.4192C42.5022 24.9422 43.5623 21.2126 43.6186 21.2887C43.6759 21.347 44.0356 22.5394 44.4327 23.9779Z"
        fill="#0056B7"
      />
      <path
        d="M54.1639 19.699C54.1076 19.945 53.1602 22.8417 52.0813 26.1356L50.1123 32.1562H51.286H52.4785L52.9329 30.4905L53.3874 28.8436L55.5836 28.7873L57.7611 28.73L58.2343 30.3957L58.6888 32.0614L59.8813 32.1187C60.5254 32.1562 61.055 32.0999 61.055 32.0239C61.055 31.9478 60.1273 29.0521 59.0109 25.6248L56.9658 19.377L55.6212 19.3207C54.3724 19.2643 54.2776 19.3019 54.1639 19.699ZM57.1357 26.5525C57.2493 26.8173 57.0409 26.8558 55.6399 26.8558C54.1817 26.8558 54.0118 26.8183 54.1066 26.515C54.1442 26.3441 54.4663 25.1704 54.7883 23.9216C55.1292 22.6718 55.4512 21.5169 55.5076 21.3657C55.5451 21.2146 55.9245 22.2559 56.3216 23.6756C56.701 25.0953 57.0794 26.4014 57.1357 26.5525Z"
        fill="#0056B7"
      />
      <path
        d="M11.738 37.8167C10.0535 38.5932 9.18213 40.1453 9.18213 42.3791C9.18213 44.8213 10.2422 46.487 12.1737 47.0363C13.3859 47.396 14.2375 47.2823 15.3925 46.6194C16.3014 46.0889 16.3577 45.9002 15.7896 45.2946L15.3925 44.8786L14.7108 45.3519C13.4234 46.2223 11.7953 45.6552 11.1511 44.1406C10.4694 42.5698 11.0751 39.843 12.23 39.2561C12.9492 38.8778 14.0093 38.8965 14.691 39.2937C15.2215 39.6158 15.2591 39.6158 15.6375 39.2176C16.167 38.6871 16.0159 38.3472 15.0131 37.8542C13.9905 37.3622 12.7408 37.3434 11.738 37.8167Z"
        fill="#0056B7"
      />
      <path
        d="M19.6327 37.6656C17.5501 38.2712 16.3951 41.4139 17.2467 44.2543C18.3256 47.889 23.0589 48.2871 24.7434 44.8984C25.103 44.1407 25.1791 43.6872 25.1791 42.2863C25.1791 40.7342 25.1228 40.4882 24.5922 39.5407C23.6082 37.7979 21.6768 37.0787 19.6327 37.6656ZM22.2261 39.2552C23.3622 39.8233 23.873 42.228 23.2486 43.9885C22.3782 46.4308 19.5566 46.2984 18.8561 43.7613C18.4017 42.1707 18.7238 40.3726 19.5941 39.5012C20.2195 38.8956 21.3172 38.782 22.2261 39.2552Z"
        fill="#0056B7"
      />
      <path
        d="M27.167 42.379V47.1123H27.9435H28.7201L28.6628 43.5527L28.5867 40.0128L29.7228 43.0419C30.7454 45.7489 30.9153 46.071 31.3135 46.071C31.6919 46.071 31.8628 45.7677 32.7717 43.3254C33.3398 41.8109 33.8693 40.4287 33.9454 40.24C34.0215 40.0504 34.059 41.5274 34.0215 43.5151L33.9454 47.1123H34.7219H35.4985V42.379V37.6457H34.5708H33.6431L32.5435 40.7696C31.9191 42.4926 31.3885 43.8935 31.3322 43.8935C31.2759 43.8935 30.7454 42.4926 30.121 40.7696L29.0234 37.6457H28.0957H27.168V42.379H27.167Z"
        fill="#0056B7"
      />
      <path
        d="M37.9585 42.3797V47.1318L38.7726 47.0755L39.5679 47.0182L39.6242 45.2577L39.6815 43.5159H41.0449C42.6543 43.5159 43.5247 43.1563 44.15 42.2098C44.6806 41.4145 44.7369 39.7676 44.2824 38.9723C43.6758 37.9685 43.0129 37.7413 40.382 37.6849L37.9585 37.6089V42.3797ZM42.3124 39.2183C43.2964 39.7103 43.2214 41.5093 42.1988 41.9826C41.9341 42.0962 41.2711 42.191 40.7031 42.191H39.6617V40.5817V38.9723H40.7594C41.3472 38.9723 42.0477 39.0859 42.3124 39.2183Z"
        fill="#0056B7"
      />
      <path
        d="M46.4775 42.379V47.1123H49.2231H51.9686V46.3545V45.5968H50.0757H48.1828V41.6212V37.6457H47.3311H46.4795V42.379H46.4775Z"
        fill="#0056B7"
      />
      <path
        d="M53.6719 42.379V47.1123H54.5235H55.3751V42.379V37.6457H54.5235H53.6719V42.379Z"
        fill="#0056B7"
      />
      <path
        d="M58.3106 41.5649C57.6101 43.7424 56.9096 45.8625 56.7773 46.2982L56.5312 47.1123H57.3641H58.197L58.5941 45.7874L58.9913 44.4626H60.5631H62.134L62.4936 45.7311L62.872 47.0184L63.7809 47.0758C64.5574 47.1321 64.6898 47.0945 64.595 46.8297C64.5189 46.6786 63.8185 44.5387 63.0231 42.0964L61.5847 37.6476H60.6007H59.6167L58.3106 41.5649ZM61.1124 43.0982C60.7528 43.1358 60.242 43.1358 59.9387 43.0982L59.4082 43.0409L59.995 40.9958L60.5631 38.932L61.15 40.9958L61.7368 43.0409L61.1124 43.0982Z"
        fill="#0056B7"
      />
      <path
        d="M65.7886 42.3985V47.1318L66.6027 47.0755L67.398 47.0182L67.3604 43.5347L67.3229 40.0511L68.9323 42.9666C69.8224 44.576 70.674 46.1656 70.845 46.4877C71.1285 47.0558 71.2421 47.1121 71.9811 47.1121H72.7952V42.3788V37.6454H72.0187H71.2234L71.3182 41.2426L71.3933 44.8398L70.4468 42.9844C69.9163 41.9618 69.0647 40.3337 68.5351 39.3872L67.5867 37.6474H66.6975H65.7886V42.3985Z"
        fill="#0056B7"
      />
      <path
        d="M74.4961 38.3086V38.9715H75.821H77.1458V43.0419V47.1123H77.9974H78.8491V43.0419V38.9715H80.1739H81.4988V38.3086V37.6457H77.9994H74.4971V38.3086H74.4961Z"
        fill="#0056B7"
      />
    </svg>
  );
};

export default Hippa;
