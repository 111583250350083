import { IAction } from 'redux/store/types';
import { ICard, InsuranceProfile, PaymentState, RecurringSubScription, ServiceInfo } from './types';
import {
  ADD_PAYMENT_CARD,
  ADD_PAYMENT_CARD_FAILED,
  ADD_PAYMENT_CARD_SUCCESSFUL,
  DELETE_CARD,
  DELETE_CARD_FAILED,
  DELETE_CARD_SUCCESSFUL,
  FETCH_CARDS,
  FETCH_CARDS_FAILED,
  FETCH_CARDS_SUCCESSFUL,
  FETCH_BILLING_INSURANCE,
  FETCH_BILLING_INSURANCE_FAILED,
  FETCH_BILLING_INSURANCE_SUCCESSFUL,
  FETCH_SERVICE_INFO,
  FETCH_SERVICE_INFO_FAILED,
  FETCH_SERVICE_INFO_SUCCESSFUL,
} from './actions';
import { USER_LOGOUT } from '../auth/actions';

export const DEFAULT = {
  isLoading: false,
  isRequesting: false,
  errorMsg: '',
  cardsList: [] as ICard[],
  billingProfile: {
    insuranceProfile: {} as InsuranceProfile,
    recurringSubscription: {} as RecurringSubScription,
  },
  serviceInfo: {} as ServiceInfo,
};

export default function paymentReducer(state = DEFAULT, action: IAction): PaymentState {
  const { type, payload } = action;
  switch (type) {
    case FETCH_CARDS:
      return {
        ...state,
        isLoading: true,
        isRequesting: false,
        errorMsg: '',
      };
    case FETCH_CARDS_FAILED: {
      return {
        ...state,
        isLoading: false,
        cardsList: [] as ICard[],
        errorMsg: payload?.message || '',
      };
    }
    case FETCH_CARDS_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        errorMsg: '',
        cardsList: payload,
      };
    }
    case ADD_PAYMENT_CARD:
      return {
        ...state,
        isLoading: true,
        isRequesting: false,
        errorMsg: '',
      };
    case ADD_PAYMENT_CARD_FAILED: {
      return {
        ...state,
        isLoading: false,
        errorMsg: payload?.message || '',
      };
    }
    case ADD_PAYMENT_CARD_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        errorMsg: '',
        cardsList: [...state.cardsList, payload],
      };
    }
    case DELETE_CARD:
      return {
        ...state,
        isLoading: true,
        isRequesting: false,
        errorMsg: '',
      };
    case DELETE_CARD_FAILED: {
      return {
        ...state,
        isLoading: false,
        errorMsg: payload?.message || '',
      };
    }
    case DELETE_CARD_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        errorMsg: '',
        cardsList: state.cardsList.filter(card => card.cardId !== payload),
      };
    }
    case FETCH_BILLING_INSURANCE:
      return {
        ...state,
        isLoading: true,
        isRequesting: false,
        errorMsg: '',
      };
    case FETCH_BILLING_INSURANCE_FAILED: {
      return {
        ...state,
        isLoading: false,
        errorMsg: payload?.message || '',
      };
    }
    case FETCH_BILLING_INSURANCE_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        errorMsg: '',
        billingProfile: payload,
      };
    }
    case FETCH_SERVICE_INFO:
      return {
        ...state,
        isLoading: true,
        isRequesting: false,
        errorMsg: '',
      };
    case FETCH_SERVICE_INFO_FAILED: {
      return {
        ...state,
        isLoading: false,
        errorMsg: payload?.message || '',
      };
    }
    case FETCH_SERVICE_INFO_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        errorMsg: '',
        serviceInfo: payload,
      };
    }
    case USER_LOGOUT: {
      return {
        ...state,
        ...DEFAULT,
      };
    }
    default:
      return state;
  }
}
