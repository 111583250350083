import { fork, all } from 'redux-saga/effects';

import { authSaga } from 'redux/modules/auth';
import { appointmentSaga } from 'redux/modules/appointment';
import { providerSaga } from 'redux/modules/provider';
import { stateSaga } from 'redux/modules/state';
import { scheduleSaga } from 'redux/modules/schedule';
import { conversationSaga } from 'redux/modules/conversation';
import { profileSaga } from 'redux/modules/profile';
import { invoiceSaga } from 'redux/modules/invoice';
import { paymentSaga } from 'redux/modules/payment';
import { connectionSaga } from 'redux/modules/connection';
import { chatSaga, chatConversationSaga } from 'redux/modules/chat';
import { chatbotSaga } from 'redux/modules/chatbot';
import { publicChatbotSaga } from 'redux/modules/public-chatbot';
import { publicConnectionSaga } from 'redux/modules/public-connection';
import { publicConversationSaga } from 'redux/modules/public-conversation';

export default function* rootSaga(store): IterableIterator<any> {
  yield all([
    fork(authSaga),
    fork(appointmentSaga),
    fork(providerSaga),
    fork(stateSaga),
    fork(scheduleSaga),
    fork(conversationSaga),
    fork(profileSaga),
    fork(invoiceSaga),
    fork(paymentSaga),
    fork(connectionSaga),
    fork(chatSaga, store),
    fork(chatConversationSaga),
    fork(chatbotSaga, store),
    fork(publicChatbotSaga, store),
    fork(publicConnectionSaga),
    fork(publicConversationSaga),
  ]);
}
