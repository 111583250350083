import { createAction } from 'redux-actions';

export const FETCH_PROFILE = 'profile/fetch';
export const FETCH_PROFILE_SUCCESSFUL = 'profile/fetchSuccessful';
export const FETCH_PROFILE_FAILED = 'profile/fetchFailed';

export const FETCH_ACTIVITY = 'profile/fetchActivity';
export const FETCH_ACTIVITY_SUCCESSFUL = 'profile/fetchActivity/Successful';
export const FETCH_ACTIVITY_FAILED = 'profile/fetchActivity/Failed';

export const UPDATE_PROFILE = 'profile/update';
export const UPDATE_PROFILE_SUCCESSFUL = 'profile/updateSuccessful';
export const UPDATE_PROFILE_FAILED = 'profile/updateFailed';

export const UPDATE_PROFILE_IMAGE = 'profile/image/update';
export const UPDATE_PROFILE_IMAGE_SUCCESSFUL = 'profile/image/updateSuccessful';
export const UPDATE_PROFILE_IMAGE_FAILED = 'profile/image/updateFailed';

export const FETCH_CARE_TEAM_MEMBERS = 'careTeamMembers/fetch';
export const FETCH_CARE_TEAM_MEMBERS_SUCCESSFUL = 'careTeamMembers/fetchSuccessful';
export const FETCH_CARE_TEAM_MEMBERS_FAILED = 'careTeamMembers/fetchSuccess';

export const FETCH_INSURANCE = 'insurance/fetch';
export const FETCH_INSURANCE_SUCCESSFUL = 'insurance/fetchSuccessful';
export const FETCH_INSURANCE_FAILED = 'insurance/fetchFailed';

export const UPDATE_INSURANCE = 'insurance/update';
export const UPDATE_INSURANCE_SUCCESSFUL = 'insurance/updateSuccessful';
export const UPDATE_INSURANCE_FAILED = 'insurance/updateFailed';
export const ADD_INSURANCE = 'insurance/add';
export const ADD_INSURANCE_SUCCESSFUL = 'insurance/addSuccessful';
export const ADD_INSURANCE_FAILED = 'insurance/addFailed';

export const FETCH_PUBLIC_INSURANCE = 'public/insurance/fetch';
export const FETCH_PUBLIC_INSURANCE_SUCCESSFUL = 'public/insurance/fetchSuccessful';
export const FETCH_PUBLIC_INSURANCE_FAILED = 'public/insurance/fetchFailed';

export const UPDATE_PUBLIC_INSURANCE = 'public/insurance/update';
export const UPDATE_PUBLIC_INSURANCE_SUCCESSFUL = 'public/insurance/updateSuccessful';
export const UPDATE_PUBLIC_INSURANCE_FAILED = 'public/insurance/updateFailed';

export const UPDATE_ACTIVE_CONNECTION_LAST_MESSAGE = 'updateActiveConnectionLastMessage';

export const FETCH_CONNECTIONS = 'connections/fetch';
export const FETCH_CONNECTIONS_SUCCESSFUL = 'connections/fetchSuccessful';
export const FETCH_CONNECTIONS_FAILED = 'connections/fetchFailed';

export const FETCH_MARKED_ARTICLES = 'profile/markedArticles/fetch';
export const FETCH_MARKED_ARTICLES_SUCCESSFUL = 'profile/markedArticles/fetchSuccessful';
export const FETCH_MARKED_ARTICLES_FAILED = 'profile/markedArticles/fetchFailed';

export const profileActionCreators = {
  fetchProfile: createAction(FETCH_PROFILE),
  fetchActivity: createAction(FETCH_ACTIVITY),
  updateProfile: createAction(UPDATE_PROFILE),
  updateProfileImage: createAction(UPDATE_PROFILE_IMAGE),
  fetchInsurance: createAction(FETCH_INSURANCE),
  updateInsurance: createAction(UPDATE_INSURANCE),
  addInsurance: createAction(ADD_INSURANCE),
  fetchPublicInsurance: createAction(FETCH_PUBLIC_INSURANCE),
  updatePublicInsurance: createAction(UPDATE_PUBLIC_INSURANCE),
  fetchConnections: createAction(FETCH_CONNECTIONS),
  fetchMarkedArticles: createAction(FETCH_MARKED_ARTICLES),
};
