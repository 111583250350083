import { AxiosResponse } from 'axios';
import { apiEndpoints } from 'constants/apiEndpoints';
import { baseRequest } from 'utils/httpClient';
import { IInsuranceRequest } from 'redux/modules/profile/types';

export const getProfile = (userId?: string): Promise<AxiosResponse> => {
  const queryParams = userId ? { userId } : undefined;
  return baseRequest(apiEndpoints.GET_PROFILE, {}, undefined, queryParams, true);
};

export const getActivity = (
  pathParams: { userId: string },
  queryParams: { pageNumber: number; pageSize: number }
): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.USER_ACTIVITY, undefined, pathParams, queryParams, true);
};

export const updateProfile = (bodyRequest): Promise<AxiosResponse> => {
  return baseRequest(
    apiEndpoints.UPDATE_PROFILE,
    bodyRequest,
    undefined,
    undefined,
    true,
    true,
    true,
    'profile'
  );
};

export const getPublicProvider = (providerId): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.GET_PROVIDER_PUBLIC, undefined, { providerId });
};

export const updateProfileImage = (bodyRequest): Promise<AxiosResponse> => {
  return baseRequest(
    apiEndpoints.UPLOAD_PROFILE_IMAGE,
    bodyRequest,
    undefined,
    undefined,
    true,
    true,
    true,
    'file'
  );
};

export const updateMemberIdCard = (bodyRequest): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.UPDATE_ID_CARD, bodyRequest);
};

// Retrieves a list of care team members for the provided member specified by userId
export const getCareTeamMembers = (userId: string): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.GET_CARE_TEAM_MEMBERS, undefined, { userId });
};

export const createInsuranceInfo = (bodyRequest: Partial<IInsuranceRequest>): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.CREATE_INSURANCE_PROFILE, bodyRequest);
};

// Retrieves user basic insurance profile info
export const getInsuranceInfo = (pathParams: { userId: string }): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.GET_INSURANCE_INFO, undefined, pathParams);
};

// Update user basic insurance profile info
export const updateInsuranceInfo = (
  bodyRequest: Partial<IInsuranceRequest>,
  userId: { userId: string }
): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.UPDATE_INSURANCE_INFO, bodyRequest, userId);
};

export const updatePublicInsuranceInfo = (
  bodyRequest,
  userId: { userId: string }
): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.UPDATE_PUBLIC_INSURANCE_INFO, bodyRequest, userId);
};

// Get active, past, request connections on chat
export const getConnections = (queryParams: { userId: string }): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.GET_CONNECTIONS, undefined, undefined, queryParams, true);
};

export const archiveConnection = (bodyRequest: { connectionId: string }): Promise<AxiosResponse> => {
  // TODO:: API requires both body and path params. Needs to be improved
  return baseRequest(apiEndpoints.ARCHIVE_CONNECTION, bodyRequest, bodyRequest);
};

export const educationViewed = (entryId: string): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.EDUCATION_VIEWED, undefined, { entryId });
};

export const bookMarkEducationContent = (slug: string, mark: boolean): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.BOOKMARK_EDUCATIONAL_CONTENT, undefined, { slug }, { mark });
};

export const getMarkedEducationalContent = (
  markType: 'bookmarked' | 'completedSlug'
): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.GET_MARKED_EDUCATIONAL_CONTENT, undefined, undefined, { type: markType });
};

export const markContentAsCompleted = (slug: string, topicEntryId?: string): Promise<AxiosResponse> => {
  return baseRequest(
    apiEndpoints.MARK_CONTENT_AS_COMPLETED,
    undefined,
    { slug },
    { topic: topicEntryId || '' }
  );
};
