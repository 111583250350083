import { AxiosResponse } from 'axios';
import { apiEndpoints } from 'constants/apiEndpoints';
import { CardPaymentPayload, WalletPaymentPayload } from 'redux/modules/payment/types';
import { baseRequest } from 'utils/httpClient';

// Retrives all cards for current user
export const getCards = (): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.GET_CARDS);
};

// Delete a payment card by cardId
export const deleteCard = (cardId: string): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.DELETE_CARD, undefined, { cardId });
};

export const addCard = (cardTokenId: string): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.ADD_PAYMENT_CARD, { cardTokenId });
};

// Charge using card
export const payForAppointment = (
  appointmentId: string,
  payload: CardPaymentPayload
): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.PAY_FOR_APPOINTMENT_BY_CARD, payload, { appointmentId });
};

// Charge using wallet
export const payForAppointmentViaWallet = (payload: WalletPaymentPayload): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.PAY_FOR_APPOINTMENT_BY_WALLET, payload);
};

// Get billing insurance profile
export const getBillingInsurance = (): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.GET_PATIENT_INSURANCE_PROFILE);
};

// Get charge amount using memberId and serviceId
export const getChargeAmountWithMemberId = (
  patientUserAccountId: string,
  serviceId: string
): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.GET_CHARGE_AMOUNT_WITH_ACCOUNTID, undefined, {
    patientUserAccountId,
    serviceId,
  });
};

export const payInvoice = (invoiceId: string, paymentToken: string): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.PAY_INVOICE, { paymentToken, invoiceId });
};
