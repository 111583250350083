import { createAction } from 'redux-actions';

export const FETCH_CONVERSATIONS_PUBLIC = 'conversations/public/fetch';
export const FETCH_CONVERSATIONS_SUCCESSFUL_PUBLIC = 'conversations/public/fetchSuccessful';
export const FETCH_CONVERSATIONS_FAILED_PUBLIC = 'conversations/public/fetchSuccess';

export const ASSIGN_CHATBOT_PUBLIC = 'chatbot/public/assign';
export const ASSIGN_CHATBOT_SUCCESSFUL_PUBLIC = 'chatbot/public/assignSuccessful';
export const ASSIGN_CHATBOT_FAILED_PUBLIC = 'chatbot/public/assignSuccess';

export const FETCH_CHATBOTS_PUBLIC = 'chatbots/public/fetch';
export const FETCH_CHATBOTS_SUCCESSFUL_PUBLIC = 'chatbots/public/fetchSuccessful';
export const FETCH_CHATBOTS_FAILED_PUBLIC = 'chatbots/public/fetchSuccess';

export const CREATE_CHATBOT_PUBLIC = 'chatbots/public/create';
export const CREATE_CHATBOT_SUCCESSFUL_PUBLIC = 'chatbots/public/createSuccessful';
export const CREATE_CHATBOT_FAILED_PUBLIC = 'chatbots/public/createFailed';

export const UPDATE_CHATBOT_PUBLIC = 'chatbots/public/update';
export const UPDATE_CHATBOT_SUCCESSFUL_PUBLIC = 'chatbots/public/updateSuccessful';
export const UPDATE_CHATBOT_FAILED_PUBLIC = 'chatbots/public/updateFailed';

export const publicConversationActionCreators = {
  fetchConversations: createAction(FETCH_CONVERSATIONS_PUBLIC),
  assignChatbot: createAction(ASSIGN_CHATBOT_PUBLIC),
  fetchChatbots: createAction(FETCH_CHATBOTS_PUBLIC),
  createChatbot: createAction(CREATE_CHATBOT_PUBLIC),
};
