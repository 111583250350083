import { createAction } from 'redux-actions';

export const FETCH_CARDS = 'billing/payments/cards/fetch';
export const FETCH_CARDS_SUCCESSFUL = 'billing/payments/cards/fetchSuccessful';
export const FETCH_CARDS_FAILED = 'billing/payments/cards/fetchFailed';

export const DELETE_CARD = 'billing/payments/cards/delete';
export const DELETE_CARD_SUCCESSFUL = 'billing/payments/cards/deleteSuccessful';
export const DELETE_CARD_FAILED = 'billing/payments/cards/deleteFailed';

export const ADD_PAYMENT_CARD = 'billing/payments/cards/add';
export const ADD_PAYMENT_CARD_SUCCESSFUL = 'billing/payments/cards/addSuccessful';
export const ADD_PAYMENT_CARD_FAILED = 'billing/payments/cards/addFailed';

export const FETCH_BILLING_INSURANCE = 'billing/billingProfile/fetch';
export const FETCH_BILLING_INSURANCE_SUCCESSFUL = 'billing/billingProfile/fetchSuccessful';
export const FETCH_BILLING_INSURANCE_FAILED = 'billing/billingProfile/fetchFailed';

export const FETCH_SERVICE_INFO = 'billing/serviceInfo/fetch';
export const FETCH_SERVICE_INFO_SUCCESSFUL = 'billing/serviceInfo/fetchSuccessful';
export const FETCH_SERVICE_INFO_FAILED = 'billing/serviceInfo/fetchFailed';

export const paymentActionCreators = {
  fetchCards: createAction(FETCH_CARDS),
  deleteCard: createAction(DELETE_CARD),
  addCard: createAction(ADD_PAYMENT_CARD),
  fetchBillingProfile: createAction(FETCH_BILLING_INSURANCE),
  fetchServiceInfo: createAction(FETCH_SERVICE_INFO),
};
