// TODO: This file is a mess. Needs to be refactored. It's a copy of the mobile code.
import { IAction } from 'redux/store/types';
import {
  CHAT_ADD_MESSAGE,
  CHAT_AUTO_REPLY_REGISTERED,
  CHAT_EXIT,
  CHAT_HIDE_SINGLE_CHOICES,
  CHAT_INIT_SUCCESSFUL,
  CHAT_LOAD_EARLIER,
  CHAT_LOADED_EARLIER,
  CHAT_PAUSE,
  CHAT_REQUEST_INITIALIZE,
  CHAT_RESUME,
  CHAT_SEND_MESSAGE,
  CHAT_SEND_MESSAGE_FAILED,
  CHAT_SEND_MESSAGE_IN_PROGRESS,
  CHAT_SEND_MESSAGE_SUCCESSFUL,
  CHAT_TYPING_DETECTED,
  CHAT_UPDATE_CONNECTION_STATUS,
} from './actions';
import { USER_LOGOUT } from '../auth/actions';

import { IConnection } from '../connection/types';
import { ChatbotState, IChatbotMessage, IConversationContext } from './types';

export const DEFAULT_CHAT_LOCAL_CONTEXT = {
  contact: {} as IConnection,
  connectionStatus: 0,
  chatMessages: [] as IChatbotMessage[],
  conversationContext: null as IConversationContext | null,
  typingText: '',
  initTyping: '',
  sendingMessage: false,
  isConnectedBefore: false,
  pendingAutoReply: null,
  watermark: 0,
  loadEarlier: true,
  isLoadingEarlier: false,
  chatPaused: false,
  sendbirdStatus: 0,
};

export default function chatbotReducer(state = DEFAULT_CHAT_LOCAL_CONTEXT, action: IAction): ChatbotState {
  const { type, payload } = action;

  switch (type) {
    case CHAT_INIT_SUCCESSFUL: {
      return {
        ...state,
        connectionStatus: payload.connectionStatus,
        conversationContext: payload.conversationContext,
        contact: payload.contact,
        isConnectedBefore: payload.isConnectedBefore,
        watermark: payload.watermark,
        loadEarlier: payload.conversationHistory.length >= 10,
        chatMessages: payload.conversationHistory,
        initTyping: payload.conversationHistory.length === 0 ? 'Typing...' : '',
      };
    }
    case CHAT_PAUSE: {
      return { ...state, chatPaused: true };
    }
    case CHAT_RESUME: {
      return { ...state, chatPaused: false };
    }
    case CHAT_UPDATE_CONNECTION_STATUS: {
      return { ...state, connectionStatus: payload.status };
    }
    case CHAT_TYPING_DETECTED: {
      return {
        ...state,
        chatMessages: [payload, ...state.chatMessages],
        typingText: 'Typing...',
        initTyping: '',
      };
    }
    case CHAT_ADD_MESSAGE: {
      // const watermark = payload.watermark ? payload.watermark : state.watermark;
      return {
        ...state,
        typingText: '',
        initTyping: '',
        chatMessages: [payload, ...state.chatMessages],
        watermark: state.watermark + 1,
      };
    }
    case CHAT_HIDE_SINGLE_CHOICES: {
      if (state.chatMessages.length > 0) {
        state.chatMessages.forEach((msg: any) => {
          if (
            msg.attachments &&
            msg.attachments.length &&
            msg.attachments[0].contentType &&
            msg.attachments[0].contentType === 'single-select'
          ) {
            msg.attachments[0].content.choices = [];
          }
        });
      }

      return { ...state, chatMessages: [...state.chatMessages] };
    }
    case CHAT_SEND_MESSAGE: {
      return {
        ...state,
        typingText: '',
        initTyping: '',
        chatMessages: [payload, ...state.chatMessages],
      };
    }
    case CHAT_SEND_MESSAGE_IN_PROGRESS: {
      // Update sendingMessage status and also clear auto pending auto reply if needed
      let pendingAutoReply = state.pendingAutoReply as any;
      if (pendingAutoReply !== null && payload.text === pendingAutoReply._id) {
        pendingAutoReply = null;
      }
      return { ...state, sendingMessage: true, pendingAutoReply: pendingAutoReply };
    }
    case CHAT_SEND_MESSAGE_SUCCESSFUL: {
      return { ...state, sendingMessage: false };
    }
    case CHAT_AUTO_REPLY_REGISTERED: {
      return { ...state, pendingAutoReply: payload };
    }
    case CHAT_SEND_MESSAGE_FAILED: {
      // TODO:: push this message to pendingAutoReply for replaying
      return { ...state, sendingMessage: false };
    }
    case CHAT_LOAD_EARLIER: {
      return { ...state, isLoadingEarlier: true };
    }
    case CHAT_LOADED_EARLIER: {
      return {
        ...state,
        loadEarlier: payload.conversationHistory.length === 10,
        watermark: state.watermark + payload.conversationHistory.length,
        isLoadingEarlier: false,
        chatMessages: [...state.chatMessages, ...payload.conversationHistory],
      };
    }
    case CHAT_EXIT:
    case CHAT_REQUEST_INITIALIZE: {
      return {
        ...state,
        ...DEFAULT_CHAT_LOCAL_CONTEXT,
        sendbirdStatus: state.sendbirdStatus,
        conversationContext: null,
      };
    }
    case USER_LOGOUT: {
      return DEFAULT_CHAT_LOCAL_CONTEXT;
    }
    default:
      return state;
  }
}
