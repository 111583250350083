import { IAction } from 'redux/store/types';
import { InvoiceState, IInvoice } from './types';
import { FETCH_INVOICES, FETCH_INVOICES_FAILED, FETCH_INVOICES_SUCCESSFUL } from './actions';

export const DEFAULT = {
  isLoading: false,
  isRequesting: false,
  errorMsg: '',
  invoices: [] as IInvoice[],
};

export default function invoiceReducer(state = DEFAULT, action: IAction): InvoiceState {
  const { type, payload } = action;
  switch (type) {
    case FETCH_INVOICES:
      return {
        ...state,
        isLoading: true,
        isRequesting: false,
        invoices: [] as IInvoice[],
        errorMsg: '',
      };
    case FETCH_INVOICES_FAILED: {
      return {
        ...state,
        isLoading: false,
        invoices: [] as IInvoice[],
        errorMsg: payload?.message || '',
      };
    }
    case FETCH_INVOICES_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        errorMsg: '',
        invoices: payload.data,
      };
    }
    default:
      return state;
  }
}
