import React, { FC } from 'react';

interface IGlyphIcon {
  className?: string;
  color?: string;
  onClick?: () => void;
}

const CustomFilterIcon: FC<IGlyphIcon> = ({ className = '', color, onClick }) => {
  return (
    <svg
      onClick={onClick}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M2.96875 4.9375C2.96875 4.40625 3.40625 3.9375 3.96875 3.9375H15.9688C16.5 3.9375 16.9688 4.40625 16.9688 4.9375C16.9688 5.5 16.5 5.9375 15.9688 5.9375H3.96875C3.40625 5.9375 2.96875 5.5 2.96875 4.9375ZM4.96875 9.9375C4.96875 9.40625 5.40625 8.9375 5.96875 8.9375H13.9688C14.5 8.9375 14.9688 9.40625 14.9688 9.9375C14.9688 10.5 14.5 10.9375 13.9688 10.9375H5.96875C5.40625 10.9375 4.96875 10.5 4.96875 9.9375ZM11.9688 14.9375C11.9688 15.5 11.5 15.9375 10.9688 15.9375H8.96875C8.40625 15.9375 7.96875 15.5 7.96875 14.9375C7.96875 14.4062 8.40625 13.9375 8.96875 13.9375H10.9688C11.5 13.9375 11.9688 14.4062 11.9688 14.9375Z"
        fill={color}
      />
    </svg>
  );
};

export default CustomFilterIcon;
