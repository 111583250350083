import { uploadMedia } from '../services/media/media.service';

const uploadMediaFiles = async (file, prefix: string, onProgress) => {
  try {
    const keyPrefix = prefix + new Date().getTime().toString();
    const response = await uploadMedia({ file, prefix: keyPrefix, contentType: file?.type });

    if (onProgress) {
      onProgress({ percent: 1 });
    }

    if (response && response.status === 200) {
      return {
        response: response.data?.postResponse,
        success: true,
      };
    }
    return { success: false };
  } catch (error) {
    console.error('Error uploading media: ', error);
    return { success: false };
  }
};

const uploadChatMedia = (file, onProgress) => {
  return uploadMediaFiles(file, 'chatMedia/', onProgress);
};

const uploadExercisesMedia = (file, onProgress) => {
  return uploadMediaFiles(file, 'exercises/', onProgress);
};

const uploadContactNotesMedia = (file, keyPrefix, onProgress) => {
  return uploadMediaFiles(file, keyPrefix, onProgress);
};

const uploadDisplayPicture = (file, onProgress) => {
  return uploadMediaFiles(file, 'displayPics/', onProgress);
};

const uploadChatAvatar = (file, onProgress) => {
  return uploadMediaFiles(file, 'chatBot/', onProgress);
};

const uploadInsuranceImage = (file, onProgress) => {
  return uploadMediaFiles(file, 'idCardImages/', onProgress);
};

export const MediaManager = {
  uploadMediaFiles,
  uploadChatMedia,
  uploadExercisesMedia,
  uploadContactNotesMedia,
  uploadDisplayPicture,
  uploadChatAvatar,
  uploadInsuranceImage,
};
