import { AxiosResponse } from 'axios';
import { apiEndpoints } from 'constants/apiEndpoints';
import { baseRequest } from 'utils/httpClient';

export const getSystemServices = (): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.GET_SYSTEM_SERVICES);
};

export const getPublicAllServices = (): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.GET_PUBLIC_ALL_SERVICES, undefined);
};

// need to change this
export const getPublicProviderServices = (queryParams: { providerId: string }): Promise<AxiosResponse> => {
  const queryParams2 = {
    viewProviderDetails: true,
    participantId: queryParams.providerId,
  };
  return baseRequest(apiEndpoints.GET_AVAILABLE_SLOTS, undefined, undefined, queryParams2);
};

export const getProviderServices = (queryParams: { providerId: string }): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.GET_PROVIDER_SERVICES_BY_ID, undefined, undefined, queryParams);
};

export const getProviderSchedule = (providerId: string): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.GET_PROVIDER_SCHEDULE, undefined, undefined, { providerId });
};

export const getPublicProviderSchedule = (queryParams): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.GET_AVAILABLE_SLOTS, undefined, undefined, queryParams);
};

// Create/Update telehealth session feedback
export const updateTelehealthFeedback = (bodyRequest: {
  appointmentId?: string;
  action: string;
  memberFeedbackDto: {
    privateFeedback: string;
    publicComment: string;
    rating: number | null;
    sessionConnected: boolean;
    sessionWorks: boolean;
  };
}): Promise<AxiosResponse> => {
  const appointmentId = bodyRequest.appointmentId;
  // delete bodyRequest.appointmentId;
  return baseRequest(apiEndpoints.UPDATE_TELEHEALTH_FEEDBACK, bodyRequest, { appointmentId });
};
