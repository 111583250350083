import { createAction } from 'redux-actions';

export const FETCH_CONNECTIONS_PUBLIC = 'connections/public/fetch';
export const FETCH_CONNECTIONS_SUCCESSFUL_PUBLIC = 'connections/public/fetchSuccessful';
export const FETCH_CONNECTIONS_FAILED_PUBLIC = 'connections/public/fetchSuccess';

export const FETCH_BOT_PROGRESS_PUBLIC = 'connections/public/FETCH_BOT_PROGRESS';

export const publicConnectionActionCreators = {
  fetchConnections: createAction(FETCH_CONNECTIONS_PUBLIC),
};
