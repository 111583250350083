import { AxiosResponse } from 'axios';
import { apiEndpoints } from 'constants/apiEndpoints';
import { IGetAvailableSlotsBodyRequest } from 'services/appointment/appointment.types';
import { baseRequest } from 'utils/httpClient';

type IGetProvidersBodyRequest = {
  activeInChat: boolean;
  designation: string[];
  levelOfEngagements: string[];
  searchQuery: string;
  session: string;
  startDate: string;
  endDate: string;
};

type IGetProvidersQueryParams = {
  pageNumber?: number;
  pageSize?: number;
  page?: number;
  size?: number;
  orderBy?: string;
  sortBy?: string;
  viewProviderDetails?: boolean;
  viewInsurances?: boolean;
  state?: string | undefined;
  payerId?: string;
  searchQuery?: string;
  startDate?: string;
  providerRoles?: string;
};

export const getProviders = (
  bodyRequest: IGetProvidersBodyRequest,
  queryParams: IGetProvidersQueryParams
): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.GET_PROVIDERS, {}, undefined, queryParams);
};

export const getAllProviders = (queryParams: IGetAvailableSlotsBodyRequest): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.GET_AVAILABLE_SLOTS, undefined, undefined, queryParams);
};

export const getPublicProviders = (queryParams: IGetProvidersQueryParams): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.GET_AVAILABLE_SLOTS, undefined, undefined, queryParams);
};

export const getProviderById = (pathParams: { providerId: string }): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.GET_PROVIDER, undefined, pathParams);
};
