import { FC } from 'react';

interface IGlyphIcon {
  className?: string;
  color?: string;
  onClick?: () => void;
}

const GooglePay: FC<IGlyphIcon> = ({ className = '', onClick }) => {
  return (
    <svg
      width="58"
      height="40"
      viewBox="0 0 58 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
    >
      <rect x="0.5" y="0.5" width="57" height="39" rx="5.5" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.763 25.88V20.9889H29.2874C30.3218 20.9889 31.1949 20.6423 31.9067 19.9587L32.0775 19.7854C33.3776 18.3701 33.2922 16.1653 31.9067 14.8558C31.2139 14.1626 30.2649 13.7871 29.2874 13.8064H25.2351V25.88H26.763ZM26.7632 19.5061V15.289H29.3259C29.8765 15.289 30.3985 15.5008 30.7877 15.8859C31.6134 16.6947 31.6324 18.0426 30.8351 18.8803C30.446 19.2943 29.8954 19.5254 29.3259 19.5061H26.7632ZM39.2046 18.2641C38.5498 17.6576 37.6577 17.3495 36.5284 17.3495C35.0764 17.3495 33.9851 17.8887 33.2638 18.9574L34.6114 19.8143C35.1049 19.0825 35.7787 18.7167 36.6328 18.7167C37.1737 18.7167 37.6957 18.9189 38.1038 19.2847C38.5024 19.6313 38.7301 20.132 38.7301 20.6615V21.0178C38.1417 20.6904 37.4015 20.5171 36.4905 20.5171C35.4276 20.5171 34.5735 20.7675 33.9376 21.2777C33.3018 21.788 32.9791 22.462 32.9791 23.3189C32.9601 24.0988 33.2923 24.8402 33.8807 25.3408C34.4786 25.88 35.2378 26.1496 36.1298 26.1496C37.1832 26.1496 38.0184 25.6778 38.6542 24.7342H38.7206V25.88H40.1821V20.7867C40.1821 19.718 39.8595 18.8707 39.2046 18.2641ZM35.0577 24.3588C34.7445 24.1277 34.5546 23.7522 34.5546 23.3478C34.5546 22.8953 34.7635 22.5198 35.1716 22.2213C35.5892 21.9229 36.1113 21.7688 36.7282 21.7688C37.5825 21.7592 38.2469 21.9517 38.7215 22.3369C38.7215 22.9916 38.4652 23.5596 37.9621 24.041C37.5065 24.5032 36.8896 24.7632 36.2442 24.7632C35.817 24.7728 35.3994 24.6284 35.0577 24.3588ZM43.4657 29.5098L48.5714 17.6191H46.9107L44.5476 23.55H44.5191L42.0992 17.6191H40.4384L43.7884 25.3504L41.8904 29.5098H43.4657Z"
        fill="#3C4043"
      />
      <path
        d="M21.4881 19.9299C21.4881 19.4581 21.4501 18.9863 21.3742 18.5242H14.9304V21.1912H18.6221C18.4702 22.0481 17.9768 22.8183 17.2555 23.2997V25.0328H19.4572C20.7479 23.8293 21.4881 22.0481 21.4881 19.9299Z"
        fill="#4285F4"
      />
      <path
        d="M14.9308 26.7081C16.7719 26.7081 18.3282 26.0919 19.4576 25.0328L17.2559 23.2997C16.639 23.7233 15.8513 23.964 14.9308 23.964C13.1466 23.964 11.6377 22.7413 11.0968 21.1045H8.82861V22.8953C9.98641 25.235 12.3495 26.7081 14.9308 26.7081Z"
        fill="#34A853"
      />
      <path
        d="M11.097 21.1045C10.8122 20.2476 10.8122 19.3136 11.097 18.4471V16.6659H8.82845C7.8508 18.6204 7.8508 20.9311 8.82845 22.8857L11.097 21.1045Z"
        fill="#FBBC04"
      />
      <path
        d="M14.9308 15.5876C15.9083 15.5683 16.8478 15.9438 17.5501 16.6274L19.505 14.644C18.2618 13.4694 16.6295 12.8243 14.9308 12.8436C12.3495 12.8436 9.98641 14.3263 8.82861 16.6659L11.0968 18.4568C11.6377 16.8103 13.1466 15.5876 14.9308 15.5876Z"
        fill="#EA4335"
      />
      <rect x="0.5" y="0.5" width="57" height="39" rx="5.5" stroke="#E0E6EB" />
    </svg>
  );
};

export default GooglePay;
