import { AxiosResponse } from 'axios';
import { apiEndpoints } from 'constants/apiEndpoints';
import { baseRequest } from 'utils/httpClient';

// Retrieves all conversations of the specified organization
export const getConversations = (): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.GET_CONVERSATIONS, undefined, undefined);
};

export const getNextContentBlock = payload => {
  return baseRequest(apiEndpoints.GET_NEXT_CONTENT_BLOCK, payload);
};

export const getNextContentBlockFullPage = payload => {
  return baseRequest(apiEndpoints.GET_NEXT_CONTENT_BLOCK_FULL_PAGE, payload);
};

// Assign conversation to a patient
export const assignChatbot = (bodyRequest: {
  conversationId: string;
  organizationId: string;
  patientUserId: string;
  priority: string;
}): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.ASSIGN_CONVERSATION, bodyRequest);
};

// Retrieves a list a chatbot contacts for a specific user alongwith their percentage completions
export const getChatbots = (userId: string, includeResponses = true): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.CHATBOT_DETAILS, undefined, { userId }, { includeResponses });
};

// Generate a new directline token
export const getChatToken = (contactId: string): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.GET_CHAT_TOKEN, { contactId });
};

// Get at most 10 historical messages providing how many messages to skip
export const getConversationHistory = (queryParams: {
  contactId: string;
  skip: number;
}): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.GET_CHAT_BOT_HISTORY, undefined, undefined, queryParams);
};

// Restart a chatbot contact
export const restartChatbot = (contactId: string): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.RESTART_CHATBOT, undefined, { contactId });
};

export const getChannelUrl = (pathParams: { connectionId: string }): Promise<AxiosResponse> => {
  if (!pathParams?.connectionId) {
    console.error('No Connection Id');
  }
  return baseRequest(apiEndpoints.GET_CHANNEL_URL, undefined, pathParams);
};

export const refreshDirectLineToken = (requestBody): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.REFRESH_DIRECTLINE_TOKEN, requestBody);
};

export const getSignedUrl = (requestBody): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.AWS_SIGNEDURL, requestBody);
};
