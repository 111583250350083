import { AxiosResponse } from 'axios';
import { apiEndpoints } from 'constants/apiEndpoints';
import { baseRequest } from 'utils/httpClient';

type ProxyRequest = {
  body?: any;
  method: string;
  params?: any;
  url: string;
  memberId: string;
};

export const bypassProxy = (request: ProxyRequest): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.BYPASS_PROXY, request, undefined);
};
