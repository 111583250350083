import { AvatarColor } from './Avatar.types';

import { theme } from 'themes';

export const bgColors = {
  [AvatarColor.IMAGE]: theme.color.neutral[800],
  [AvatarColor.PRIMARY]: theme.color.primary[600],
  [AvatarColor.SECONDARY]: theme.color.secondary[600],
  [AvatarColor.INDIGO]: theme.color.decorative.indigo[600],
  [AvatarColor.ORANGE]: theme.color.decorative.orange[600],
  [AvatarColor.LIME]: theme.color.decorative.lime[600],
  [AvatarColor.NEUTRAL]: theme.color.neutral[800],
};
