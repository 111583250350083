import dayjs from 'dayjs';
import { IAction } from 'redux/store/types';
import { AppointmentState, IAppointment, IInsurance, IMissingInfoIndicators, ISchedulingData } from './types';
import {
  REJECT_APPOINTMENT_SUCCESSFUL,
  CREATE_APPOINTMENT_REQUEST,
  CREATE_APPOINTMENT_REQUEST_FAILED,
  CREATE_APPOINTMENT_REQUEST_SUCCESSFUL,
  FETCH_APPOINTMENTS,
  FETCH_APPOINTMENTS_FAILED,
  FETCH_APPOINTMENTS_SUCCESSFUL,
  ACCEPT_APPOINTMENT_SUCCESSFUL,
  SET_SELECTED_SERVICE,
  FETCH_ALL_PROVIDER_SERVICES_SUCCESSFUL,
  FETCH_MASTER_SCHEDULE_SUCCESSFUL,
  FETCH_INSURANCES_BY_STATE_SUCCESSFUL,
  FETCH_INSURANCES_BY_STATE_FAILED,
  SET_SELECTED_SlOT,
  SET_SELECTED_SlOT_PROVIDERS,
  SET_SELECTED_PROVIDER,
  FETCH_INSURANCES_BY_STATE,
  INSURANCE_DETAILS,
  FETCH_ALL_PROVIDER_SERVICES,
  FETCH_ALL_PROVIDER_SERVICES_FAILED,
  SELECTED_INSURANCE_DATA,
  UPDATE_PROVIDER,
  UPDATE_INSURANCE,
  UPDATE_INSURANCE_DETAILS,
  UPDATE_SERVICE,
  UPDATE_START_TIME,
  UPDATE_PROFILE,
  UPDATE_PAYMENT,
  UPDATE_URL_PARAMS,
  APPOINTMENT_DETAILS,
  CLEAR_SCHEDULING_CACHE,
  FETCH_MASTER_SCHEDULE,
  SAVE_MISSING_INFO_INDICATORS,
  APPOINTMENT_CHARGE_AMOUNT,
} from './actions';
import { AppointmentStatus } from 'pages/appointments/Appointments.constants';

export const DEFAULT = {
  selectedService: {},
  selectedSlot: {},
  selectedProvider: {},
  appointmentDetailById: {},
  fetchAppointmentsTrigger: false,
  insuranceDetails: {
    dob: '',
    memberId: '',
    firstName: '',
    lastName: '',
  },
  selectedSlotProviders: [],
  allProviderServices: [],
  allProviderServicesLoading: false,
  masterSchedule: [],
  isLoading: false,
  isRequesting: false,
  errorMsg: '',
  appointments: [] as IAppointment[],
  currentPage: 1,
  totalPages: 1,
  totalRecords: 0,
  insurances: [] as IInsurance[],
  insurancesLoading: false,
  selectedInsuranceData: {} as IInsurance,
  schedulingData: {
    urlParams: {},
    cache: {},
  } as ISchedulingData,
  missingInfoIndicators: {
    firstName: false,
    lastName: false,
    fullName: false,
    phoneNumber: false,
    emailAddress: false,
    address1: false,
    address2: false,
    dob: false,
    gender: false,
    state: false,
    city: false,
    zipCode: false,
    hipaa: false,
    pcpRelease: false,
    consentForNewHampshire: false,
    consentForBuprenorphineSuboxone: false,
    consentForAdolescence: '',
    qualityAssurance: false,
    electronicCommunication: false,
    userCreditCardExist: false,
    userInsuranceExist: false,
    memberInsuranceCheck: {
      primaryInsured: false,
      firstName: false,
      lastName: false,
      fullName: false,
      dob: false,
      memberId: false,
      payerName: '',
      stateName: '',
    },
  } as IMissingInfoIndicators,
};

export default function appointmentReducer(state = DEFAULT, action: IAction): AppointmentState {
  const { type, payload } = action;
  switch (type) {
    case FETCH_APPOINTMENTS:
      return {
        ...state,
        isLoading: true,
        isRequesting: false,
        errorMsg: '',
      };
    case FETCH_APPOINTMENTS_FAILED: {
      return {
        ...state,
        isLoading: false,
        appointments: [] as IAppointment[],
        errorMsg: payload?.message || '',
      };
    }
    case FETCH_APPOINTMENTS_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        errorMsg: '',
        appointments: payload.appointments,
        currentPage: payload.currentPage,
        totalPages: payload.totalPages,
        totalRecords: payload.totalRecords,
      };
    }
    case CREATE_APPOINTMENT_REQUEST:
    case CREATE_APPOINTMENT_REQUEST_FAILED:
      return {
        ...state,
        isRequesting: false,
        errorMsg: payload?.message || '',
      };
    case CREATE_APPOINTMENT_REQUEST_SUCCESSFUL:
      return {
        ...state,
        isRequesting: true,
        errorMsg: '',
      };
    case ACCEPT_APPOINTMENT_SUCCESSFUL: {
      return {
        ...state,
        fetchAppointmentsTrigger: !state.fetchAppointmentsTrigger,
        appointments: state.appointments
          .map(item => {
            if (item.appointmentId === payload.appointmentId) {
              return {
                ...item,
                status: AppointmentStatus.BOOKED,
              };
            }
            return item;
          })
          .sort((a, b) => dayjs(a.startTime).unix() - dayjs(b.startTime).unix()),
      };
    }
    case FETCH_INSURANCES_BY_STATE: {
      return {
        ...state,
        insurancesLoading: true,
      };
    }
    case FETCH_INSURANCES_BY_STATE_SUCCESSFUL: {
      return {
        ...state,
        insurances: payload.data,
        insurancesLoading: false,
      };
    }
    case FETCH_INSURANCES_BY_STATE_FAILED: {
      return {
        ...state,
        insurances: [],
        insurancesLoading: false,
      };
    }
    case REJECT_APPOINTMENT_SUCCESSFUL: {
      return {
        ...state,
        appointments: state.appointments.map(item => {
          if (item.appointmentId === payload.appointmentId) {
            return {
              ...item,
              status: AppointmentStatus.CANCELLED,
            };
          }
          return item;
        }),
      };
    }
    case FETCH_ALL_PROVIDER_SERVICES: {
      return {
        ...state,
        allProviderServicesLoading: true,
      };
    }
    case FETCH_ALL_PROVIDER_SERVICES_SUCCESSFUL: {
      return {
        ...state,
        allProviderServices: payload?.schedule,
        allProviderServicesLoading: false,
      };
    }
    case FETCH_ALL_PROVIDER_SERVICES_FAILED: {
      return {
        ...state,
        allProviderServices: [],
        allProviderServicesLoading: false,
      };
    }
    case FETCH_MASTER_SCHEDULE: {
      return {
        ...state,
        masterSchedule: [],
      };
    }
    case FETCH_MASTER_SCHEDULE_SUCCESSFUL: {
      return {
        ...state,
        masterSchedule: payload?.data,
      };
    }
    case SET_SELECTED_SERVICE: {
      return {
        ...state,
        selectedService: payload,
      };
    }
    case SET_SELECTED_SlOT: {
      return {
        ...state,
        selectedSlot: payload,
      };
    }
    case SET_SELECTED_SlOT_PROVIDERS: {
      return {
        ...state,
        selectedSlotProviders: payload,
      };
    }
    case SET_SELECTED_PROVIDER: {
      return {
        ...state,
        selectedProvider: payload,
      };
    }
    case INSURANCE_DETAILS:
      return {
        ...state,
        insuranceDetails: {
          memberId: payload.memberId,
          firstName: payload.firstName,
          lastName: payload.lastName,
          dob: payload.dob,
        },
      };
    case SELECTED_INSURANCE_DATA:
      return {
        ...state,
        selectedInsuranceData: payload,
      };
    case UPDATE_PROVIDER: {
      return {
        ...state,
        schedulingData: {
          ...state.schedulingData,
          cache: {
            ...state.schedulingData.cache,
            provider: payload,
          },
        },
      };
    }
    case UPDATE_INSURANCE: {
      return {
        ...state,
        schedulingData: {
          ...state.schedulingData,
          cache: {
            ...state.schedulingData.cache,
            insurance: payload,
          },
        },
      };
    }
    //Write other reducers for scheduling cache the ones that are not written yet
    case UPDATE_INSURANCE_DETAILS: {
      return {
        ...state,
        schedulingData: {
          ...state.schedulingData,
          cache: {
            ...state.schedulingData.cache,
            insuranceDetails: payload,
          },
        },
      };
    }
    case UPDATE_SERVICE: {
      return {
        ...state,
        schedulingData: {
          ...state.schedulingData,
          cache: {
            ...state.schedulingData.cache,
            service: payload,
          },
        },
      };
    }
    case UPDATE_START_TIME: {
      return {
        ...state,
        schedulingData: {
          ...state.schedulingData,
          cache: {
            ...state.schedulingData.cache,
            startTime: payload,
          },
        },
      };
    }
    case UPDATE_PROFILE: {
      return {
        ...state,
        schedulingData: {
          ...state.schedulingData,
          cache: {
            ...state.schedulingData.cache,
            profile: payload,
          },
        },
      };
    }
    case UPDATE_PAYMENT: {
      return {
        ...state,
        schedulingData: {
          ...state.schedulingData,
          cache: {
            ...state.schedulingData.cache,
            payment: payload,
          },
        },
      };
    }
    case UPDATE_URL_PARAMS: {
      return {
        ...state,
        schedulingData: {
          ...state.schedulingData,
          urlParams: payload,
        },
      };
    }
    case APPOINTMENT_DETAILS: {
      return {
        ...state,
        schedulingData: {
          ...state.schedulingData,
          cache: {
            ...state.schedulingData.cache,
            appointmentDetails: payload,
          },
        },
      };
    }
    case APPOINTMENT_CHARGE_AMOUNT: {
      return {
        ...state,
        schedulingData: {
          ...state.schedulingData,
          cache: {
            ...state.schedulingData.cache,
            appointmentChargeAmount: payload,
          },
        },
      };
    }
    case CLEAR_SCHEDULING_CACHE: {
      return {
        ...state,
        schedulingData: {
          urlParams: {},
          cache: {},
        },
        missingInfoIndicators: DEFAULT.missingInfoIndicators,
      };
    }
    case SAVE_MISSING_INFO_INDICATORS: {
      return {
        ...state,
        missingInfoIndicators: payload || { ...DEFAULT.missingInfoIndicators },
      };
    }
    default:
      return state;
  }
}
