import { IAction } from 'redux/store/types';
import {
  FETCH_PROVIDER_SCHEDULE,
  FETCH_PROVIDER_SCHEDULE_FAILED,
  FETCH_PROVIDER_SCHEDULE_SUCCESSFUL,
  FETCH_PROVIDER_SERVICES,
  FETCH_PROVIDER_SERVICES_FAILED,
  FETCH_PROVIDER_SERVICES_SUCCESSFUL,
  FETCH_PUBLIC_ALL_SERVICES,
  FETCH_PUBLIC_PROVIDER_SCHEDULE,
  FETCH_PUBLIC_PROVIDER_SCHEDULE_FAILED,
  FETCH_PUBLIC_PROVIDER_SCHEDULE_SUCCESSFUL,
  FETCH_PUBLIC_PROVIDER_SERVICES,
  FETCH_SYSTEM_SERVICES,
  FETCH_SYSTEM_SERVICES_FAILED,
  FETCH_SYSTEM_SERVICES_SUCCESSFUL,
} from './actions';
import { IProviderSchedule, ScheduleState } from './types';
import { IScheduleV2, IService } from '../appointment/types';

export const DEFAULT = {
  providerSchedule: {
    isLoading: false,
    isSaving: false,
    errorMsg: '',
    schedule: {} as IProviderSchedule,
  },
  publicSchedule: {
    isLoading: false,
    isSaving: false,
    errorMsg: '',
    schedules: [] as IScheduleV2[],
  },
  providerServices: {
    isLoading: false,
    errorMsg: '',
    services: [] as IService[],
  },
  systemServices: { isLoading: false, errorMsg: '', list: [] as string[] },
};

export default function scheduleReducer(state = DEFAULT, action: IAction): ScheduleState {
  const { type, payload } = action;
  switch (type) {
    case FETCH_SYSTEM_SERVICES:
      return {
        ...state,
        systemServices: {
          ...state.systemServices,
          isLoading: true,
          errorMsg: '',
        },
      };
    case FETCH_SYSTEM_SERVICES_FAILED: {
      return {
        ...state,
        systemServices: {
          ...state.systemServices,
          isLoading: false,
          list: [],
          errorMsg: payload?.message || '',
        },
      };
    }
    case FETCH_SYSTEM_SERVICES_SUCCESSFUL: {
      return {
        ...state,
        systemServices: {
          ...state.systemServices,
          isLoading: false,
          errorMsg: '',
          list: payload.list,
        },
      };
    }
    case FETCH_PROVIDER_SERVICES: {
      return {
        ...state,
        providerServices: {
          ...state.providerServices,
          isLoading: true,
        },
      };
    }
    case FETCH_PUBLIC_PROVIDER_SERVICES: {
      return {
        ...state,
        providerServices: {
          ...state.providerServices,
          isLoading: true,
          services: [],
        },
      };
    }
    case FETCH_PUBLIC_ALL_SERVICES: {
      return {
        ...state,
        providerServices: {
          ...state.providerServices,
          isLoading: true,
        },
      };
    }
    case FETCH_PROVIDER_SERVICES_SUCCESSFUL: {
      return {
        ...state,
        providerServices: {
          ...state.providerServices,
          isLoading: false,
          services: payload,
        },
      };
    }
    case FETCH_PROVIDER_SERVICES_FAILED: {
      return {
        ...state,
        providerServices: {
          ...state.providerServices,
          isLoading: false,
          services: [],
          errorMsg: payload.message,
        },
      };
    }
    case FETCH_PROVIDER_SCHEDULE: {
      return {
        ...state,
        providerSchedule: {
          ...state.providerSchedule,
          isLoading: true,
          errorMsg: '',
        },
      };
    }
    case FETCH_PROVIDER_SCHEDULE_SUCCESSFUL: {
      return {
        ...state,
        providerSchedule: {
          ...state.providerSchedule,
          isLoading: false,
          schedule: payload.schedule,
        },
      };
    }
    case FETCH_PROVIDER_SCHEDULE_FAILED: {
      return {
        ...state,
        providerSchedule: {
          ...state.providerSchedule,
          isLoading: false,
          errorMsg: payload?.message || null,
          schedule: {} as IProviderSchedule,
        },
      };
    }
    case FETCH_PUBLIC_PROVIDER_SCHEDULE: {
      return {
        ...state,
        publicSchedule: {
          ...state.publicSchedule,
          isLoading: true,
          errorMsg: '',
        },
      };
    }
    case FETCH_PUBLIC_PROVIDER_SCHEDULE_SUCCESSFUL: {
      return {
        ...state,
        publicSchedule: {
          ...state.publicSchedule,
          isLoading: false,
          schedules: payload.schedules?.results,
        },
      };
    }
    case FETCH_PUBLIC_PROVIDER_SCHEDULE_FAILED: {
      return {
        ...state,
        publicSchedule: {
          ...state.publicSchedule,
          isLoading: false,
          errorMsg: payload?.message || null,
          schedules: [] as IScheduleV2[],
        },
      };
    }
    default:
      return state;
  }
}
