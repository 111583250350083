import { IAction } from 'redux/store/types';
import { ConnectionState, IConnection, ICareTeamMember } from './types';
import {
  FETCH_CONNECTIONS_PUBLIC,
  FETCH_CONNECTIONS_SUCCESSFUL_PUBLIC,
  FETCH_CONNECTIONS_FAILED_PUBLIC,
} from './actions';

export const DEFAULT = {
  isLoading: false,
  isRequesting: false,
  errorMsg: '',
  careTeamMembers: [] as ICareTeamMember[],
  activeConnections: [] as IConnection[],
  pastConnections: [] as IConnection[],
  requestedConnections: [] as IConnection[],
};

export default function publicConnectionReducer(state = DEFAULT, action: IAction): ConnectionState {
  const { type, payload } = action;
  switch (type) {
    case FETCH_CONNECTIONS_PUBLIC:
      return {
        ...state,
        isLoading: true,
        isRequesting: false,
        errorMsg: '',
      };
    case FETCH_CONNECTIONS_FAILED_PUBLIC: {
      return {
        ...state,
        isLoading: false,
        activeConnections: [] as IConnection[],
        pastConnections: [] as IConnection[],
        requestedConnections: [] as IConnection[],
        errorMsg: payload?.message || '',
      };
    }
    case FETCH_CONNECTIONS_SUCCESSFUL_PUBLIC: {
      return {
        ...state,
        isLoading: false,
        errorMsg: '',
        activeConnections: payload.activeConnections,
        pastConnections: payload.pastConnections,
        requestedConnections: payload.requestedConnections,
      };
    }

    default:
      return state;
  }
}
