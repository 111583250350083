import { createAction } from 'redux-actions';

export const FETCH_STATES = 'states/fetch';
export const FETCH_STATES_SUCCESSFUL = 'states/fetchSuccessful';
export const FETCH_STATES_FAILED = 'states/fetchSuccess';

export const FETCH_ACTIVE_STATES = 'states/active/fetch';
export const FETCH_ACTIVE_STATES_SUCCESSFUL = 'states/activefetchSuccessful';
export const FETCH_ACTIVE_STATES_FAILED = 'states/active/fetchSuccess';

export const FETCH_PAYERS_BY_STATE_ID = 'state/payers/fetch';
export const FETCH_PAYERS_BY_STATE_ID_SUCCESSFUL = 'state/payers/fetchSuccessful';
export const FETCH_PAYERS_BY_STATE_ID_FAILED = 'state/payers/fetchSuccess';

export const FETCH_PAYERS_BY_STATE_NAME = '/payers/by-state-name/fetch';
export const FETCH_PAYERS_BY_STATE_NAME_SUCCESSFUL = 'state/payers/fetchSuccessful';
export const FETCH_PAYERS_BY_STATE_NAME_FAILED = 'state/payers/fetchSuccess';

export const stateActionCreators = {
  fetchStates: createAction(FETCH_STATES),
  fetchActiveStates: createAction(FETCH_ACTIVE_STATES),
  fetchPayersByState: createAction(FETCH_PAYERS_BY_STATE_ID),
  fetchPayersByStateName: createAction(FETCH_PAYERS_BY_STATE_NAME),
};
