import { call, put, takeLatest } from 'redux-saga/effects';
import { IAction } from 'redux/store/types';
import * as PaymentService from 'services/payment/payment.service';
import {
  FETCH_CARDS,
  FETCH_CARDS_FAILED,
  FETCH_CARDS_SUCCESSFUL,
  DELETE_CARD,
  DELETE_CARD_FAILED,
  DELETE_CARD_SUCCESSFUL,
  ADD_PAYMENT_CARD_SUCCESSFUL,
  ADD_PAYMENT_CARD_FAILED,
  ADD_PAYMENT_CARD,
  FETCH_BILLING_INSURANCE_SUCCESSFUL,
  FETCH_BILLING_INSURANCE_FAILED,
  FETCH_BILLING_INSURANCE,
  FETCH_SERVICE_INFO_SUCCESSFUL,
  FETCH_SERVICE_INFO_FAILED,
  FETCH_SERVICE_INFO,
} from './actions';

function* fetchCards() {
  try {
    const { data } = yield call(PaymentService.getCards);
    yield put({
      type: FETCH_CARDS_SUCCESSFUL,
      payload: data,
    });
  } catch (e: any) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({ type: FETCH_CARDS_FAILED, payload: { message } });
  }
}

function* fetchBillingProfile() {
  try {
    const { data } = yield call(PaymentService.getBillingInsurance);
    yield put({
      type: FETCH_BILLING_INSURANCE_SUCCESSFUL,
      payload: data,
    });
  } catch (e: any) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({ type: FETCH_BILLING_INSURANCE_FAILED, payload: { message } });
  }
}

function* deleteCard(action: IAction) {
  const { cardId } = action.payload;

  try {
    yield call(PaymentService.deleteCard, cardId);
    yield put({
      type: DELETE_CARD_SUCCESSFUL,
      payload: cardId,
    });
  } catch (e: any) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({ type: DELETE_CARD_FAILED, payload: { message } });
  }
}

function* addCard(action: IAction) {
  const { tokenId, callback } = action.payload;
  try {
    const { data } = yield call(PaymentService.addCard, tokenId);
    yield put({
      type: ADD_PAYMENT_CARD_SUCCESSFUL,
      payload: data,
    });
    yield callback(true, data);
  } catch (e: any) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({ type: ADD_PAYMENT_CARD_FAILED, payload: { message } });
  }
}

function* fetchServiceInfo(action: IAction) {
  const { memberId, serviceId } = action.payload;
  try {
    const { data } = yield call(PaymentService.getChargeAmountWithMemberId, memberId, serviceId);
    yield put({
      type: FETCH_SERVICE_INFO_SUCCESSFUL,
      payload: data,
    });
  } catch (e: any) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({ type: FETCH_SERVICE_INFO_FAILED, payload: { message } });
  }
}

export default function* paymentSaga(): IterableIterator<any> {
  yield takeLatest(FETCH_CARDS, fetchCards);
  yield takeLatest(FETCH_BILLING_INSURANCE, fetchBillingProfile);
  yield takeLatest(FETCH_SERVICE_INFO, fetchServiceInfo);
  yield takeLatest(DELETE_CARD, deleteCard);
  yield takeLatest(ADD_PAYMENT_CARD, addCard);
}
