import { AxiosResponse } from 'axios';
import { apiEndpoints } from 'constants/apiEndpoints';
import { baseRequest } from 'utils/httpClient';

// Retrieves a list of paginated invoices
export const getInvoices = (
  bodyRequest: {
    searchQuery: string;
    startDate: string;
    endDate: string;
    states: string[];
  },
  queryParams: {
    pageSize: number;
    pageNumber: number;
    sortBy: 'createdAt' | 'updatedAt' | 'status';
    sortOrder: 'desc' | 'asc';
  }
): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.GET_INVOICES, bodyRequest, undefined, queryParams);
};
