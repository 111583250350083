import { IAction } from 'redux/store/types';
import {
  FETCH_PROVIDER,
  FETCH_PROVIDERS,
  FETCH_PROVIDERS_FAILED,
  FETCH_PROVIDERS_SUCCESSFUL,
  FETCH_PROVIDER_FAILED,
  FETCH_PROVIDER_SUCCESSFUL,
} from './actions';
import { IProvider, ProviderState } from './types';

export const DEFAULT = {
  providers: {
    isLoading: false,
    errorMsg: '',
    providers: [] as IProvider[],
    currentPage: 1,
    totalPages: 1,
    totalRecords: 0,
  },
  provider: {
    isLoading: false,
    errorMsg: '',
    data: {} as IProvider,
  },
};

export default function providerReducer(state = DEFAULT, action: IAction): ProviderState {
  const { type, payload } = action;
  switch (type) {
    case FETCH_PROVIDERS:
      return {
        ...state,
        providers: {
          ...state.providers,
          isLoading: true,
          errorMsg: '',
        },
      };
    case FETCH_PROVIDERS_FAILED: {
      return {
        ...state,
        providers: {
          ...state.providers,
          isLoading: false,
          providers: [] as IProvider[],
          errorMsg: payload?.message || '',
        },
      };
    }
    case FETCH_PROVIDERS_SUCCESSFUL: {
      return {
        ...state,
        providers: {
          ...state.providers,
          isLoading: false,
          errorMsg: '',
          providers: payload.providers,
          currentPage: payload.currentPage,
          totalPages: payload.totalPages,
          totalRecords: payload.totalRecords,
        },
      };
    }
    case FETCH_PROVIDER:
      return {
        ...state,
        provider: {
          ...state.provider,
          isLoading: true,
          errorMsg: '',
        },
      };
    case FETCH_PROVIDER_FAILED: {
      return {
        ...state,
        provider: {
          ...state.providers,
          isLoading: false,
          data: {} as IProvider,
          errorMsg: payload?.message || '',
        },
      };
    }
    case FETCH_PROVIDER_SUCCESSFUL: {
      return {
        ...state,
        provider: {
          ...state.provider,
          isLoading: false,
          errorMsg: '',
          data: payload.provider,
        },
      };
    }
    default:
      return state;
  }
}
