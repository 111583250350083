import { IAction } from 'redux/store/types';
import { ConnectionState, IConnection, ICareTeamMember } from './types';
import {
  FETCH_CONNECTIONS,
  FETCH_CONNECTIONS_SUCCESSFUL,
  FETCH_CONNECTIONS_FAILED,
  FETCH_CARE_TEAM_MEMBERS,
  FETCH_CARE_TEAM_MEMBERS_FAILED,
  FETCH_CARE_TEAM_MEMBERS_SUCCESSFUL,
} from './actions';

export const DEFAULT = {
  isLoading: false,
  isRequesting: false,
  errorMsg: '',
  careTeamMembers: [] as ICareTeamMember[],
  activeConnections: [] as IConnection[],
  pastConnections: [] as IConnection[],
  requestedConnections: [] as IConnection[],
};

export default function connectionReducer(state = DEFAULT, action: IAction): ConnectionState {
  const { type, payload } = action;
  switch (type) {
    case FETCH_CONNECTIONS:
      return {
        ...state,
        isLoading: true,
        isRequesting: false,
        errorMsg: '',
      };
    case FETCH_CONNECTIONS_FAILED: {
      return {
        ...state,
        isLoading: false,
        activeConnections: [] as IConnection[],
        pastConnections: [] as IConnection[],
        requestedConnections: [] as IConnection[],
        errorMsg: payload?.message || '',
      };
    }
    case FETCH_CONNECTIONS_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        errorMsg: '',
        activeConnections: payload.activeConnections,
        pastConnections: payload.pastConnections,
        requestedConnections: payload.requestedConnections,
      };
    }
    case FETCH_CARE_TEAM_MEMBERS:
      return {
        ...state,
        isLoading: true,
        isRequesting: false,
        errorMsg: '',
      };
    case FETCH_CARE_TEAM_MEMBERS_FAILED: {
      return {
        ...state,
        isLoading: false,
        careTeamMembers: [] as ICareTeamMember[],
        errorMsg: payload?.message || '',
      };
    }
    case FETCH_CARE_TEAM_MEMBERS_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        errorMsg: '',
        careTeamMembers: payload,
      };
    }
    default:
      return state;
  }
}
