import { transformTokens } from 'token-transformer';
import { get, difference, forIn, isString, isArray, isObject, keysIn, startsWith, endsWith } from 'lodash';
import jsonData from 'themes/tokens.json';

const isPlaceholder = val => {
  return startsWith(val, '{') && endsWith(val, '}');
};

const getPlaceholderValue = (val, reference) => {
  if (isPlaceholder(val)) {
    const path = val.substring(1, val.length - 1);
    const valObj = get(reference, path);
    if (isObject(valObj) && valObj['value']) {
      return valObj['value'];
    }
  }

  return val;
};

const getBoxShadowValue = obj => {
  const { x, y, blur, spread, color } = obj;

  return `${x}px ${y}px ${blur}px ${spread}px ${color}`;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const replacePlaceholders = (obj, reference) => {
  forIn(obj, (value, key) => {
    if (isString(value)) {
      obj[key] = getPlaceholderValue(obj[key], reference);
    } else if (isObject(obj[key])) {
      replacePlaceholders(obj[key], reference);
    }
  });
};

const replaceValueObject = obj => {
  forIn(obj, (value, key) => {
    if (typeof value === 'object') {
      const keys = keysIn(value);
      if (difference(['type', 'value', 'description'], keys).length === 0) {
        if (value.type === 'boxShadow') {
          if (isArray(value.value)) {
            obj[key] = value.value.map(val => getBoxShadowValue(val)).join(',');
          } else {
            obj[key] = getBoxShadowValue(value.value);
          }
        } else if (value.type === 'border') {
          const { color, width, style } = value.value;
          obj[key] = `${width}px ${style} ${color}`;
        } else {
          obj[key] = value.value;
        }
      } else if (value && value.type === 'dropShadow') {
        obj[key] = getBoxShadowValue(value);
      } else {
        replaceValueObject(value);
      }
    }
  });
};

export const parseJsonTheme: any = () => {
  // const result = pick(jsonData, ['core', 'semantic']);
  // replacePlaceholders(result.semantic, result.core);
  // replacePlaceholders(result.semantic, result.semantic);

  const resolved = transformTokens(jsonData, ['core', 'semantic', 'icons']);
  replaceValueObject(resolved);

  return resolved;
};
