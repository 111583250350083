import { IAction } from 'redux/store/types';
import { IProfile, ICareTeamMember, ProfileState, IInsuranceInfo, IConnection, IActivity } from './types';
import {
  FETCH_PROFILE,
  FETCH_PROFILE_FAILED,
  FETCH_PROFILE_SUCCESSFUL,
  UPDATE_PROFILE,
  UPDATE_PROFILE_FAILED,
  UPDATE_PROFILE_SUCCESSFUL,
  UPDATE_PROFILE_IMAGE,
  UPDATE_PROFILE_IMAGE_FAILED,
  UPDATE_PROFILE_IMAGE_SUCCESSFUL,
  FETCH_INSURANCE,
  FETCH_INSURANCE_FAILED,
  FETCH_INSURANCE_SUCCESSFUL,
  UPDATE_INSURANCE,
  UPDATE_INSURANCE_FAILED,
  UPDATE_INSURANCE_SUCCESSFUL,
  ADD_INSURANCE,
  ADD_INSURANCE_FAILED,
  ADD_INSURANCE_SUCCESSFUL,
  FETCH_CONNECTIONS,
  FETCH_CONNECTIONS_FAILED,
  FETCH_CONNECTIONS_SUCCESSFUL,
  UPDATE_ACTIVE_CONNECTION_LAST_MESSAGE,
  FETCH_MARKED_ARTICLES,
  FETCH_MARKED_ARTICLES_FAILED,
  FETCH_MARKED_ARTICLES_SUCCESSFUL,
  FETCH_ACTIVITY,
  FETCH_ACTIVITY_FAILED,
  FETCH_ACTIVITY_SUCCESSFUL,
  FETCH_PUBLIC_INSURANCE,
  FETCH_PUBLIC_INSURANCE_FAILED,
  FETCH_PUBLIC_INSURANCE_SUCCESSFUL,
  UPDATE_PUBLIC_INSURANCE,
  UPDATE_PUBLIC_INSURANCE_FAILED,
  UPDATE_PUBLIC_INSURANCE_SUCCESSFUL,
} from './actions';
import { addColorCode, sortConnections } from './helper';
import { USER_LOGOUT } from '../auth/actions';

export const DEFAULT = {
  isLoading: false,
  isRequesting: false,
  errorMsg: '',
  profile: {} as IProfile,
  insurance: {} as IInsuranceInfo,
  publicInsurance: {} as IInsuranceInfo,
  careTeamMembers: [] as ICareTeamMember[],
  activeConnections: [] as IConnection[],
  pastConnections: [] as IConnection[],
  connectionsFetchedFor: '',
  markedArticles: [] as string[],
  completedArticles: [] as string[],
  activity: {} as IActivity,
};

export default function profileReducer(state = DEFAULT, action: IAction): ProfileState {
  const { type, payload } = action;
  switch (type) {
    case FETCH_PROFILE: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_PROFILE_FAILED: {
      return {
        ...state,
        isLoading: false,
        profile: {} as IProfile,
      };
    }
    case FETCH_PROFILE_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        profile: payload,
      };
    }
    case FETCH_ACTIVITY: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_ACTIVITY_FAILED: {
      return {
        ...state,
        isLoading: false,
        activity: {} as IActivity,
      };
    }
    case FETCH_ACTIVITY_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        activity: payload,
      };
    }
    case UPDATE_PROFILE: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case UPDATE_PROFILE_FAILED: {
      return {
        ...state,
        isLoading: false,
        // profile: {} as IProfile,
        errorMsg: payload?.message,
      };
    }
    case UPDATE_PROFILE_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        profile: payload,
      };
    }
    case UPDATE_PROFILE_IMAGE: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case UPDATE_PROFILE_IMAGE_FAILED: {
      return {
        ...state,
        isLoading: false,
        errorMsg: payload?.message,
      };
    }
    case UPDATE_PROFILE_IMAGE_SUCCESSFUL: {
      return {
        ...state,
        profile: {
          ...state.profile,
          profileImage: payload,
        },
        isLoading: false,
      };
    }
    case FETCH_INSURANCE: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_INSURANCE_FAILED: {
      return {
        ...state,
        isLoading: false,
        insurance: {} as IInsuranceInfo,
        errorMsg: payload?.message,
      };
    }
    case FETCH_INSURANCE_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        insurance: payload.data,
      };
    }
    case UPDATE_INSURANCE: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case UPDATE_INSURANCE_FAILED: {
      return {
        ...state,
        isLoading: false,
        insurance: {} as IInsuranceInfo,
        errorMsg: payload?.message,
      };
    }
    case UPDATE_INSURANCE_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        insurance: payload.data,
      };
    }
    case ADD_INSURANCE: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ADD_INSURANCE_FAILED: {
      return {
        ...state,
        isLoading: false,
        insurance: {} as IInsuranceInfo,
        errorMsg: payload?.message,
      };
    }
    case ADD_INSURANCE_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        insurance: payload.data,
      };
    }
    case FETCH_PUBLIC_INSURANCE: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_PUBLIC_INSURANCE_FAILED: {
      return {
        ...state,
        isLoading: false,
        publicInsurance: {} as IInsuranceInfo,
        errorMsg: payload?.message,
      };
    }
    case FETCH_PUBLIC_INSURANCE_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        publicInsurance: payload.data,
      };
    }
    case UPDATE_PUBLIC_INSURANCE: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case UPDATE_PUBLIC_INSURANCE_FAILED: {
      return {
        ...state,
        isLoading: false,
        publicInsurance: {} as IInsuranceInfo,
        errorMsg: payload?.message,
      };
    }
    case UPDATE_PUBLIC_INSURANCE_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        publicInsurance: payload.data,
      };
    }
    case UPDATE_ACTIVE_CONNECTION_LAST_MESSAGE: {
      return {
        ...state,
        activeConnections: state.activeConnections.map(item =>
          item.channelUrl === payload.channelUrl
            ? { ...item, lastMessage: payload.message.message.text }
            : item
        ),
      };
    }
    case FETCH_CONNECTIONS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_CONNECTIONS_FAILED: {
      return {
        ...state,
        isLoading: false,
        errorMsg: payload?.message,
      };
    }
    case FETCH_CONNECTIONS_SUCCESSFUL: {
      const { activeConnections, pastConnections } = state;
      let newActive = addColorCode(payload.activeConnections);
      let newPast = payload.pastConnections;
      if (state.connectionsFetchedFor !== null) {
        activeConnections.forEach((connection: IConnection) => {
          newActive = payload.activeConnections.map((activeConnection: IConnection) => {
            if (connection.connectionId === activeConnection.connectionId) {
              activeConnection.messages = connection.messages;
            }
            return activeConnection;
          });
        });
        pastConnections.forEach((connection: IConnection) => {
          newPast = payload.pastConnections.map((pastConnection: IConnection) => {
            if (connection.connectionId === pastConnection.connectionId) {
              pastConnection.messages = connection.messages;
            }
            return pastConnection;
          });
        });
      }
      return {
        ...state,
        isLoading: false,
        activeConnections: sortConnections(newActive),
        pastConnections: sortConnections(newPast),
        connectionsFetchedFor: payload.userId,
      };
    }
    case FETCH_MARKED_ARTICLES: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_MARKED_ARTICLES_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        markedArticles: payload.bookmarkedContentResponse,
        completedArticles: payload.markAsCompletedContentResponse,
      };
    }
    case FETCH_MARKED_ARTICLES_FAILED: {
      return {
        ...state,
        isLoading: false,
        markedArticles: [] as string[],
        completedArticles: [] as string[],
      };
    }
    case USER_LOGOUT: {
      return {
        ...state,
        ...DEFAULT,
      };
    }
    default:
      return state;
  }
}
