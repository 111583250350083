import React, { FC } from 'react';

interface IGlyphIcon {
  className?: string;
  color?: string;
  onClick?: () => void;
}

const OnlyCashPayIcon: FC<IGlyphIcon> = ({ className = '', color, onClick }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
    >
      <circle cx="8" cy="8" r="7.5" fill="#EBFDE8" stroke="#70E377" />
    </svg>
  );
};

export default OnlyCashPayIcon;
