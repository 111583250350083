let appId = process.env.REACT_APP_SEND_BIRD_APP_ID as string;

export const setSendbirdAppId = (selectedAppId: string): void => {
  appId = selectedAppId;
};

export const getSendbirdAppId = (): string => {
  return appId;
};

export const APP_ID = appId;

export const QUERY_LIMIT = 50;
