/* eslint-disable max-len */
import { FC } from 'react';

interface IGlyphIcon {
  className?: string;
  color?: string;
  onClick?: () => void;
}

const Analytics: FC<IGlyphIcon> = ({ className, color, onClick }) => {
  return (
    <svg
      className={className}
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M12.9219 1.92188C13.4688 1.92188 14.0156 2.3125 14.1328 2.85938L17.0234 13.4453L17.7266 12.0781C18.1562 11.2188 19.0156 10.6719 19.9531 10.6719H24.2109C24.875 10.6719 25.4609 11.2578 25.4609 11.9219C25.4609 12.625 24.875 13.1719 24.2109 13.1719H19.9531L17.8047 17.5078C17.5703 17.9766 17.1016 18.25 16.5547 18.1719C16.0469 18.1328 15.6172 17.7812 15.5 17.2734L13.0781 8.48438L10.4219 20.9453C10.3047 21.5312 9.79688 21.9219 9.25 21.9219C8.66406 21.9609 8.15625 21.5703 8 21.0234L5.73438 13.1719H1.71094C1.00781 13.1719 0.460938 12.625 0.460938 11.9219C0.460938 11.2578 1.00781 10.6719 1.71094 10.6719H5.73438C6.86719 10.6719 7.84375 11.4141 8.15625 12.5078L9.01562 15.5156L11.7109 2.9375C11.8281 2.35156 12.3359 1.96094 12.9219 1.92188Z"
        fill={color}
      />
    </svg>
  );
};

export default Analytics;
