import makeStyles from '@mui/styles/makeStyles';
import type { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: theme.sizing[6],
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    borderRadius: theme.borderRadius.xsmall,
    ...theme.typography.helper.bold,
    whiteSpace: 'nowrap',
  },
  neutralDefault: {
    background: theme.color.surface.default,
    border: theme.border.medium.s,
    color: theme.color.text.primary,
  },
  neutralFillSubtle: {
    background: theme.color.surface.accent,
    color: theme.color.text.secondary,
    ...theme.typography.helper.regular,
  },
  neutralFill: {
    background: theme.color.background.inverse,
    color: theme.color.text.onColor,
  },
  primaryDefault: {
    background: theme.color.background.white,
    border: theme.border.primaryColor.s,
    color: theme.color.text.primaryColor,
  },
  primaryFillSubtle: {
    background: theme.color.surface.primaryColor,
    color: theme.color.text.primaryColor,
    ...theme.typography.helper.regular,
  },
  primaryFill: {
    background: theme.color.background.interactive,
    color: theme.color.text.onColor,
  },
  secondaryDefault: {
    background: theme.color.background.white,
    border: theme.border.secondaryColor.s,
    color: theme.color.text.secondaryColor,
  },
  secondaryFillSubtle: {
    background: theme.color.surface.secondaryColor,
    color: theme.color.text.secondaryColor,
    ...theme.typography.helper.regular,
  },
  secondaryFill: {
    background: theme.color.background.accent,
    color: theme.color.text.onColor,
  },
  successDefault: {
    background: theme.color.background.white,
    border: theme.border.success.s,
    color: theme.color.text.success,
  },
  successFillSubtle: {
    background: theme.color.surface.success,
    color: theme.color.text.success,
    ...theme.typography.helper.regular,
  },
  successFill: {
    background: theme.color.support.success,
    color: theme.color.text.onColor,
  },
  infoDefault: {
    background: theme.color.background.white,
    border: theme.border.info.s,
    color: theme.color.text.info,
  },
  infoFillSubtle: {
    background: theme.color.surface.info,
    color: theme.color.text.info,
    ...theme.typography.helper.regular,
  },
  infoFill: {
    background: theme.color.support.info,
    color: theme.color.text.onColor,
  },
  warningDefault: {
    background: theme.color.background.white,
    border: theme.border.warning.s,
    color: theme.color.text.warning,
  },
  warningFillSubtle: {
    background: theme.color.surface.warning,
    color: theme.color.text.warning,
    ...theme.typography.helper.regular,
  },
  warningFill: {
    background: theme.color.support.warning,
    color: theme.color.text.onColor,
  },
  dangerDefault: {
    background: theme.color.background.white,
    border: theme.border.danger.s,
    color: theme.color.text.danger,
  },
  dangerFillSubtle: {
    background: theme.color.surface.danger,
    color: theme.color.text.danger,
    ...theme.typography.helper.regular,
  },
  dangerFill: {
    background: theme.color.support.danger,
    color: theme.color.text.onColor,
  },
  appointmentScheduled: {
    background: theme.color.appointmentStatus.scheduledSubtle,
    color: theme.color.appointmentStatus.scheduled,
  },
  appointmentScheduledSelected: {
    background: theme.color.appointmentStatus.scheduled,
    color: theme.color.text.onColor,
  },
  appointmentToday: {
    background: theme.color.appointmentStatus.todaySubtle,
    color: theme.color.appointmentStatus.today,
  },
  appointmentTodaySelected: {
    background: theme.color.appointmentStatus.today,
    color: theme.color.text.onColor,
  },
  appointmentRequested: {
    background: theme.color.appointmentStatus.requestedSubtle,
    color: theme.color.appointmentStatus.requested,
  },
  appointmentRequestedSelected: {
    background: theme.color.appointmentStatus.requested,
    color: theme.color.text.onColor,
  },
  appointmentCompleted: {
    background: theme.color.appointmentStatus.completedSubtle,
    color: theme.color.appointmentStatus.completed,
  },
  appointmentCompletedSelected: {
    background: theme.color.appointmentStatus.completed,
    color: theme.color.text.onColor,
  },
  appointmentCanceled: {
    background: theme.color.appointmentStatus.canceledSubtle,
    color: theme.color.appointmentStatus.canceled,
  },
  appointmentCanceledSelected: {
    background: theme.color.appointmentStatus.canceled,
    color: theme.color.text.onColor,
  },
  appointmentNoshow: {
    background: theme.color.appointmentStatus.noShowSubtle,
    color: theme.color.appointmentStatus.noShow,
  },
  appointmentNoshowSelected: {
    background: theme.color.appointmentStatus.noShow,
    color: theme.color.text.onColor,
  },
  availableStatus: {
    padding: 0,
    gap: theme.spacing(1),
    justifyContent: 'flex-start',
    '& svg': {
      width: theme.sizing[5],
      height: theme.sizing[5],
    },
    '& .text': {
      ...theme.typography.helper.bold,
    },
  },
  active: {
    ...theme.typography.helper.bold,
    background: theme.color.support.successSubtle,
    color: theme.color.text.success,
  },
  activeSelected: {
    ...theme.typography.helper.bold,
    background: theme.color.support.success,
    color: theme.color.text.onColor,
  },
  inactive: {
    ...theme.typography.helper.bold,
    background: theme.color.support.dangerSubtle,
    color: theme.color.text.danger,
  },
  inactiveSelected: {
    ...theme.typography.helper.bold,
    background: theme.color.support.danger,
    color: theme.color.text.onColor,
  },
}));
