import { IAction } from 'redux/store/types';
import {
  FETCH_PAYERS_BY_STATE_ID,
  FETCH_PAYERS_BY_STATE_ID_FAILED,
  FETCH_PAYERS_BY_STATE_ID_SUCCESSFUL,
  FETCH_PAYERS_BY_STATE_NAME,
  FETCH_PAYERS_BY_STATE_NAME_FAILED,
  FETCH_PAYERS_BY_STATE_NAME_SUCCESSFUL,
  FETCH_STATES,
  FETCH_STATES_FAILED,
  FETCH_STATES_SUCCESSFUL,
  FETCH_ACTIVE_STATES,
  FETCH_ACTIVE_STATES_FAILED,
  FETCH_ACTIVE_STATES_SUCCESSFUL,
} from './actions';
import { IPayer, IState, StatesState } from './types';

export const DEFAULT = {
  states: { list: [] as IState[], isLoading: false, errorMsg: '' },
  activeStates: { list: [] as IState[], isLoading: false, errorMsg: '' },
  payers: { list: [] as IPayer[], isLoading: false, errorMsg: '' },
};

export default function stateReducer(state = DEFAULT, action: IAction): StatesState {
  const { type, payload } = action;
  switch (type) {
    case FETCH_STATES:
      return {
        ...state,
        states: {
          ...state.states,
          isLoading: true,
          errorMsg: '',
        },
      };
    case FETCH_STATES_FAILED: {
      return {
        ...state,
        states: {
          ...state.states,
          isLoading: false,
          list: [] as IState[],
          errorMsg: payload?.message || '',
        },
      };
    }
    case FETCH_STATES_SUCCESSFUL: {
      return {
        ...state,
        states: {
          isLoading: false,
          errorMsg: '',
          list: payload.states,
        },
      };
    }
    case FETCH_ACTIVE_STATES:
      return {
        ...state,
        activeStates: {
          ...state.states,
          isLoading: true,
          errorMsg: '',
        },
      };
    case FETCH_ACTIVE_STATES_FAILED: {
      return {
        ...state,
        activeStates: {
          ...state.states,
          isLoading: false,
          list: [] as IState[],
          errorMsg: payload?.message || '',
        },
      };
    }
    case FETCH_ACTIVE_STATES_SUCCESSFUL: {
      return {
        ...state,
        activeStates: {
          isLoading: false,
          errorMsg: '',
          list: payload.states,
        },
      };
    }
    case FETCH_PAYERS_BY_STATE_ID:
      return {
        ...state,
        payers: {
          ...state.payers,
          isLoading: true,
          errorMsg: '',
        },
      };
    case FETCH_PAYERS_BY_STATE_ID_FAILED: {
      return {
        ...state,
        payers: {
          ...state.payers,
          isLoading: false,
          list: [] as IPayer[],
          errorMsg: payload?.message || '',
        },
      };
    }
    case FETCH_PAYERS_BY_STATE_ID_SUCCESSFUL: {
      return {
        ...state,
        payers: {
          isLoading: false,
          errorMsg: '',
          list: payload.payers,
        },
      };
    }
    case FETCH_PAYERS_BY_STATE_NAME:
      return {
        ...state,
        payers: {
          ...state.payers,
          isLoading: true,
          errorMsg: '',
        },
      };
    case FETCH_PAYERS_BY_STATE_NAME_FAILED: {
      return {
        ...state,
        payers: {
          ...state.payers,
          isLoading: false,
          list: [] as IPayer[],
          errorMsg: payload?.message || '',
        },
      };
    }
    case FETCH_PAYERS_BY_STATE_NAME_SUCCESSFUL: {
      return {
        ...state,
        payers: {
          isLoading: false,
          errorMsg: '',
          list: payload.payers,
        },
      };
    }
    default:
      return state;
  }
}
