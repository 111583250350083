import { call, put, takeLatest } from 'redux-saga/effects';
import { IAction } from 'redux/store/types';
import * as ConversationService from 'services/conversation/conversation.service';
import * as ProxyService from 'services/proxy/proxy.service';
import getConfig from 'config';

import { showSnackbar } from '../snackbar';

import {
  FETCH_CONVERSATIONS_PUBLIC,
  FETCH_CONVERSATIONS_FAILED_PUBLIC,
  FETCH_CONVERSATIONS_SUCCESSFUL_PUBLIC,
  FETCH_CHATBOTS_PUBLIC,
  FETCH_CHATBOTS_FAILED_PUBLIC,
  FETCH_CHATBOTS_SUCCESSFUL_PUBLIC,
  ASSIGN_CHATBOT_PUBLIC,
  ASSIGN_CHATBOT_FAILED_PUBLIC,
  ASSIGN_CHATBOT_SUCCESSFUL_PUBLIC,
} from './actions';

function* fetchConversations() {
  try {
    const { data } = yield call(ConversationService.getConversations);
    yield put({ type: FETCH_CONVERSATIONS_SUCCESSFUL_PUBLIC, payload: data });
  } catch (e: any) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({ type: FETCH_CONVERSATIONS_FAILED_PUBLIC, payload: { message } });
  }
}

function* fetchChatbots(action: IAction) {
  const { userId, includeResponses = true } = action.payload;

  try {
    const { data } = yield call(ProxyService.bypassProxy, {
      method: 'GET',
      url: `${getConfig.api.baseUrl}/conversation/chatbots/${userId}`,
      memberId: userId,
      params: { includeResponses },
    });
    yield put({ type: FETCH_CHATBOTS_SUCCESSFUL_PUBLIC, payload: data });
  } catch (err: any) {
    const message = err.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({ type: FETCH_CHATBOTS_FAILED_PUBLIC, payload: { message } });
  }
}

function* assignChatbot(action: IAction) {
  const { callback, ...bodyRequest } = action.payload;

  try {
    yield call(ConversationService.assignChatbot, bodyRequest);
    yield put({ type: ASSIGN_CHATBOT_SUCCESSFUL_PUBLIC });
    yield callback();
  } catch (e: any) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({ type: ASSIGN_CHATBOT_FAILED_PUBLIC, payload: { message } });
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: message,
      })
    );
  }
}

function* publicConversationSaga(): IterableIterator<any> {
  yield takeLatest(FETCH_CONVERSATIONS_PUBLIC, fetchConversations);
  yield takeLatest(FETCH_CHATBOTS_PUBLIC, fetchChatbots);
  yield takeLatest(ASSIGN_CHATBOT_PUBLIC, assignChatbot);
}

export default publicConversationSaga;
