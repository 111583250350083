import { createAction } from 'redux-actions';

export const FETCH_CONNECTIONS = 'connections/fetch';
export const FETCH_CONNECTIONS_SUCCESSFUL = 'connections/fetchSuccessful';
export const FETCH_CONNECTIONS_FAILED = 'connections/fetchSuccess';

export const FETCH_CARE_TEAM_MEMBERS = 'careTeamMembers/fetch';
export const FETCH_CARE_TEAM_MEMBERS_SUCCESSFUL = 'careTeamMembers/fetchSuccessful';
export const FETCH_CARE_TEAM_MEMBERS_FAILED = 'careTeamMembers/fetchSuccess';

export const FETCH_BOT_PROGRESS = 'connections/FETCH_BOT_PROGRESS';

export const connectionActionCreators = {
  fetchConnections: createAction(FETCH_CONNECTIONS),
  fetchCareTeamMembers: createAction(FETCH_CARE_TEAM_MEMBERS),
};
