export const CONNECTIONS_TYPES = {
  PRACTITIONER: 'PRACTITIONER',
  PATIENT: 'PATIENT',
  CHAT_GROUP: 'CHAT_GROUP',
  CARE_TEAM: 'CARE_TEAM',
};

export interface IGiftedMessage {
  _id: number;
  parentMessageId?: number;
  prevMsg?: any;
  message: {
    text: string | null;
  };
  nickName: string;
  createdAt: number;
  type: string;
  fileMeta: {
    url: string;
    type: string;
    loading: boolean;
    progress: number;
  } | null;
  system: boolean;
  user: {
    userId: number;
    name: string;
    avatar: string | null;
  } | null;
}

export interface ChatState {
  isLoading: boolean;
  isLoadingEarlier: boolean;
  channelUrl: null;
  sendbirdStatus: number;
  connectionStatus: number;
  messages: any[];
  sendingMessage: boolean;
  loadEarlier: boolean;
  errorMsg: string;
  channels: any[];
  channelMembers: any[];
}
