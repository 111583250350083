import { useStyles } from './index.styles';

const FallbackSpin = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.dots3} />
    </div>
  );
};

export default FallbackSpin;
