import { FC } from 'react';

interface IGlyphIcon {
  className?: string;
  color?: string;
  onClick?: () => void;
}

const Connecticut: FC<IGlyphIcon> = ({ className = '', onClick, color }) => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
    >
      <g id="Virginia">
        <path
          id="fill"
          d="M57.6367 43.99V44.33H57.58L57.3533 44.1033L57.6367 43.99ZM58.26 38.21H57.92L58.3167 37.53V38.04L58.26 38.21ZM58.8833 36.7933L58.8267 36.68L59.2233 36.2267L59.11 36.2833L58.8833 36.7933ZM60.7533 32.1467L59.62 35.49L59.2233 35.83L58.8833 35.32L58.6 35.7167L58.8833 35.83L58.26 36.2833L57.8633 38.72L57.6367 38.3233L57.24 39.0033L56.7867 37.4733L57.3533 34.8667L58.1467 34.2433L58.26 33.11L59.11 32.8267L58.6 32.26L59.28 31.3533L61.15 31.1833L60.7533 32.1467ZM61.4333 32.26L61.2067 32.43H60.9233L62 30.9L61.4333 32.26ZM43.64 21.4933L44.49 19.9633L45.68 20.1333L46.4733 20.87L46.1333 21.72L47.72 22.4L49.25 23.7033V25.0633L48.57 25.46L48.4567 26.0267L48.0033 25.97L47.4933 28.0667L47.0967 27.7833L47.72 28.35L47.21 28.5767L49.4767 28.0667L49.6467 28.5767L49.25 28.7467L50.1 29.71L51.5733 30.2767L52.5367 30.22L53.0467 31.3533L55.03 32.3167V33.11L54.35 32.8267L54.6333 34.7533L53.5 34.1867L53.2167 34.64L50.1 30.7867L49.25 30.1067L48.74 30.22L50.1 30.9L53.0467 34.7533L54.7467 35.32L54.0667 35.7167L55.1433 35.8867L55.03 37.53L54.01 36.3967L53.7833 37.4733L54.2933 37.8133L53.67 38.04L51.29 35.7167L51.1767 35.8867L53.1033 38.04L54.18 38.3233L54.7467 39.06L54.5767 39.4L55.03 39.4567L54.7467 40.25L54.01 40.5333L52.31 38.4367L51.6867 38.72L50.78 37.53L50.2133 38.3233L49.7033 37.53L49.4767 37.9267L48.0033 37.36L47.89 37.7567L49.25 38.04L49.6467 37.8133L50.0433 38.3233L51.6867 39.0033L52.14 38.72L52.31 39.9667L53.6133 40.76L53.2167 41.61L54.2933 41.3267L54.86 41.61L54.7467 40.76L57.07 41.0433L58.1467 44.33L57.24 42.63V44.33L56.6733 43.9333L56.7867 44.33L42.3933 44.5H27.7733L2.5 42.97L6.35333 41.8933L6.86333 41.0433L8.28 40.76L9.75333 38.72L12.19 37.4733L14.8533 35.32L15.0233 36.7933L17.46 38.3233L18.99 37.2467L20.01 38.04L22.5033 37.4733L22.6733 36.68L23.4667 36.9633L24.9967 36.2833L25.5067 36.68L26.64 35.8867L27.15 34.9233L26.7533 34.3567L30.21 29.2567L31.2867 26.31L32.08 26.65L32.25 27.5L33.78 28.01L35.82 24.2133L36.7267 24.7233L39.7867 21.4933L40.3533 19L43.64 21.4933Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default Connecticut;
