import { createAction } from 'redux-actions';
import { FETCH_BOT_PROGRESS_PUBLIC } from '../public-connection/actions';

export const CHAT_REQUEST_INITIALIZE_PUBLIC = 'chat/public/REQUEST_INITIALIZE';
export const CHAT_RESPONSE_RECEIVED_PUBLIC = 'chat/public/RESPONSE_RECEIVED';
export const CHAT_INITIALIZING_PUBLIC = 'chat/public/INITIALIZING';
export const CHAT_UPDATE_CONNECTION_STATUS_PUBLIC = 'chat/public/UPDATE_CONNECTION_STATUS';
export const CHAT_INIT_FAILED_PUBLIC = 'chat/public/INIT_FAILED';
export const CHAT_TOKEN_EXPIRED_PUBLIC = 'chat/public/TOKEN_EXPIRED';
export const CHAT_INIT_SUCCESSFUL_PUBLIC = 'chat/public/INIT_SUCCESSFUL';
export const CHAT_FETCH_NEXT_MSG_PUBLIC = 'chat/public/FETCH_NEXT_MSG';
export const CHAT_SEND_MESSAGE_PUBLIC = 'chat/public/SEND_MESSAGE';
export const CHAT_HIDE_SINGLE_CHOICES_PUBLIC = 'chat/public/HIDE_SINGLE_CHOICES';
export const CHAT_SEND_MESSAGE_IN_PROGRESS_PUBLIC = 'chat/public/SEND_MESSAGE_IN_PROGRESS';
export const CHAT_SEND_MESSAGE_FAILED_PUBLIC = 'chat/public/SEND_MESSAGE_FAILED';
export const CHAT_SEND_MESSAGE_SUCCESSFUL_PUBLIC = 'chat/public/SEND_MESSAGE_SUCCESSFUL';
export const CHAT_TYPING_DETECTED_PUBLIC = 'chat/public/TYPING_DETECTED';
export const CHAT_ADD_MESSAGE_PUBLIC = 'chat/public/ADD_MESSAGE';
export const CHAT_AUTO_REPLY_REGISTERED_PUBLIC = 'chat/public/AUTO_REPLY_REGISTERED';
export const CHAT_LOAD_EARLIER_PUBLIC = 'chat/public/LOAD_EARLIER';
export const CHAT_LOADING_EARLIER_PUBLIC = 'chat/public/LOADING_EARLIER';
export const CHAT_LOADED_EARLIER_PUBLIC = 'chat/public/LOADED_EARLIER';
export const CHAT_PAUSE_PUBLIC = 'chat/public/PAUSE';
export const CHAT_RESUME_PUBLIC = 'chat/public/RESUME';
export const CHAT_EXIT_PUBLIC = 'chat/public/EXIT';

const createActionWrapper = action => {
  return createAction(
    action,
    data => data?.payload,
    data => data?.meta
  );
};

export const publicChatbotActionCreators = {
  chatRequestInitialize: createActionWrapper(CHAT_REQUEST_INITIALIZE_PUBLIC),
  chatUpdateConnectionStatus: createActionWrapper(CHAT_UPDATE_CONNECTION_STATUS_PUBLIC),
  chatPaused: createAction(CHAT_PAUSE_PUBLIC),
  chatResumed: createAction(CHAT_RESUME_PUBLIC),
  chatInitFailed: createActionWrapper(CHAT_INIT_FAILED_PUBLIC),
  chatInitSuccessful: createActionWrapper(CHAT_INIT_SUCCESSFUL_PUBLIC),
  chatSendMessage: createActionWrapper(CHAT_SEND_MESSAGE_PUBLIC),
  chatSendMessageInProgress: createActionWrapper(CHAT_SEND_MESSAGE_IN_PROGRESS_PUBLIC),
  chatSendMessageFailed: createActionWrapper(CHAT_SEND_MESSAGE_FAILED_PUBLIC),
  chatSendMessageSuccessful: createActionWrapper(CHAT_SEND_MESSAGE_SUCCESSFUL_PUBLIC),
  chatTypingDetected: createActionWrapper(CHAT_TYPING_DETECTED_PUBLIC),
  chatAddMessage: createActionWrapper(CHAT_ADD_MESSAGE_PUBLIC),
  chatLoadEarlier: createActionWrapper(CHAT_LOAD_EARLIER_PUBLIC),
  chatLoadingEarlier: createActionWrapper(CHAT_LOADING_EARLIER_PUBLIC),
  chatLoadedEarlier: createActionWrapper(CHAT_LOADED_EARLIER_PUBLIC),
  chatExit: createActionWrapper(CHAT_EXIT_PUBLIC),
  chatFetchNext: createActionWrapper(CHAT_FETCH_NEXT_MSG_PUBLIC),
  updateBotProgress: createAction(FETCH_BOT_PROGRESS_PUBLIC),
};
