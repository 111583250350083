import { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import SocketListener from 'lib/socket/socket-listener';
import { SocketNotificationTypes } from 'lib/socket/socket.types';

export interface State extends SnackbarOrigin {
  open: boolean;
  notificationData: any;
}

const InAppNotification: FC = () => {
  const [state, setState] = useState<State>({
    open: true,
    vertical: 'top',
    horizontal: 'right',
    notificationData: {},
  });

  const { vertical, horizontal } = state;

  const { inAppNotification } = useSelector((storeState: any) => storeState.socket);

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const handleClick = () => {
    let clickListener: any;
    switch (state.notificationData.notificationType) {
      case SocketNotificationTypes.chatMessageReceived:
      case SocketNotificationTypes.groupMessageReceived:
        clickListener = SocketListener.createMessageNoitificationClickListener(state.notificationData);
        clickListener();
        break;
      case SocketNotificationTypes.appointmentRequested:
        clickListener = SocketListener.createAppointmentRequestedNotificationListener(state.notificationData);
        clickListener();
        break;
      case SocketNotificationTypes.appointmentNeedsAction:
        clickListener = SocketListener.createAppointmentNeedsActionNotificationListener(
          state.notificationData
        );
        clickListener();
        break;
      case SocketNotificationTypes.appointmentConfirmed:
        clickListener = SocketListener.createAppointmentConfirmedNotificationListener(state.notificationData);
        clickListener();
        break;
      case SocketNotificationTypes.appointmentCancelled:
        clickListener = SocketListener.createAppointmentCancelledNotificationListener(state.notificationData);
        clickListener();
        break;
      default:
        break;
    }
    handleClose();
  };

  useEffect(() => {
    if (inAppNotification) {
      console.log('in app notification log:', inAppNotification);
      setState({ ...state, notificationData: inAppNotification.data, open: inAppNotification.open });
    }
  }, [inAppNotification?.data, inAppNotification?.open]);

  function truncate(str, n) {
    return str.length > n ? `${str.slice(0, n - 1)}...` : str;
  }
  console.log('notification state:', state);
  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={state.open}
      key={vertical + horizontal}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <div>
        <Alert severity="info" onClick={handleClick} onClose={handleClose}>
          <AlertTitle>
            {state.notificationData?.title ? truncate(state.notificationData.title, 21) : ''}
          </AlertTitle>
          {state.notificationData?.subtitle ? truncate(state.notificationData.subtitle, 21) : ''}
        </Alert>
      </div>
    </Snackbar>
  );
};

export { InAppNotification };
