import makeStyles from '@mui/styles/makeStyles';
import type { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100vh',
  },
  dots3: {
    width: 12,
    aspectRatio: 1,
    borderRadius: '50%',
    animation: '$d3 1s infinite linear alternate',
  },
  '@keyframes d3': {
    '0%': {
      boxShadow: `20px 0 ${theme.color.primary[500]}, -20px 0 #0002`,
      background: theme.color.primary[500],
    },
    '33%': { boxShadow: `20px 0 ${theme.color.primary[500]}, -20px 0 #0002`, background: '#0002' },
    '66%': { boxShadow: `20px 0 #0002, -20px 0 ${theme.color.primary[500]}`, background: '#0002' },
    '100%': {
      boxShadow: `20px 0 #0002, -20px 0 ${theme.color.primary[500]}`,
      background: theme.color.primary[500],
    },
  },
}));
