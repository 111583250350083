export enum BadgeType {
  DEFAULT = 'default',
  AVAILABILITY = 'availability',
  PROGRESS = 'progress',
  APPOINTMENT = 'appointment',
  INVOICE = 'invoice',
  STATUS = 'status',
  ACTIVE_INACTIVE = 'activeInactive',
}

export enum BadgeColorVariant {
  NEUTRAL = 'neutral',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  DANGER = 'danger',
}

export enum BadgeStyleVariant {
  DEFAULT = 'default',
  FILLSUBTLE = 'fillSubtle',
  FILL = 'fill',
}

export type DefaultBadge = {
  type?: BadgeType.DEFAULT;
  color?: BadgeColorVariant;
  style?: BadgeStyleVariant;
  text?: string;
};

export enum BadgeAvailableStatus {
  AVAILABLE = 'available',
  NOTAVAILABLE = 'notAvailable',
  NEXTAVAILABLE = 'nextAvaialble',
}

export type AvailabilityBadge = {
  type: BadgeType.AVAILABILITY;
  status?: BadgeAvailableStatus;
  text?: string;
};

export enum BadgeProgressStatus {
  INPROGRESS = 'in progress',
  NOTSTARTED = 'not started',
  COMPLETED = 'completed',
  ARCHIVED = 'archived',
}

export type ProgressBadge = {
  type: BadgeType.PROGRESS;
  status?: BadgeProgressStatus;
  selected?: boolean;
};

export enum BadgeAppointmentStatus {
  SCHEDULED = 'scheduled',
  TODAY = 'today',
  REQUESTED = 'requested',
  COMPLETED = 'completed',
  CANCELED = 'canceled',
  NOSHOW = 'no show',
}

export type AppointmentBadge = {
  type: BadgeType.APPOINTMENT;
  selected?: boolean;
  status?: BadgeAppointmentStatus | string;
};

export enum BadgeInvoiceStatus {
  PENDING = 'PENDING',
  PAID = 'PAID',
  ONHOLD = 'ON_HOLD',
  WRITE_OFF = 'WRITE_OFF',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  CANCELLED = 'CANCELLED',
}

export type InvoiceBadge = {
  type: BadgeType.INVOICE;
  status?: BadgeInvoiceStatus;
  selected?: boolean;
};

export type StatusBadge = {
  type: BadgeType.STATUS;
  active?: boolean;
  selected?: boolean;
};

export type ActiveInactiveBadge = {
  type: BadgeType.ACTIVE_INACTIVE;
  isActive?: boolean;
  selected?: boolean;
};

export type BadgeProps = (
  | DefaultBadge
  | AvailabilityBadge
  | ProgressBadge
  | AppointmentBadge
  | InvoiceBadge
  | StatusBadge
  | ActiveInactiveBadge
) & {
  className?: string;
};
